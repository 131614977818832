import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { registriesQueryKeys } from '../registriesQueryKeys';

const useRegistryDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: (registryId) => makeRequest('DELETE', `/orgs/${orgId}/registries/${registryId}`),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: registriesQueryKeys.list(orgId),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useRegistryDeleteMutation;
