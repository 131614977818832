import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { User } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from './userQueryKeys';

const useModifyUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosError,
    { user: Partial<User>; skipInvalidation?: boolean }
  >({
    mutationFn: ({ user }) => makeRequest('PATCH', `/current-user`, user),
    onSuccess: (_, { skipInvalidation = false }) => {
      if (!skipInvalidation) {
        queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser() });
        queryClient.invalidateQueries({ queryKey: userQueryKeys.currentUser() });
      }
    },
    onError: (error: AxiosError<{ message: string }> | Error) => {
      datadogRum.addError(error);
    },
  });
};

export default useModifyUserMutation;
