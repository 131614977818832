import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

import { ButtonSize, ButtonVariant } from '../../../base/Button/Button';
import { Checkbox } from '../../../base/Checkbox/Checkbox';
import WalDropdownMenu, { DropdownItem } from '../DropdownMenu/DropdownMenu';

const Header = styled.div`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

interface MultiSelectItem<T> extends DropdownItem<T> {
  heading?: boolean;
}

export interface MultiSelectGroup {
  title: string;
  key: string;
  items: DropdownItem<string>[];
}

export interface MultiSelectMenuProps {
  /** List of menu items */
  items: (MultiSelectGroup | MultiSelectItem<string>)[];
  name: string;
  /** The text to display when no options are selected */
  defaultText: string;
  label?: string;
  readonly?: boolean;
  /** Pre selected options. The string should be the full path of the form name e.g. 'triggers.environment.created' */
  selectedOptions?: string[];
  /** 'Select all' is available if true */
  enableSelectAll?: boolean;
  /** Selects all on init when true */
  selectAllByDefault?: boolean;
  /** The size of the dropdown toggle */
  buttonSize?: ButtonSize;
  /** dropdown variant style */
  buttonVariant?: ButtonVariant;
  /** a function that is triggered when the menu is closed */
  onMenuClosed?: () => void;
  className?: string;
  setFormFieldValid?: (valid: boolean) => any;
  disableOptions?: boolean;
  /** Always render the contents of the Menu in the DOM */
  alwaysRenderContent?: boolean;
}

export const MultiSelectMenu = ({
  items,
  name,
  defaultText,
  label,
  selectedOptions,
  readonly,
  enableSelectAll,
  selectAllByDefault,
  buttonSize,
  buttonVariant,
  onMenuClosed,
  className,
  setFormFieldValid,
  disableOptions,
}: MultiSelectMenuProps) => {
  // Form
  const { watch, setValue } = useFormContext();
  const formValues = watch();
  const selectAllSelected = watch(`${name}.all`);

  // Component state
  const [displayedSelections, setDisplayedSelections] = useState('');
  const [allChecked, setAllChecked] = useState(false);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  useEffect(() => {
    selectedOptions?.forEach((trigger) => setValue(trigger, true));
  }, [selectedOptions, setValue]);

  useEffect(() => {
    if (selectAllByDefault && enableSelectAll) {
      setValue(`${name}.all`, true);
    }
  }, [selectAllByDefault, enableSelectAll, name, setValue]);

  /**
   * Sets 'all' to true is all options are selected.
   */
  useEffect(() => {
    setValue(`${name}.all`, allChecked);
  }, [allChecked, setValue, name]);

  const newItems: MultiSelectItem<string>[] = useMemo(() => {
    const generatedItems: MultiSelectItem<string>[] = [
      ...items.flatMap((item) => {
        return (item as MultiSelectGroup).items
          ? [
              {
                value: (item as MultiSelectGroup).title,
                id: (item as MultiSelectGroup).title,
                label: (item as MultiSelectGroup).key,
                disabled: true,
                heading: true,
                separatorItem: true,
                component: <Header>{(item as MultiSelectGroup).title}</Header>,
              },
              ...(item as MultiSelectGroup).items.map((groupItem) => ({
                ...groupItem,
                value: `${(item as MultiSelectGroup).key}.${groupItem.value}`,
                id: `${(item as MultiSelectGroup).key}.${groupItem.value}`,
                disabled: disableOptions,
                component: (
                  <Checkbox
                    readonly={disableOptions}
                    name={`${name}.${(item as MultiSelectGroup).key}.${groupItem.value}`}
                    label={groupItem.label}
                  />
                ),
              })),
            ]
          : {
              ...(item as DropdownItem<string>),
              value: (item as DropdownItem<string>).value,
              id: (item as DropdownItem<string>).id,
              disabled: disableOptions,
              component: (
                <Checkbox
                  readonly={disableOptions}
                  name={`${name}.${(item as DropdownItem<string>).value}`}
                  label={(item as DropdownItem<string>).label}
                />
              ),
            };
      }),
    ];

    if (enableSelectAll) {
      /**
       * Sets all options to false when 'all' is false.
       */
      const selectAllChanged = (event: any) => {
        generatedItems.forEach((menuItem) => {
          if (!menuItem.disabled && menuItem.value !== 'all' && !event.target.checked) {
            setValue(`${name}.${menuItem.value}`, false);
          }
        });
      };
      if (generatedItems.length) {
        generatedItems.splice(0, 0, {
          label: uiTranslations.ALL,
          id: 'all',
          value: 'all',
          disabled: disableOptions,
          component: (
            <Checkbox
              readonly={disableOptions}
              name={`${name}.all`}
              label={uiTranslations.SELECT_ALL}
              handleChange={selectAllChanged}
            />
          ),
        });
      }
    }

    return generatedItems;
  }, [
    items,
    name,
    enableSelectAll,
    setValue,
    uiTranslations.SELECT_ALL,
    uiTranslations.ALL,
    disableOptions,
  ]);

  /**
   * Sets all options to true if 'all' is true.
   */
  useEffect(() => {
    newItems.forEach((menuItem) => {
      if (!menuItem.disabled && menuItem.value !== 'all' && selectAllSelected) {
        setValue(`${name}.${menuItem.value}`, true);
      }
    });
  }, [selectAllSelected, newItems, setValue, name]);

  /**
   * Convert checked form fields to a readable string for the dropdown overview.
   */
  useEffect(() => {
    const checkedItems = newItems
      .filter((item) => Boolean(get(formValues, `${name}.${item.value}`)))
      .map((checkedItem) => checkedItem.label);

    if (setFormFieldValid) {
      setFormFieldValid(Boolean(checkedItems.length));
    }

    setDisplayedSelections(
      formValues[name]?.all ? `${uiTranslations.ALL} ${name}` : checkedItems.join(', ')
    );
  }, [formValues, newItems, name, uiTranslations.ALL, setFormFieldValid]);

  /**
   * Check if all options are selected
   */
  useEffect(() => {
    if (!enableSelectAll) return;
    const allOptionsExceptAll = newItems.filter((item) => item.value !== 'all' && !item.heading);
    const optionCount = allOptionsExceptAll.length;
    const allCheckedCount = allOptionsExceptAll.filter((option) =>
      Boolean(get(formValues, `${name}.${option.value}`))
    ).length;
    setAllChecked(optionCount ? allCheckedCount >= optionCount : false);
  }, [formValues, newItems, name, enableSelectAll]);

  return (
    <WalDropdownMenu
      buttonVariant={buttonVariant}
      buttonSize={buttonSize}
      disableCloseOnClick
      items={newItems}
      label={label}
      fullWidth
      menuSize={'parent'}
      defaultText={displayedSelections || defaultText}
      disabled={readonly}
      onMenuClosed={onMenuClosed}
      className={className}
      prioritizeLabelAsDisplayedValue
      alwaysShowDefaultText
    />
  );
};

export default MultiSelectMenu;
