import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { User } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from './userQueryKeys';

const useGetCurrentUserQuery = () => {
  return useQuery({
    queryKey: userQueryKeys.currentUser(),
    queryFn: () => makeRequest<User>('GET', '/current-user'),
    select: (data) => data.data,
    retry: (failureCount, error: AxiosError) => {
      const is403 = Boolean(error.response?.status === 403);

      /**
       * If we get a 403, it means the user is not authenticated. So we shouldn't retry.
       *
       * But there could be a case where the a race condition happens between `/auth/login` & `/current-user`:
       * 1. `/current-user` is called.
       * 2. While `/current-user` is still in flight, `/auth/login` is requested, returns a successful response and we invalidate the `/current-user` endpoint.
       * 3. Because `/current-user` was still in flight when we invalidated it, it seems that the invalidation doesn't work.
       *
       * Because of this, we should retry once to make sure we avoid this race condition.
       */
      if (is403 && failureCount === 0) {
        return true;
      } else if (is403) {
        /**
         * If it's 403 & we already retried once, don't retry again.
         */
        return false;
      }

      /**
       * If we get anything other than 403, retry 3 times.
       */
      return failureCount <= 3;
    },
  });
};

export default useGetCurrentUserQuery;
