import { WalDropdownMenu, WalInput } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { generateSettingsURL } from '@src/utilities/navigation';

const CreateEnvironmentForm = () => {
  // Router hooks
  const { orgId } = useParams();
  const location = useLocation();

  // React Query
  const { data: environment } = useEnvironmentQuery();
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();

  // i18n
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;

  const items = useMemo(
    () =>
      envTypes.map((envType) => ({
        label: envType.id,
        value: envType,
        id: envType.id,
      })),

    [envTypes]
  );

  return (
    <div className={'flex gap-md mb-lg mt-lg'}>
      <WalInput
        name={'environmentName'}
        label={modalTranslations.ENVIRONMENT_NAME}
        labelAbove
        required
        standardValidation={[
          { type: 'name' },
          { type: 'existingId', ids: applicationEnvironments.map((e) => e.id) },
        ]}
      />
      <WalDropdownMenu
        items={items}
        name={'environmentType'}
        label={modalTranslations.ENVIRONMENT_TYPE}
        buttonVariant={'input'}
        defaultValue={environment?.type}
        fullWidth
        fixedBottomRow={{
          component: <span>Manage types</span>,
          link: {
            to: generateSettingsURL(orgId || '', 'environment-types'),
            state: {
              previousPage: location.pathname,
            },
          },
        }}
      />
    </div>
  );
};
export default CreateEnvironmentForm;
