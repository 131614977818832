import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';

import { AutoCompleteOptions } from '../../AutoCompleteInput/AutoCompleteInput';
import { FormGenerator } from '../../FormGenerator/FormGenerator';
import { PairValidation } from '../KeyValueEntries';

export type ValueValidation = {
  pattern: RegExp;
  message: string;
  maxLength?: number;
  transform?: (original: string) => string;
};

export interface KeyValidation {
  pattern: RegExp;
  message: string;
  maxLength?: number;
}

interface KeyValueEntryProps {
  group: { name: string; label?: string };
  index: number;
  existingKeys: string[];
  errorMoreInformationLink?: string;
  keyValidation?: KeyValidation;
  valueValidation?: ValueValidation;
  /* Enables inputs & delete button */
  editingEnabled: boolean;
  /* Regex's passed as strings. Validations must  */
  pairValidations?: PairValidation[];

  autoCompleteOptions?: AutoCompleteOptions;
}

const gridTemplate = css`
  grid-template-columns: 20% 80%;
`;

/**
 * Renders a component which allows user to enter key value combinations.
 *
 * Various validations for the key/value pair can be passed.
 * 'pairValidations' will validate based on the initial key input. The value input must match the pattern defined in the matching key regex.
 */
export const KeyValueEntry = ({
  group,
  index,
  existingKeys,
  errorMoreInformationLink,
  keyValidation,
  valueValidation,
  editingEnabled,
  pairValidations,
  autoCompleteOptions,
}: KeyValueEntryProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const keyValueTranslations = t('UI').KEY_VALUE_ENTRIES;

  // Form
  const { watch } = useFormContext();

  // Component state
  const [valueRegexes, setValueRegexes] = useState<string[]>([]); // List of regexes based on the key value

  const key = watch(`${group.name}.${index}.key`);

  useEffect(() => {
    setValueRegexes(
      pairValidations
        ?.filter((validation) => new RegExp(validation.key.value).test(key))
        .map((v) => v.value.value) ?? []
    );
  }, [pairValidations, key]);

  /**
   * Checks that at least one regex passes for the value supplied.
   *
   * @param value The input value
   * @param validations Pair validations with regex & optional message.
   * @returns Error message or true.
   */
  const checkValidations = (
    value: string,
    validations: PairValidation[],
    keyOrValue: 'key' | 'value'
  ) => {
    const passedAmount = validations.reduce((passedAmt, regex) => {
      return new RegExp(regex[keyOrValue].value).test(value) ? passedAmt + 1 : passedAmt;
    }, 0);

    const customMessages = validations.filter((validation) => validation[keyOrValue].message);

    // If there are custom messages defined, use them.
    if (customMessages.length && passedAmount === 0) {
      // Use uniq so we don't display duplicate messages
      return uniq(customMessages.map((validation) => validation[keyOrValue].message)).join('; ');
    }

    // If there are no custom messages, just display the regex's joined by a comma.
    const regexesJoined = validations.map((validation) => validation[keyOrValue].value).join(', ');

    return (
      passedAmount >= 1 ||
      (validations.length === 1
        ? `${keyValueTranslations.MUST_MATCH_THE_FOLLOWING_PATTERN} ${regexesJoined}`
        : `${keyValueTranslations.MUST_MATCH_ONE_OF_THE_FOLLOWING_PATTERNS} ${regexesJoined}`)
    );
  };

  const valueProps = {
    name: `${group.name}.${index}.value`,
    label: uiTranslations.VALUE_LABEL,
    noMargin: true,
    readonly: !editingEnabled,
    maxLength: valueValidation?.maxLength,
    validate: {
      ...(valueRegexes.length && {
        mustMatchKeyPair: (value: string) =>
          checkValidations(
            value,
            pairValidations?.filter((v) => valueRegexes.includes(v.value.value)) || [],
            'value'
          ),
      }),
      ...(valueValidation && {
        validateValue: (v: string) =>
          valueValidation.pattern.test(
            valueValidation.transform ? valueValidation.transform(v) : v
          ) || valueValidation.message,
      }),
    },
  };

  return (
    <FormGenerator
      hideLabels
      dataTestId={`${group.name}.${index}`}
      errorMoreInformationLink={errorMoreInformationLink}
      gridTemplate={gridTemplate}
      fields={[
        {
          type: 'input',
          props: {
            name: `${group.name}.${index}.key`,
            label: uiTranslations.KEY_LABEL,
            noMargin: true,
            required: true,
            readonly: !editingEnabled,
            allowArrowScrolling: true,
            maxLength: keyValidation?.maxLength,
            validate: {
              ...(pairValidations && {
                mustMatchOne: (value: string) => checkValidations(value, pairValidations, 'key'),
              }),
              ...(keyValidation && {
                validateKey: (v: string) => keyValidation.pattern.test(v) || keyValidation.message,
              }),
            },
            standardValidation: [
              {
                type: 'existingId',
                ids: existingKeys,
              },
            ],
          },
        },
        autoCompleteOptions
          ? {
              type: 'autocomplete',
              props: { ...valueProps, autoCompleteOptions },
            }
          : {
              type: 'input',
              props: valueProps,
            },
      ]}
    />
  );
};
