import { cloneDeep, get, set, unset } from 'lodash';

import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentSet, DeploymentSetResources, Workload } from '@src/models/deployment-set';
import makeRequest from '@src/utilities/make-request';

import { DeploymentDeltaChange } from '../models/deployment-delta';

export const DEFAULT_RESOURCES: DeploymentSetResources = {
  limits: {
    cpu: '0.250',
    memory: '256Mi',
  },
  requests: {
    cpu: '0.025',
    memory: '64Mi',
  },
};

/**
 * @deprecated Use 'updateWorkload' function from useDeltaUtils instead
 *
 * create a deployment to patch one value
 * @param {string} path - path to the property to change in the delta
 * @param {any} value - new value
 * @param {string} moduleKey - key of the deployment delta module
 * @param {boolean} op - update operation
 * @returns {DeploymentDelta}
 */
export const createDeploymentDraftPatchValue = (
  path: string,
  value: any,
  moduleKey: string,
  op: 'remove' | 'replace' | 'add' = 'add'
): Partial<DeploymentDelta> => {
  const obj: DeploymentDeltaChange = { op, path, value };

  const deploymentDelta: Partial<DeploymentDelta> = {
    modules: {
      update: {
        [moduleKey]: [obj],
      },
    },
  };
  return deploymentDelta;
};

export const createSharedDeploymentDraftPatchValue = (
  path: string,
  value: any,
  op: 'remove' | 'replace' | 'add' = 'add'
): Partial<DeploymentDelta> => ({
  shared: [
    {
      op,
      path,
      value,
    },
  ],
});

/**
 * gets value from deployment set from a deployment delta change path
 */
export const getDeploymentSetValueByPath = (deploymentSetModule: Workload, path: string) => {
  const pathSegments = path.split('/');
  pathSegments.shift();
  return pathSegments.reduce((value: any, segment: string) => {
    return value !== undefined && value[decodePathKey(segment)] !== undefined
      ? value[decodePathKey(segment)]
      : undefined;
  }, deploymentSetModule);
};

export const defaultDraftModule: Workload = {
  profile: 'humanitec/default-module',
  spec: {
    volumes: {},
  },
  externals: {},
};

export const expandChange = (change: DeploymentDeltaChange): any => {
  if (typeof change.value !== 'object' || change.value === null) {
    return [change];
  }
  const path = change.path.endsWith('/')
    ? change.path.substring(0, change.path.length - 1)
    : change.path;
  if (change.value && Array.isArray(change.value)) {
    return [{ op: change.op, path: `${path}`, value: JSON.stringify(change.value) }];
  }
  return [{ op: change.op, path: change.path, value: {} }].concat(
    Object.entries(change.value).flatMap((kvp) =>
      expandChange({ op: change.op, path: `${path}/${kvp[0]}`, value: kvp[1] })
    )
  );
};

export const getDeltaChangesCount = (deploymentDelta?: DeploymentDelta) => {
  let count = 0;
  if (deploymentDelta?.modules) {
    if (deploymentDelta.modules.add) {
      count += Object.keys(deploymentDelta.modules.add).length;
    }
    if (deploymentDelta.modules.update) {
      Object.values(deploymentDelta.modules.update).forEach(
        (moduleChanges: DeploymentDeltaChange[]) => {
          count += moduleChanges.length;
        }
      );
    }
    if (deploymentDelta.modules.remove) {
      count += deploymentDelta.modules.remove.length;
    }
    return count;
  } else {
    return 0;
  }
};
/**
 * gets well structred diff object for a specific module
 *
 * @param {DeploymentDeltaChange[]} updatedModulesChangesArr - deployment delta changes
 * @param {DeploymentSetModule} module - deployment set modules
 * @returns {any}
 */
export const getModuleChangesObj = (
  updatedModulesChangesArr: DeploymentDeltaChange[],
  workload: Workload
) => {
  const changesObj: { containers: Record<string, { addedOrRemoved?: 'added' | 'removed' }> } = {
    containers: {},
  };
  updatedModulesChangesArr
    .flatMap((u) => expandChange(u))
    .filter((u) => typeof u.value !== 'object' || u.value === null)
    .forEach((change) => {
      const pathSegments = change.path.split('/');
      const pathSegmentsWithoutSpec = change.path.split('/').splice(2, pathSegments.length - 2);
      const lastSegment = pathSegments[pathSegments.length - 1];
      if (change.path.match(`^\\/spec\\/containers\\/[^\\/]*$`)) {
        changesObj.containers[lastSegment] = {};
        changesObj.containers[lastSegment].addedOrRemoved =
          change.op === 'add' ? 'added' : 'removed';
        return changesObj;
      } else {
        return pathSegmentsWithoutSpec.reduce((obj: any, segment: string) => {
          if (!obj[segment]) {
            obj[segment] = {};
          }
          if (segment === lastSegment) {
            obj[segment] = {
              newValue: change.op !== 'remove' ? change.value : null,
              oldValue:
                typeof getDeploymentSetValueByPath(workload, change.path) === 'object'
                  ? JSON.stringify(getDeploymentSetValueByPath(workload, change.path))
                  : getDeploymentSetValueByPath(workload, change.path),
            };
          }
          return obj[segment];
        }, changesObj);
      }
    });
  return changesObj;
};

/**
 * it returns a base delta of the difference between two deploymentsets
 */
export const diffDeploymentSets = async (
  leftSetId: string,
  rightSetId: string,
  appId: string,
  orgId: string
) => {
  const res = await makeRequest<DeploymentDelta>(
    'get',
    `/orgs/${orgId}/apps/${appId}/sets/${leftSetId}?diff=${rightSetId}`
  );
  return res.data;
};

/**
 * @deprecated Use `updateWorkload` from `useDeltaUtils`. Paths will automatically be encoded.
 */
export const encodePathKey = (text: string) => {
  return text?.replace(/\//g, '~1');
};

/**
 * @deprecated Use `updateWorkload` from `useDeltaUtils`. Paths will automatically be decoded.
 */
export const decodePathKey = (text: string) => {
  return text?.replace(/~1/g, '/');
};

const isDeploymentSet = (v: DeploymentSet | Record<string, unknown>): v is DeploymentSet => {
  return v ? Boolean((v as DeploymentSet).modules) || Boolean((v as DeploymentSet).shared) : false;
};

/**
 * Adds all delta updates to the deployment set.
 *
 * In the case of a delta with no existing DeploymentSet(environment has not been deployed yet), we are building the DeploymentSet from scratch based on the draft changes.
 * This allows us to reference every change (i.e. rendering the data in components) using the same interface(DeploymentSet).
 *
 * @param deploymentSet The existing deploymentset, of in the case of no previous deploy, en empty object ({}).
 * @param draftModeActive If this is false, we will not apply the delta changes to the set.
 * @param deploymentDelta The delta changes we're applying to the Deployment Set.
 */
export const addActiveDeltaUpdatesToDeploymentSet = (
  deploymentSet: Partial<DeploymentSet> | Record<string, unknown>,
  draftModeActive: boolean,
  deploymentDelta?: DeploymentDelta
): DeploymentSet | undefined => {
  if (draftModeActive) {
    deploymentDelta?.shared?.forEach((update) => {
      const path = update.path
        .split('/')
        .filter((p) => p)
        .map((p) => decodePathKey(p));
      if (update.op === 'add' || update.op === 'replace') {
        set(deploymentSet, ['shared', ...path], update.value);
      } else if (update.op === 'remove') {
        unset(deploymentSet, ['shared', ...path]);
      }
    });

    Object.entries(deploymentDelta?.modules.update ?? {}).forEach(
      ([workloadId, workloadUpdates]) => {
        if (workloadUpdates) {
          workloadUpdates.forEach((update) => {
            const path = update.path
              .split('/')
              .filter((p) => p)
              .map((p) => decodePathKey(p));

            if (
              deploymentSet &&
              isDeploymentSet(deploymentSet) &&
              deploymentSet.modules &&
              deploymentSet.modules[workloadId]
            ) {
              if (update.op === 'add' || update.op === 'replace') {
                set(deploymentSet, ['modules', workloadId, ...path], update.value);
              } else if (update.op === 'remove') {
                unset(deploymentSet, ['modules', workloadId, ...path]);
              }
            }
          });
        }
      }
    );

    for (const update of deploymentDelta?.shared ?? []) {
      const path = update.path
        .split('/')
        .filter((p) => p)
        .map((p) => decodePathKey(p));

      if (update.op === 'add' || update.op === 'replace') {
        set(deploymentSet, ['shared', ...path], update.value);
      } else if (update.op === 'remove') {
        unset(deploymentSet, ['shared', ...path]);
      }
    }

    Object.entries(deploymentDelta?.modules.add ?? {}).forEach(([workloadId, workload]) => {
      set(deploymentSet, ['modules', workloadId], workload);
    });
  }

  // In some cases, `modules` can be undefined. e.g. we add some shared resources to the draft, but no modules we added.
  if (!deploymentSet.modules) {
    deploymentSet.modules = {};
  }

  return isDeploymentSet(deploymentSet) ? deploymentSet : undefined;
};

export const convertPathToArraySegments = (path: string) =>
  path
    .split('/')
    .filter((p) => p)
    .map((p) => decodePathKey(p));

export const checkPathExistsInDeploymentSetWorkload = (
  path: string,
  dsWorkload: Workload
): boolean => Boolean(get(dsWorkload, convertPathToArraySegments(path)));

export const checkPathExistsInDeploymentSet = (
  path: string,
  deploymentSet: DeploymentSet
): boolean => {
  const value = get(deploymentSet, convertPathToArraySegments(path));

  if (typeof value === 'string' && value === '') {
    return true;
  }

  return Boolean(value);
};

export interface ResourceAssociatedItem {
  path: string;
  addedInCurrentDelta: boolean;
}

/**
 * find resource associated ingress rules
 *
 * @param resourceId - the dns resource id
 * @param workload - the workload to look for associated rules in
 */
export const findResourceAssociatedRules = (
  resourceId: string,
  workload?: Workload
): ResourceAssociatedItem | undefined => {
  const rule = Object.entries(workload?.spec?.ingress?.rules ?? {}).find(
    ([ruleId]) => ruleId.split('.')[1] === resourceId
  );
  return rule ? { path: `/spec/ingress/rules/${rule[0]}`, addedInCurrentDelta: false } : undefined;
};

/**
 * finds the resource's associated volume mounts
 *
 * @param resourcePath - the volume resource path
 * @param workload - the workload to look for associated volume mounts
 */
export const findResourceAssociatedVolumeMounts = (
  resourcePath: string,
  sharedOrExternal: 'shared' | 'external',
  workload?: Workload
): ResourceAssociatedItem[] | undefined => {
  const associatedVolumeMounts: { path: string; addedInCurrentDelta: boolean }[] = [];

  if (workload?.spec?.containers) {
    Object.entries(workload?.spec?.containers).forEach(([containerId, container]) => {
      if (container.volume_mounts) {
        Object.entries(container.volume_mounts).forEach(([volumeMountPath, volumeMount]) => {
          // Check if associated mount exists. Check for specific notation.
          let included = false;
          if (sharedOrExternal === 'external') {
            const specPrefix = '/spec/volumes/';
            const externalsPrefix = '/externals/';
            if (resourcePath.startsWith(specPrefix)) {
              const id = resourcePath.substr(specPrefix.length, resourcePath.length);

              if (id && `volumes.${id}` === volumeMount.id) {
                included = true;
              }
            } else if (resourcePath.startsWith(externalsPrefix)) {
              const id = resourcePath.substr(externalsPrefix.length, resourcePath.length);

              if (id && `externals.${id}` === volumeMount.id) {
                included = true;
              }
            }
          } else if (sharedOrExternal === 'shared') {
            const sharedPrefix = '/';
            const id = resourcePath.substr(sharedPrefix.length, resourcePath.length);

            if (id && `externals.${id}` === volumeMount.id) {
              included = true;
            }
          }

          if (included) {
            const addedInCurrentDelta =
              workload &&
              !Boolean(
                getDeploymentSetValueByPath(
                  workload,
                  `/spec/containers/${containerId}/volume_mounts/${encodePathKey(volumeMountPath)}`
                )
              );

            associatedVolumeMounts.push({
              path: `/spec/containers/${containerId}/volume_mounts/${encodePathKey(
                volumeMountPath
              )}`,
              addedInCurrentDelta,
            });
          }
        });
      }
    });
  }

  return associatedVolumeMounts;
};

export const patchDeltaToRemoveResourceAssociatedItems = (
  delta: Partial<DeploymentDelta>[],
  workloadId: string,
  dsWorkload?: Workload,
  associatedRules?: ResourceAssociatedItem,
  associatedVolumeMounts?: ResourceAssociatedItem[]
) => {
  if (associatedRules) {
    const existsInSet = checkPathExistsInDeploymentSetWorkload(
      associatedRules.path,
      cloneDeep(dsWorkload)!
    );
    delta.push(
      createDeploymentDraftPatchValue(
        associatedRules.path,
        existsInSet ? {} : { scope: 'delta' },
        workloadId,
        'remove'
      )
    );
  }
  if (associatedVolumeMounts) {
    associatedVolumeMounts.forEach((associatedVolumeMount) => {
      delta.push(
        createDeploymentDraftPatchValue(
          associatedVolumeMount.path,
          associatedVolumeMount.addedInCurrentDelta ? { scope: 'delta' } : null,
          workloadId,
          'remove'
        )
      );
    });
  }
};

/**
 * applies a delta to a set and returns the new set back
 *
 * @param delta - the delta to apply
 * @param deploymentSet - the set to apply the delta to
 * @param orgId - the current org Id
 * @param appId - the current app id
 */
export const getDeploymentSetFromDelta = async (
  delta: DeploymentDelta,
  deploymentSet: DeploymentSet,
  orgId: string,
  appId: string
): Promise<DeploymentSet> => {
  // apply the delta to get a deployment set
  const newDeploymentSetId = (
    await makeRequest<string>(
      'POST',
      `/orgs/${orgId}/apps/${appId}/sets/${deploymentSet.id}`,
      delta
    )
  ).data;
  // get the new deployment set using the generated id
  const newDeploymentSet = (
    await makeRequest<DeploymentSet>(
      'GET',
      `/orgs/${orgId}/apps/${appId}/sets/${newDeploymentSetId}`,
      delta
    )
  ).data;

  return newDeploymentSet;
};

export const getChangeCountMessage = (delta: DeploymentDelta | undefined) => {
  const workloadChangeCount =
    Number(Object.values(delta?.modules.update || {}).length) +
      Number(Object.values(delta?.modules.add || {}).length) +
      Number(delta?.modules.remove?.length) || 0;
  const sharedModulesChangeCount = Number(delta?.shared?.length) || 0;
  const workloadChangeMessage = workloadChangeCount === 1 ? `workload` : 'workloads';
  const sharedModulesMessage =
    sharedModulesChangeCount === 1 ? 'shared resource' : 'shared resources';
  if (workloadChangeCount === 0 && sharedModulesChangeCount === 0) {
    return '';
  }
  if (workloadChangeCount > 0 && sharedModulesChangeCount > 0) {
    return `Changes to ${workloadChangeCount} ${workloadChangeMessage}, ${sharedModulesChangeCount} ${sharedModulesMessage}`;
  }
  if (workloadChangeCount > 0) {
    return `Changes to ${workloadChangeCount} ${workloadChangeMessage}`;
  }
  if (sharedModulesChangeCount > 0) {
    return `Changes to ${sharedModulesChangeCount} ${sharedModulesMessage}`;
  }
  return '';
};
