import { MonacoEditor, Toggle, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { useWalhallForm } from '@src/utilities/form';

const Split = styled.div<{ toggleActive: boolean }>`
  display: grid;
  ${({ toggleActive }) =>
    toggleActive
      ? css`
          grid-template-columns: 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr;
        `}
`;

interface FormPlaygroundResponseModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  formValues: any;
  modifiedFormValues?: any;
}

export const FormPlaygroundResponseModal = ({
  openState,
  formValues,
  modifiedFormValues,
}: FormPlaygroundResponseModalProps) => {
  // Component state
  const [formValueFormatted, setFormValueFormatted] = useState<string>();
  const [modifiedFormValueFormatted, setModifiedFormValueFormatted] = useState<string>();

  // Form
  const formMethods = useWalhallForm<{ show_resource_form_modifications: boolean }>({
    defaultValues: { show_resource_form_modifications: false },
  });

  const { watch } = formMethods;
  const toggleActive = watch('show_resource_form_modifications');

  useEffect(() => {
    if (formValues) setFormValueFormatted(JSON.stringify(formValues.driver_data, null, 2));
  }, [setFormValueFormatted, formValues]);

  useEffect(() => {
    setModifiedFormValueFormatted(JSON.stringify(modifiedFormValues, null, 2));
  }, [setModifiedFormValueFormatted, formValues, modifiedFormValues]);

  return (
    <WalModal
      size={toggleActive ? 'xlarge' : 'large'}
      openState={openState}
      title={'Form response'}
      content={
        <div>
          <FormProvider {...formMethods}>
            <Toggle
              name={'show_resource_form_modifications'}
              label={'Show resource form modifications'}
            />
          </FormProvider>
          <Split toggleActive={toggleActive}>
            <MonacoEditor
              height={'300px'}
              readOnly
              hideSyntaxDropdown
              fileExtension={'json'}
              value={formValueFormatted}
            />
            {toggleActive && (
              <MonacoEditor
                height={'300px'}
                readOnly
                hideSyntaxDropdown
                fileExtension={'json'}
                value={modifiedFormValueFormatted}
              />
            )}
          </Split>
        </div>
      }
      actions={{
        cancel: {},
      }}
    />
  );
};
