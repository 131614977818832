import {
  ActionButtons,
  AddButtonForm,
  VariablesViewAddEdit,
  VariablesViewAddEditProps,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import usePlaceholders from '@src/hooks/usePlaceholders';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { KeyValue } from '@src/models/general';
import { units } from '@src/styles/variables';

import GetHighlightedFieldValue from './GetHighlightedFieldValue';

const KeyValueEditorContainer = styled.div`
  margin-top: ${units.margin.md};
`;

const AddVariablesFormWrapper = styled.div`
  margin-top: ${units.margin.md};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

interface ConfigJsonProps {
  variableValues: KeyValue[];
  editValue: (oldValue: KeyValue, newValue: KeyValue) => void;
  addValue: (value: KeyValue) => void;
  deleteValue: (key: KeyValue) => void;
  inputsId: string;
}

const VariablesInputs = ({
  variableValues,
  editValue,
  addValue,
  deleteValue,
  inputsId,
}: ConfigJsonProps) => {
  // Component state
  const autoCompleteObj = usePlaceholders();
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [rowToOverrideColumns, setRowToOverrideColumns] = useState<number | undefined>();
  const [showAddModeFormContent, setShowAddModeFormContent] = useState<boolean | undefined>();

  // Zustand
  const { currentDeltaAction } = useDeploymentDeltasStore();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  const { draftModeActive } = useDeploymentOrDeltaContext();

  /**
   * saves the given configuration
   *
   * @param formValue - configuration values
   * @param index - the edited field index
   */
  const saveConfigurationClick = (formValue: KeyValue, index: number) => {
    const oldValue = variableValues[index];
    const newValue = { key: formValue.key, value: formValue.value, index };

    editValue(oldValue, newValue);
  };

  /**
   * delete a key value form config object
   *
   * @param key - string of the element to remove
   */
  const deleteKeyValue = (keyValue: KeyValue) => {
    deleteValue({
      key: keyValue.key,
      value: keyValue.value,
    });
  };

  /**
   * add an empty key/value to the config object
   *
   * @param formValue - the new key value from the form
   */
  const addKeyValue = (formValue: any) => {
    const key = formValue[`${inputsId}-key`];
    const value = formValue[`${inputsId}-value`];

    addValue({ key, value, index: variableValues.length });
  };

  const handleEdit = (index: number) => {
    setExpandedRow(index);
    setRowToOverrideColumns(index);
  };

  const handleCancelEditMode = () => {
    setExpandedRow(undefined);
    setRowToOverrideColumns(undefined);
  };

  const handleCancelAddMode = () => {
    setShowAddModeFormContent(false);
  };

  const autoCompleteOptions = {
    lookupObject: autoCompleteObj,
    dropdownMenuMaxHeight: 200,
    delimiterString: {
      start: '${',
      end: '}',
    },
  };

  const formatValueFieldText = (value: string) =>
    GetHighlightedFieldValue(value, autoCompleteOptions);

  const columns: WalTableColumn<KeyValue>[] = [
    {
      label: uiTranslations.KEY_LABEL,
      prop: 'key',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 380,
        maxCharacters: 35,
        text: (row) => row.data.key,
      },
    },
    {
      label: uiTranslations.VALUE_LABEL,
      prop: 'value',
      ellipsisTooltip: {
        maxWidth: 380,
        maxCharacters: 35,
        text: (row) => row.data.value,
        showGenericText: true,
      },
      template: (row) => (
        <p>{formatValueFieldText(row.data.value) || <StyledDash>-</StyledDash>}</p>
      ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        if (draftModeActive) {
          return (
            <ActionsWrapper>
              <ActionButtons
                buttons={['edit', 'delete']}
                onEdit={() => handleEdit(variableValues.indexOf(row.data))}
                onDelete={() => deleteKeyValue(row.data)}
              />
            </ActionsWrapper>
          );
        }
      },
    },
  ];
  const renderVariablesViewAddEdit = ({
    id,
    keyValue,
    onSave,
    onCancel,
    mode,
    isSaving,
    existingKeys = undefined,
  }: VariablesViewAddEditProps) => (
    <VariablesViewAddEdit
      key={keyValue?.key}
      id={id}
      keyValue={keyValue}
      onSave={onSave}
      onCancel={onCancel}
      mode={mode}
      autoCompleteOptions={autoCompleteOptions}
      isSaving={isSaving}
      existingKeys={existingKeys}
    />
  );

  const renderAddVariablesSection = (
    <AddButtonForm
      showFormContentState={[showAddModeFormContent, setShowAddModeFormContent]}
      buttonText={uiTranslations.ADD_VARIABLES}
      readonly={!draftModeActive}
      formContent={
        <>
          {renderVariablesViewAddEdit({
            id: inputsId,
            mode: 'add',
            onSave: addKeyValue,
            onCancel: handleCancelAddMode,
            isSaving: currentDeltaAction === 'add-variables',
            existingKeys: variableValues.map((c) => c.key),
          })}
        </>
      }
    />
  );

  return (
    <>
      <AddVariablesFormWrapper>{renderAddVariablesSection}</AddVariablesFormWrapper>
      <KeyValueEditorContainer>
        <WalTable
          caption={sectionsTranslations.VARIABLES}
          columns={columns}
          expandedRowState={[expandedRow, setExpandedRow]}
          disableScrolling
          tableStyle={'expandable'}
          tableRowStyle={'base'}
          rows={
            variableValues
              ? variableValues?.map((values, rowIndex) => ({
                  data: values,
                  expandableContent: renderVariablesViewAddEdit({
                    id: inputsId + rowIndex,
                    keyValue: values,
                    mode: 'view',
                  }),
                  overrideColumns: rowToOverrideColumns === rowIndex,
                  columnOverrideTemplate: renderVariablesViewAddEdit({
                    id: inputsId + rowIndex,
                    keyValue: values,
                    mode: 'edit',
                    onSave: (formValue) => saveConfigurationClick(formValue, rowIndex),
                    onCancel: () => handleCancelEditMode(),
                  }),
                }))
              : []
          }
        />
      </KeyValueEditorContainer>
    </>
  );
};

export default VariablesInputs;
