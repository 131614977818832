import styled from 'styled-components';

const StyledSkeleton = styled.div<{ height: number; width?: number }>`
  width: ${({ width }) => (width ? width + 'px' : `100%`)};
  height: ${({ height }) => (height ? height + 'px' : `100px`)};
  border-radius: 4px;
  position: relative;
  padding: 1px;
  background-color: ${({ theme }) => theme.color.baseBrighter};

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }

  & .loaderBar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ theme }) =>
      `linear-gradient(to right, ${theme.color.baseBrighter}, ${theme.color.baseShadow}, ${theme.color.baseBrighter});`};
    width: 50%;
    animation: moving 0.9s linear infinite;
  }

  @keyframes moving {
    0% {
      left: 0%;
    }

    100% {
      left: 50%;
    }
  }
`;

type PositiveNumber<T extends number> = `${T}` extends '0' | `-${any}` | `${any}.${any}`
  ? never
  : T;

export interface SkeletonItemProps {
  height?: PositiveNumber<number>;
  width?: PositiveNumber<number>;
  className?: string;
}

export const SkeletonItem = ({ height = 136, width, className }: SkeletonItemProps) => {
  return (
    <StyledSkeleton className={`loader ${className || ''}`} height={height} width={width}>
      <div className={'loaderBar'} />
    </StyledSkeleton>
  );
};
