import { WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useArtefactsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactsQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { useWalhallForm } from '@src/utilities/form';

import SelectImageStep from './components/SelectImageStep';

interface ChangeImageModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  deltaPath: string;
  /* whether the current image is external */
  currentImageIsExternal?: boolean;
}

const ChangeImageModal = ({
  openState,
  currentImageIsExternal,
  deltaPath,
}: ChangeImageModalProps) => {
  const [open, setOpen] = openState;
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm<{ imageName: string; containerName: string }>();

  // react query
  const { data: artefacts = [] } = useArtefactsQuery();

  // Delta hooks
  const { updateWorkload, data: existingImageName } = useDeltaUtils<string>(deltaPath);

  const handleUpdateImageName = (imageName: string) => {
    if (open) {
      updateWorkload([
        {
          op: 'replace',
          value: imageName,
        },
      ]);
      setOpen(false);
    }
  };
  return (
    <FormProvider {...formMethods}>
      <WalModal
        openState={openState}
        title={imageTranslations.SELECT_IMAGE_TITLE}
        size={'large'}
        content={
          <SelectImageStep
            artefacts={artefacts}
            currentImageIsExternal={currentImageIsExternal}
            existingImageName={existingImageName}
            onUpdateImageName={handleUpdateImageName}
          />
        }
        actions={{
          main: {
            text: uiTranslations.CLOSE,
            props: {
              onClick: () => setOpen(false),
            },
          },
        }}
      />
    </FormProvider>
  );
};

export default ChangeImageModal;
