import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components';

import { darkPalette, lightPalette } from './color-palette';

const darkTheme: DefaultTheme = {
  dark: true,
  color: {
    ...darkPalette,
    // Extra
    buttonGreen: '#28A745',
    buttonGreenHover: rgba('#28A745', 0.8),
    logo: darkPalette.white,
    header: rgba(darkPalette.baseLayer, 0.74),

    mainTransparentFlat: '#27384d',
    alertTransparentFlat: '#4b282f',
  },
};

const lightTheme: DefaultTheme = {
  dark: false,
  color: {
    ...lightPalette,
    // Extra
    buttonGreen: '#28A745',
    buttonGreenHover: rgba('#28A745', 0.8),

    logo: lightPalette.main,
    header: rgba(lightPalette.baseLayer, 0.74),

    mainTransparentFlat: '#C6D9F0',
    alertTransparentFlat: '#EBC9D2',
  },
};

export { darkTheme, lightTheme };
