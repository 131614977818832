import { WalInput } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';

const WorkloadNameInput = () => {
  // i18n
  const { t } = useTranslation();
  const viewApplicationTranslations = t('VIEW_APPLICATION');

  const { workloadIds } = useDeltaUtils();

  return (
    <WalInput
      name={'workloadName'}
      className={'mb-md'}
      required
      maxLength={50}
      label={viewApplicationTranslations.MODULE_NAME_LABEL}
      labelExtensionText={viewApplicationTranslations.MODULE_NAME_LABEL_EXT}
      standardValidation={[
        { type: 'idStartWithAlphabetic' },
        { type: 'existingId', ids: workloadIds },
      ]}
    />
  );
};

export default WorkloadNameInput;
