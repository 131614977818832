import { MultistepModal } from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import { useWalhallForm } from '@src/utilities/form';

import SharedAppValueHistoryPurge from './SharedAppValueHistoryPurge';
import SharedAppValueHistoryRevert from './SharedAppValueHistoryRevert';
import { getSelectedVersion } from './SharedAppValueHistoryUtils';
import SharedAppValuesHistoryTable from './SharedAppValuesHistoryTable';

export type SharedAppValueHistoryProps = {
  onClose: () => void;
  keyValue: string;
  /** If this component is used in Environment Override version values screen */
  isEnvironmentOverride?: boolean;
};

type PurgeFormProps = { comment: string; purgeChangeId: string; revertChangeId: string };
type OnSubmitType = (onSubmitSuccess: () => void) => void;

/**
 * Shows modal for viewing version values history
 * Open/close state of the modal is handled by the parent invoking this modal
 */
const SharedAppValuesHistory = ({
  keyValue,
  onClose,
  isEnvironmentOverride = false,
}: SharedAppValueHistoryProps) => {
  // state used to store submit handler function. This function also has a callback which can be called after submit is success and done
  const [onSubmit, setOnSubmit] = useState<OnSubmitType | undefined>(undefined);
  const [selectedChangeId, setSelectedChangeId] = useState<string | undefined>(undefined);
  // state for handling current step in the multistep modal
  const [currentStepState, setCurrentStepState] = useState(0);

  // state to know which screen to show in second step
  const [secondScreen, setSecondScreen] = useState<'purge' | 'revert'>('purge');

  const formMethods = useWalhallForm<PurgeFormProps>();

  // translations
  const { t } = useTranslation();
  const valueHistoryTranslations = t('APP_SETTINGS').VALUE_HISTORY;

  // React Query
  const { data: sharedAppValuesHistory } = useSharedAppValuesHistoryQuery(isEnvironmentOverride);

  const purgeHistoryItem = useMemo(
    () => getSelectedVersion(sharedAppValuesHistory, keyValue, selectedChangeId),
    [sharedAppValuesHistory, keyValue, selectedChangeId]
  );

  return (
    <FormProvider {...formMethods}>
      <MultistepModal
        openState={[true, onClose]}
        currentStepState={[currentStepState, setCurrentStepState]}
        size={'xlarge'}
        handleFormSubmit={() => {
          // go back to first step after submit is successful
          if (onSubmit) onSubmit(() => setCurrentStepState(0));
        }}
        backgroundColor={'baseDarker'}
        steps={[
          {
            title: t('APP_SETTINGS.VALUE_HISTORY.TITLE', { keyValue }),
            content: (
              <SharedAppValuesHistoryTable
                isEnvironmentOverride={isEnvironmentOverride}
                keyValue={keyValue}
                onPurge={(changeId) => {
                  setCurrentStepState(1); // go to second step
                  setSecondScreen('purge'); // set second step as purge
                  setSelectedChangeId(changeId); // set the changeId
                }}
                onRevert={(changeId) => {
                  setCurrentStepState(1); // go to second step
                  setSecondScreen('revert'); // set second step as revert
                  setSelectedChangeId(changeId); // set the changeId
                }}
              />
            ),
            actions: [],
          },
          secondScreen === 'purge'
            ? {
                title: valueHistoryTranslations.PURGE_VERSION,
                content: purgeHistoryItem ? (
                  <SharedAppValueHistoryPurge
                    selectedChangeId={selectedChangeId}
                    keyValue={keyValue}
                    // while setting function to state, need to pass it as return of a function execution.
                    // Otherwise if we just pass the function directly, the function is executed and its return type is set as value
                    setOnSubmit={(onSubmitFunc) => setOnSubmit(() => onSubmitFunc)}
                    isEnvironmentOverride={isEnvironmentOverride}
                    purgeHistoryItem={purgeHistoryItem}
                  />
                ) : (
                  <></>
                ),
                actions: [
                  {
                    label: valueHistoryTranslations.BACK,
                  },
                  {
                    label: valueHistoryTranslations.PURGE_VERSION,
                    variant: 'danger',
                    type: 'submit',
                  },
                ],
              }
            : {
                title: valueHistoryTranslations.REVERT_VALUE,
                content: purgeHistoryItem ? (
                  <SharedAppValueHistoryRevert
                    keyValue={keyValue}
                    isEnvironmentOverride={isEnvironmentOverride}
                    // while setting function to state, need to pass it as return of a function execution.
                    // Otherwise if we just pass the function directly, the function is executed and its return type is set as value
                    setOnSubmit={(onSubmitFunc) => setOnSubmit(() => onSubmitFunc)}
                    historyItem={purgeHistoryItem}
                  />
                ) : undefined,
                actions: [
                  {
                    label: valueHistoryTranslations.BACK,
                  },
                  {
                    label: valueHistoryTranslations.REVERT_VALUE,
                    variant: 'primary',
                    type: 'submit',
                  },
                ],
              },
        ]}
      />
    </FormProvider>
  );
};

export default SharedAppValuesHistory;
