import { KeyValueEntriesCard } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { replacePlaceholdersWithConstant } from '@src/components/shared/ViewWorkloadProfile/utils/utils';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { UpdateWorkloadChanges, useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import usePlaceholders from '@src/hooks/usePlaceholders';
import { KeyValue } from '@src/models/general';
import { LABEL_KEY_REGEXP, LABEL_VALUE_REGEXP } from '@src/utilities/string-utility';

interface LabelsProps {
  deltaPath: string;
}

const Labels = ({ deltaPath }: LabelsProps) => {
  const { data, updateWorkload } = useDeltaUtils(deltaPath);
  const autoCompleteObj = usePlaceholders(true);

  // i18n
  const { t } = useTranslation('workloadProfile');

  const labelsTranslations = t('FEATURES').LABELS;

  const labels = Object.entries(data || {}).map(([key, value]) => ({
    key,
    value,
  }));

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const saveChanges = (formData: { entries: KeyValue[] }) => {
    const deltaToPatch: UpdateWorkloadChanges = [];

    labels.forEach((label) => {
      deltaToPatch.push({
        key: label.key,
        op: 'remove',
      });
    });

    formData.entries.forEach((entry) => {
      deltaToPatch.push({
        key: entry.key,
        op: 'add',
        value: entry.value,
      });
    });
    updateWorkload(deltaToPatch);
  };

  return (
    <KeyValueEntriesCard
      title={labelsTranslations.LABELS}
      handleModalFormSubmit={saveChanges}
      emptyStateText={labelsTranslations.NO_LABELS_DEFINED}
      entriesAmountTextSingular={labelsTranslations.LABEL}
      entriesAmountTextPlural={labelsTranslations.LABELS}
      entries={labels}
      addButtonText={labelsTranslations.ADD}
      keyValidation={{
        pattern: LABEL_KEY_REGEXP,
        message: labelsTranslations.KEY_INVALID,
        maxLength: 63,
      }}
      valueValidation={{
        pattern: LABEL_VALUE_REGEXP,
        message: labelsTranslations.VALUE_INVALID,
        maxLength: 63,
        transform: replacePlaceholdersWithConstant,
      }}
      autocompleteOptions={{
        lookupObject: autoCompleteObj,
      }}
      editingEnabled={draftModeActive}
      errorMoreInformationLink={labelsTranslations.ERROR_MORE_INFORMATION_LINK}
    />
  );
};

export default Labels;
