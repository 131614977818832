import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { DeploymentError } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../environmentQueryKeys';

const useDeploymentErrorsQuery = (
  deploymentId?: string,
  enabled: boolean = true,
  customParams?: {
    appId: string;
    envId: string;
  }
): QueryResponse<DeploymentError[]> & { hasWorkloadError: (workloadId: string) => boolean } => {
  const {
    orgId,
    appId: routerAppId,
    envId: routerEnvId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const appId = customParams?.appId || routerAppId;
  const envId = customParams?.envId || routerEnvId;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentQueryKeys.environmentDeploymentErrors(orgId, appId, envId, deploymentId),
    queryFn: () =>
      makeRequest<DeploymentError[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/deploys/${deploymentId}/errors`
      ),
    enabled: Boolean(orgId && appId && envId && deploymentId) && enabled,
  });

  const hasWorkloadError = (workloadId: string) =>
    Boolean(
      data?.data.some((error) => error.scope === 'workload' && error.object_id === workloadId)
    );
  return { ...queryResult, data: data?.data, hasWorkloadError };
};

export default useDeploymentErrorsQuery;
