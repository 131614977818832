import { DropdownItem, MenuItem, WalDropdownMenu } from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { IngressRule } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import IngressEntry from './components/IngressEntry';
import IngressPathModal from './components/IngressPathModal';

const AddADNSResource = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

interface IngressRoutingProps {
  deltaPath: string;
}

const IngressRouting = ({ deltaPath }: IngressRoutingProps) => {
  // Component state
  const [pathModalOpen, setPathModalOpen] = useState(false);

  const [resourceToAdd, setResourceToAdd] = useState<ResourceDependency & { path: string }>();

  const [sharedOrExternal, setSharedOrExternal] = useState<'shared' | 'externals'>();

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('VIEW_MODULE').EXTERNAL_RESOURCES;
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // Form
  const formMethods = useWalhallForm();
  const { sharedResourcesList } = useDeltaUtils();

  const rulesDeltaPath = `${deltaPath}/rules`;

  const {
    data: ingressRulesMap,
    updateWorkload: updateIngressRules,
    workloadResourceDependencies,
  } = useDeltaUtils<Record<string, IngressRule>>(rulesDeltaPath);

  const routingResources = useMemo(
    () =>
      [...workloadResourceDependencies, ...sharedResourcesList].filter((r) => r.category === 'dns'),
    [workloadResourceDependencies, sharedResourcesList]
  );

  const items: DropdownItem<ResourceDependency & { path: string }>[] = useMemo(
    () =>
      routingResources
        .map((r) => ({
          id: r.id,
          value: r,
          label: r.id,
        }))
        .filter((r) => {
          const existingPaths = Object.entries(ingressRulesMap ?? {})
            .filter(([, val]) => Object.keys(val.http).length)
            .map(([k]) => k.split('.')[1]);
          return !existingPaths.includes(r.id);
        }),
    [routingResources, ingressRulesMap]
  );

  const onItemClick = (_: string, item: DropdownItem<ResourceDependency & { path: string }>) => {
    openModal(item.value);
  };

  const openModal = (resource: ResourceDependency & { path: string }) => {
    setSharedOrExternal(resource.externalOrShared);
    setPathModalOpen(true);
    setResourceToAdd(resource);
  };

  const onIngressMenuClick = (item: MenuItem<'edit' | 'delete'>, resourceId: string) => {
    if (item.value === 'edit') {
      setSharedOrExternal(resourceId.split('.')[0] as 'shared' | 'externals');
      setResourceToAdd(routingResources.find((r) => r.id === resourceId.split('.')[1]));
      setPathModalOpen(true);
    } else if (item.value === 'delete') {
      updateIngressRules([
        {
          key: resourceId,
          op: 'remove',
        },
      ]);
    }
  };

  return (
    <>
      {Object.entries(ingressRulesMap || {}).map(
        ([ruleId, rule]) =>
          Object.keys(rule.http).length > 0 && (
            <IngressEntry
              key={ruleId}
              ruleId={ruleId}
              rule={rule}
              onIngressMenuClick={onIngressMenuClick}
            />
          )
      )}

      <>
        {Object.keys(ingressRulesMap || {}).length <= 0 && !draftModeActive && (
          <span className={'txt-md'}>{sectionsTranslations.NO_INGRESS_DEFINED}</span>
        )}
        {routingResources.length === 0 && draftModeActive && (
          <AddADNSResource>
            <Trans
              defaults={resourcesTranslations.ADD_A_DNS_RESOURCE}
              components={{ italic: <i /> }}
            />
          </AddADNSResource>
        )}
        {draftModeActive && (
          //  TODO: This doesn't need form. WAL-3946
          <FormProvider {...formMethods}>
            <WalDropdownMenu
              items={items}
              defaultText={resourcesTranslations.SELECT_DNS_RESOURCE}
              fullWidth
              menuSize={'parent'}
              disabled={items.length === 0}
              onItemClick={onItemClick}
            />
          </FormProvider>
        )}
      </>
      {resourceToAdd && pathModalOpen && sharedOrExternal && (
        <IngressPathModal
          type={sharedOrExternal}
          openState={[pathModalOpen, setPathModalOpen]}
          resource={resourceToAdd}
          deltaPath={rulesDeltaPath}
        />
      )}
    </>
  );
};

export default IngressRouting;
