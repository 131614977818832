import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { windowEnv } from '@src/environment';
import { MatchParams } from '@src/models/routing';

import useGetCurrentUserQuery from './react-query/user/useGetCurrentUserQuery';

export const useDatadogReplay = () => {
  const [replayEnabled, setReplayEnabled] = useState(false);

  const { data: user } = useGetCurrentUserQuery();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  useEffect(() => {
    if (
      !replayEnabled &&
      user &&
      windowEnv.ENVIRONMENT_NAME === 'production' &&
      orgId &&
      !orgId.startsWith('robot') // do not start if its robot test
    ) {
      datadogRum.startSessionReplayRecording();
      setReplayEnabled(true);
    }
  }, [replayEnabled, setReplayEnabled, user, orgId]);
};
