import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';

/**
 * Generic component for a protected route.
 */
const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { data: user, isFetching } = useGetCurrentUserQuery();

  return <>{user || isFetching ? children : <Navigate to={'/auth/login'} />}</>;
};

export default PrivateRoute;
