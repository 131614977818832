import { merge } from 'lodash';

import { MarginSizes } from './utils';
import { units } from './variables';

const directions = {
  l: 'left',
  t: 'top',
  b: 'bottom',
  r: 'right',
  x: 'x',
  y: 'y',
  '': '',
} as const;
const styles = { m: 'margin', p: 'padding' } as const;

const generateStyle = (
  styleName: 'margin' | 'padding',
  styleDir: keyof typeof directions,
  val: string
) => {
  // style of p-md, p-lg etc
  if (styleDir === '') {
    return `${styleName}: ${val}`;
    // style of px-lg, py-md etc
  } else if (styleDir === 'x') {
    return `${styleName}-left: ${val};\n  ${styleName}-right: ${val};`;
  } else if (styleDir === 'y') {
    return `${styleName}-top: ${val};\n  ${styleName}-bottom: ${val};`;
  } else {
    return `${styleName}-${directions[styleDir]}: ${val}`;
  }
};

const spacingStyles = Object.entries(styles).flatMap(([abbr, styleName]) =>
  (Object.keys(directions) as (keyof typeof directions)[]).flatMap((direction) =>
    Object.entries(units[styleName]).map(([dimension, value]) => ({
      // abbr: m,p ; direction: l, r, p ;dimension: xl,lg,sm ; styleName: margin |padding ;  value: 1.5rem
      [`${abbr}${direction}-${dimension}`]: generateStyle(styleName, direction, value),
    }))
  )
);

export const spacingStylesMap = spacingStyles.reduce(merge);

type SpacingType = keyof typeof styles;
type SpacingDirections = keyof typeof directions;

// Combines type and direction. Adds the single types (p & m) e.g. 'p-sm'
type TypeAndDirection = `${SpacingType}${SpacingDirections}`;
export type SpacingClassname = `${TypeAndDirection}-${MarginSizes}`;
