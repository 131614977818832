import { WalLabel } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import AppMembersAddMode from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/AppMembers/components/AppMembersAddMode';
import AppMembersViewEdit from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/AppMembers/components/AppMembersViewEdit';
import useRoleCreateMutation from '@src/hooks/react-query/roles/mutations/useRoleCreateMutation';
import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import useRoleUpdateMutation from '@src/hooks/react-query/roles/mutations/useRoleUpdateMutation';
import useAppRolesQuery from '@src/hooks/react-query/roles/queries/useAppRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { AppRoles, OrgRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

const LabelsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${rem(275)} ${rem(270)} ${rem(170)} 1fr;
  margin-top: ${units.margin.lg};
`;

const AppMembers = () => {
  // Component state
  const [editable, setEditable] = useState(false);
  const [usersToAdd, setUsersToAdd] = useState<Role<OrgRoles>[]>([]);
  const [resetInput, setResetInput] = useState(false);
  const [openConfirmDowngradeModal, setOpenConfirmDowngradeModal] = useState<boolean>(false);
  const [updateRoleOfMember, setUpdateRoleOfMember] = useState<{
    memberId: string;
    role: string;
  }>();

  // Context
  const { data: user } = useGetCurrentUserQuery();
  const { orgRole, appRole } = useGetUserRoles();

  // i18n
  const { t } = useTranslation();
  const translations = t('APP_SETTINGS').APP_MEMBERS;

  // Router hooks
  const { appId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: appRoles = [] } = useAppRolesQuery();

  // Mutations
  const { mutate: createRole } = useRoleCreateMutation({ type: 'apps', id: appId });
  const { mutate: deleteRole } = useRoleDeleteMutation({ type: 'apps', id: appId });
  const {
    mutate: updateRole,
    isSuccess: isUpdatedSuccessfully,
    reset: resetUpdateMutation,
  } = useRoleUpdateMutation({ type: 'apps', id: appId });

  useEffect(() => {
    if (isUpdatedSuccessfully) {
      setOpenConfirmDowngradeModal(false);
      resetUpdateMutation();
    }
  }, [isUpdatedSuccessfully, resetUpdateMutation]);

  useEffect(() => {
    setEditable(Boolean(appRole && appRole === 'owner') || orgRole === 'administrator');
  }, [appRole, orgRole]);

  const handleAddMemberSubmit = (formValues: { role: AppRoles }) => {
    usersToAdd.map((u) => createRole({ id: u.id, role: formValues.role }));
    setUsersToAdd([]);
    setResetInput(true);
  };

  /**
   * checks if the user is downgrading his role
   */
  const isDowngradingRole = (member: Role<AppRoles>, role: string): boolean => {
    // if the user is changing their own role
    if (member.email === user?.email) {
      if (appRole === 'owner' && role !== 'owner') {
        return true;
      } else if (appRole === 'developer' && role === 'viewer') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkUpdateMemberRole = (member: Role<AppRoles>, role: string) => {
    if (isDowngradingRole(member, role)) {
      setUpdateRoleOfMember({ memberId: member.id, role });
      setOpenConfirmDowngradeModal(true);
    } else {
      updateMemberRole(member.id, role);
    }
  };

  const updateMemberRole = (id: string, role: string) => {
    updateRole({ roleId: id, newRole: role });
  };

  const deleteMember = (roleId: string) => {
    deleteRole(roleId);
  };

  return (
    <>
      {editable && (
        <AppMembersAddMode
          onAdd={handleAddMemberSubmit}
          resetInputState={[resetInput, setResetInput]}
          usersToAddState={[usersToAdd, setUsersToAdd]}
        />
      )}
      {appRoles.length > 0 && (
        <LabelsWrapper>
          <WalLabel>{translations.NAME}</WalLabel>
          <WalLabel>{translations.EMAIL}</WalLabel>
          <WalLabel>{translations.ROLE}</WalLabel>
          <WalLabel>{translations.USER_TYPE}</WalLabel>
        </LabelsWrapper>
      )}
      {appRoles.map((member) => (
        <AppMembersViewEdit
          key={member.id}
          member={member}
          onConfirm={checkUpdateMemberRole}
          onDelete={deleteMember}
          editable={editable}
        />
      ))}
      {updateRoleOfMember && (
        <ConfirmDeleteModal
          title={translations.CHANGE_ROLE_CONFIRMATION_TITLE}
          confirmMessage={translations.CHANGE_ROLE_CONFIRMATION_TEXT}
          state={[openConfirmDowngradeModal, setOpenConfirmDowngradeModal]}
          deleteButtonText={translations.CHANGE_ROLE}
          deleteConfirmedCallback={() =>
            updateMemberRole(updateRoleOfMember?.memberId, updateRoleOfMember?.role)
          }
        />
      )}
    </>
  );
};
export default AppMembers;
