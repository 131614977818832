import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import Icon from '../../base/Icon/Icon';
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';
import Filters, { FilterItem, FilterOption, GroupByItem } from '../Filters/Filters';
import { DropdownItem } from '../Menu/DropdownMenu/DropdownMenu';
import { WalTable, WalTableColumn, WalTableProps, WalTableRow } from '../Table/Table';

const GroupBySection = styled.div`
  margin-bottom: ${units.margin.lg};
`;

const GroupedTable = styled(WalTable)<{ alignGroupedTableCellByWidthOf?: string }>`
  ${({ alignGroupedTableCellByWidthOf }) =>
    alignGroupedTableCellByWidthOf &&
    css`
      td {
        width: ${alignGroupedTableCellByWidthOf};
        word-break: break-all;
      }
    `}

  ${({ alignGroupedTableCellByWidthOf, tableStyle }) =>
    alignGroupedTableCellByWidthOf &&
    tableStyle === 'expandable' &&
    css`
      td:first-child {
        width: 40px;
      }
    `}
`;

const GroupByTitle = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: ${units.margin.sm};
  font-weight: normal;
`;

export interface FilterTableProps extends WalTableProps {
  filterDropdownItems?: DropdownItem<string>[];
  groupByOptions?: GroupByItem<string>[];
  /* This is used to give columns of grouped tables a fixed size so they would be vertically aligned. You can pass any value of css width property https://developer.mozilla.org/en-US/docs/Web/CSS/width*/
  alignGroupedTableCellByWidthOf?: string;
}

const FilterTable = (props: FilterTableProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const { columns, rows, filterDropdownItems, groupByOptions } = props;

  const filterOptions: FilterOption[] | undefined = filterDropdownItems?.map((dropdownItem) => ({
    ...dropdownItem,
    comboSelectItems: uniqBy(
      rows.map((row) => ({
        label: row.data[dropdownItem.id],
        value: row.data[dropdownItem.id],
        searchString: row.data[dropdownItem.id],
        id: row.data[dropdownItem.id],
      })),
      'value'
    ),
  }));

  const [filters, setFilters] = useState<Record<string, FilterItem[]>>({});
  const [groupByItem, setGroupByItem] = useState<GroupByItem<string>>();

  const filteredRows: WalTableRow[] = useMemo(() => {
    return (
      Object.entries(filters).reduce((newRows, [key, filterItems]) => {
        return newRows.filter((row) =>
          filterItems.some((filterItem) => row.data[key]?.includes(filterItem.value))
        );
      }, rows) || rows
    );
  }, [filters, rows]);

  const filteredColumns: WalTableColumn[] = columns.filter(
    (col) => col.prop !== groupByItem?.id && col.prop !== groupByItem?.parent
  );

  if (rows.length <= 0) return <></>;

  const handleFilterChange = (newFilters: Record<string, FilterItem[]>) => {
    setFilters(newFilters);
  };

  const handleGroupByChange = (item: GroupByItem<string>) => {
    setGroupByItem(item);
  };

  const renderGroupedTables = () => {
    if (groupByItem) {
      const groups = uniqBy(
        filteredRows.map((row) => ({
          groupedByValue: row.data[groupByItem.value],
          groupedByParentValue: groupByItem.parent && row.data[groupByItem.parent],
        })),
        (row) => `${row.groupedByParentValue}${row.groupedByValue}`
      );
      return groups?.map((group) => (
        <GroupBySection key={`${group.groupedByParentValue}${group.groupedByValue}`}>
          <GroupByTitle>
            {group.groupedByParentValue && (
              <>
                {group.groupedByParentValue}{' '}
                <Icon name={'arrow-right'} marginRight={'md'} marginLeft={'md'} size={12} />
              </>
            )}
            {group.groupedByValue}
          </GroupByTitle>
          <GroupedTable
            {...props}
            className={'grouped-table'}
            columns={filteredColumns}
            rows={filteredRows
              .filter((row) =>
                group && groupByItem && groupByItem.parent
                  ? row.data[groupByItem.parent] === group.groupedByParentValue
                  : true
              )
              .filter((row) =>
                group && groupByItem ? row.data[groupByItem.value] === group.groupedByValue : true
              )}
          />
        </GroupBySection>
      ));
    }
  };

  return (
    <>
      {(filterOptions || groupByOptions) && (
        <Filters
          filterOptions={filterOptions}
          groupByOptions={groupByOptions}
          onFiltersChange={handleFilterChange}
          onGroupByChange={handleGroupByChange}
        />
      )}
      {filteredRows.length !== 0 ? (
        groupByItem ? (
          renderGroupedTables()
        ) : (
          <WalTable {...props} rows={filteredRows} columns={columns} />
        )
      ) : (
        <EmptyStateCard>{uiTranslations.NO_ITEMS_FILTERS}</EmptyStateCard>
      )}
    </>
  );
};
export default FilterTable;
