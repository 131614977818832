import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { environmentTypesQueryKeys } from '@src/hooks/react-query/environment-types/environmentTypesQueryKeys';
import { userQueryKeys } from '@src/hooks/react-query/user/userQueryKeys';
import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

export const useEnvironmentTypesDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<EnvironmentType>,
    AxiosError,
    {
      envTypeId: string;
      force?: boolean;
    }
  >({
    mutationFn: ({ envTypeId, force }) => {
      return makeRequest(
        'DELETE',
        `/orgs/${orgId}/env-types/${envTypeId}${force ? '?force=true' : ''}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: environmentTypesQueryKeys.listEnvironmentTypes(orgId),
      });
      queryClient.invalidateQueries({
        queryKey: userQueryKeys.currentUser(),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};
