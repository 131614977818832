import { rem } from 'polished';
import React, { KeyboardEvent, MouseEvent } from 'react';
import styled, { css } from 'styled-components/macro';

import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import Tooltip from '..//Tooltip/Tooltip';
import { Icon, IconNames } from '../Icon/Icon';

interface ChipWrapperProps {
  variant: 'default' | 'blue';
  evenBorderRadius?: boolean;
  clickable?: boolean;
  size?: 'small' | 'medium';
}

export const ChipWrapper = styled.div<ChipWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: ${({ size }) => (size === 'small' ? rem(22) : rem(24))};
  padding: ${rem(3)} ${rem(5)} ${rem(3)} ${rem(5)};
  border-top: 1px solid hsla(0, 0%, 100%, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${rem(4)} ${rem(12)} ${rem(12)} ${rem(4)};
  ${({ evenBorderRadius }) =>
    evenBorderRadius &&
    css`
      border-radius: ${rem(4)};
    `}
  box-shadow: 0 ${rem(1)} ${rem(4)} 0 ${({ theme }) => theme.color.baseShadow};
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.baseBrightest};
  font-size: ${units.fontSize.sm};
  margin: ${rem(1)} 0;
  white-space: nowrap;
  ${({ variant }) =>
    variant === 'blue' &&
    css`
      color: ${({ theme }) => theme.color.text};
      background-color: ${({ theme }) => theme.color.mainTransparent};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.color.mainBrighter};
        svg {
          filter: unset;
          path {
            fill: ${({ theme }) => theme.color.mainBrighter};
          }
        }
      }
    `}
`;

const ChipLabel = styled.span<{ removeButton?: boolean; invertEllipsis?: boolean }>`
  line-height: 1.5;
  ${({ removeButton }) =>
    removeButton &&
    css`
      margin-right: ${rem(5)};
    `}

  ${({ invertEllipsis }) => ellipsisStyle(150, invertEllipsis)}
`;

const RemoveSVGWrapper = styled.div`
  position: relative;
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  p {
    ${ellipsisStyle(100)}
  }
`;

interface ChipProps {
  id: string;
  label: string;
  onRemove?: (label: string, e?: MouseEvent) => void;
  variant?: 'default' | 'blue';
  evenBorderRadius?: boolean;
  onChipClick?: (e: MouseEvent) => void;
  onChipKeyDown?: (e: KeyboardEvent) => void;
  className?: string;
  iconLeft?: IconNames;
  size?: 'small' | 'medium';
  noTooltipOnHover?: boolean;
  /** if the ellipsis ... should be shown in the start of the chip rather than then end */
  invertEllipsis?: boolean;
}

export const Chip = ({
  id,
  label,
  onRemove,
  variant = 'default',
  evenBorderRadius = false,
  onChipClick,
  onChipKeyDown,
  className,
  iconLeft,
  noTooltipOnHover,
  invertEllipsis,
  size = 'small',
}: ChipProps) => {
  const onRemoveKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyBindings.ENTER && onRemove) {
      onRemove(id);
    }
  };
  return (
    <ChipWrapper
      className={className}
      variant={variant}
      evenBorderRadius={evenBorderRadius}
      clickable={Boolean(onChipClick)}
      onClick={onChipClick}
      onKeyDown={onChipKeyDown}
      tabIndex={Boolean(onChipClick) ? 0 : undefined}
      size={size}>
      {iconLeft && <Icon size={12} name={iconLeft} marginRight={label ? 'sm' : undefined} />}
      {label?.length >= 20 && !noTooltipOnHover ? (
        <StyledTooltip
          disableTooltip={label?.length <= 20}
          text={label?.replace(/(.{1,40})/g, '$1\u00ad')}
          triggerComponent={<p>{label}</p>}
          position={'left'}
        />
      ) : (
        <ChipLabel removeButton={Boolean(onRemove)} title={label} invertEllipsis={invertEllipsis}>
          {label}
        </ChipLabel>
      )}
      {onRemove && variant === 'default' && (
        <Icon
          tabIndex={0}
          ariaLabel={`Remove ${label}`}
          pointer
          size={12}
          name={'remove'}
          reverseInvert
          onClick={(e) => (onRemove ? onRemove(id, e) : undefined)}
          onKeyDown={onRemoveKeyDown}
          overrideColor={'text'}
        />
      )}
      {onRemove && variant === 'blue' && (
        <RemoveSVGWrapper>
          <Icon
            tabIndex={0}
            ariaLabel={`Remove ${label}`}
            pointer
            size={12}
            name={'remove'}
            reverseInvert={false}
            onClick={(e) => (onRemove ? onRemove(id, e) : undefined)}
            onKeyDown={onRemoveKeyDown}
            overrideColor={'text'}
          />
        </RemoveSVGWrapper>
      )}
    </ChipWrapper>
  );
};
