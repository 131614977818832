import { useQuery } from '@tanstack/react-query';

import { Org } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { orgQueryKeys } from '../orgQueryKeys';

interface UseGetSpecificOrgQueryProps {
  orgId: string | undefined;
}

const useGetSpecificOrgQuery = ({ orgId }: UseGetSpecificOrgQueryProps) => {
  const { data, ...queryResult } = useQuery({
    queryKey: orgQueryKeys.org(orgId),
    queryFn: () => makeRequest<Org>('GET', `/orgs/${orgId}`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useGetSpecificOrgQuery;
