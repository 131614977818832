import { rem } from 'polished';
import { KeyboardEvent, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

import { KeyBindings } from '@src/types/key-bindings';

import CheckBoxSVG from '../../assets/svg/check-box.svg?react';
import InfoPopup from '../InfoPopup/InfoPopup';
import { INPUT_LABEL_MARGIN } from '../Input/Input';

const CHECKBOX_SIZE = rem(16);

export interface CheckboxProps {
  /** The name of the checkbox */
  name: string;
  /** The label for the checkbox */
  label?: string;
  /** The default checked state */
  defaultChecked?: boolean;
  /** If the checkbox is readonly */
  readonly?: boolean;
  /** if the checkbox must be checked to be valid */
  requiredTrue?: boolean;
  handleChange?: (event: MouseEvent) => void;
  className?: string;
  /** Add padding to the top to align with sibling inputs */
  addInputMargin?: boolean;
  description?: string;
}

export const CheckboxContainer = styled.div<{ addInputMargin?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${rem(14)};
  position: relative;

  ${({ addInputMargin }) =>
    addInputMargin &&
    css`
      margin-top: ${INPUT_LABEL_MARGIN};
    `}
`;

export const CheckboxInput = styled.input<{ readonly: boolean; toggleOnLabelClick: boolean }>`
  /* Makes the webkit checkbox invisible but keeps the behaviour */
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  cursor: pointer;

  border: 1px solid transparent;

  &:hover + .checkbox {
    border-color: ${({ theme }) => theme.color.mainBrighter};
  }

  ${({ readonly }) =>
    readonly &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${({ toggleOnLabelClick }) => {
    return toggleOnLabelClick
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: ${CHECKBOX_SIZE};
          height: ${CHECKBOX_SIZE};
        `;
  }}
`;

const CheckboxElement = styled.div<{ checked: boolean; disabled: boolean; invalid: boolean }>`
  display: flex;
  height: ${CHECKBOX_SIZE};
  width: ${CHECKBOX_SIZE};
  background-color: ${({ theme, checked }) => (checked ? theme.color.main : theme.color.baseLayer)};
  border-radius: ${rem(2)};
  margin-right: ${rem(5)};
  color: ${({ theme }) => theme.color.textTranslucent};
  padding: ${rem(2)} ${rem(1)};

  ${({ invalid, theme }) =>
    invalid
      ? css`
          border: ${rem(1)} solid ${theme.color.alert};
        `
      : css`
          border: ${rem(1)} solid ${theme.color.baseOutline};
        `}

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          background-color: ${({ theme }) => theme.color.baseOutline};
          opacity: 0.48;
        `
      : css`
          box-shadow:
            inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 1px 0 rgba(255, 255, 255, 0.05);
        `}
`;

const CheckBoxIcon = styled(CheckBoxSVG)<{ checked: boolean }>`
  color: ${({ theme }) => theme.color.white};
`;

const CheckboxLabel = styled.label<{ disabled: boolean }>`
  line-height: 1;
  color: ${({ theme }) => theme.color.text};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `};
`;

/**
 * Returns a checkbox component
 */
export const Checkbox = ({
  name,
  label,
  defaultChecked = false,
  readonly = false,
  requiredTrue = false,
  handleChange,
  className,
  addInputMargin,
  description,
}: CheckboxProps) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const checked = watch(name, defaultChecked);

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.keyCode === KeyBindings.ENTER) {
      setValue(name, !checked);
    }
  };

  return (
    <CheckboxContainer addInputMargin={addInputMargin} className={className}>
      <CheckboxInput
        {...register(name, { required: requiredTrue })}
        toggleOnLabelClick={typeof label === 'string'}
        type={'checkbox'}
        readonly={readonly}
        disabled={readonly}
        onClick={handleChange}
        defaultChecked={defaultChecked}
        tabIndex={-1}
        id={name}
      />
      <CheckboxElement
        className={'checkbox'}
        disabled={readonly}
        checked={checked}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        invalid={Boolean(name && errors[name])}>
        {checked && <CheckBoxIcon checked={checked} />}
      </CheckboxElement>
      <CheckboxLabel htmlFor={name} disabled={readonly}>
        {label}
      </CheckboxLabel>
      {description && <InfoPopup size={16} position={'top'} text={description} />}
    </CheckboxContainer>
  );
};
