import { Checkbox, CopyText, Icon, WalCard } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

interface StoreTokenFormProps {
  /* state handler for enabling and disabling the "Done" button */
  enableDoneButtonState: [boolean, Dispatch<SetStateAction<boolean>>];
  /* token to be displayed and copied */
  createdToken: string | undefined;
}

const SubTitle = styled.div`
  display: flex;
  margin-bottom: ${units.margin.md};
`;

const ConfirmationText = styled.div`
  display: flex;
  margin-top: ${units.margin.lg};
  margin-bottom: ${units.margin.lg};
`;

const StyledCopyText = styled(CopyText)`
  color: ${({ theme }) => theme.color.textTranslucent};
  .copy-text-container {
    width: 100%;
  }
`;

const StoreTokenForm = ({ enableDoneButtonState, createdToken }: StoreTokenFormProps) => {
  // i18n
  const { t } = useTranslation();
  const apiTokensTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.API_TOKENS;
  const [enableDoneButton, setEnableDoneButton] = enableDoneButtonState;

  const handleConfirmation = () => {
    setEnableDoneButton(!enableDoneButton);
  };

  return (
    <>
      <SubTitle>
        <Icon name={'warning'} overrideColor={'yellow'} marginRight={'md'} />
        {apiTokensTranslations.COPY_API_TOKEN_TEXT}
      </SubTitle>
      <WalCard cardStyle={'transparent'}>
        <StyledCopyText text={createdToken || ''} showText />
      </WalCard>
      <ConfirmationText>
        <Checkbox name={'copy'} handleChange={handleConfirmation} />
        {apiTokensTranslations.I_HAVE_COPIED_TEXT}
      </ConfirmationText>
    </>
  );
};

export default StoreTokenForm;
