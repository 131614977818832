import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import makeRequest from '@src/utilities/make-request';

import { apiTokensQueryKeys } from '../apiTokensQueryKeys';

interface ApiTokensDeleteMutationVariables {
  tokenId: string;
}

/* Mutation to delete api tokens related to a particular service user */
const useApiTokensDeleteMutations = (userId?: string) => {
  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError,
    ApiTokensDeleteMutationVariables
  >({
    mutationFn: ({ tokenId }) => makeRequest('DELETE', `/users/${userId}/tokens/${tokenId}`),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: apiTokensQueryKeys.listApiTokensByUserId(userId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useApiTokensDeleteMutations;
