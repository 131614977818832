import { merge } from 'lodash';
import { createGlobalStyle, css } from 'styled-components';
import styled from 'styled-components/macro';

import AkkuratFont from '@src/assets/fonts/AkkuratLLTT-Regular.ttf';
import LazzerFont from '@src/assets/fonts/Lazzer-SemiBold.ttf';

import { FlexClassnames, flexStylesMap } from './flex-styles';
import { MiscClassnames, miscStylesMap } from './misc-styles';
import { normalized } from './normalize';
import { SpacingClassname, spacingStylesMap } from './spacing-styles';
import { FontClassnames, fontStylesMap } from './text-styles';
import { getStyle } from './utils';
import { units } from './variables';

const colorHelpers = css`
  .txt-translucent {
    color: ${({ theme }) => theme.color.textTranslucent};
  }
`;

const borderHelpers = css`
  .flat-side-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .flat-side-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .flat-side-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .flat-side-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const fontFamilyHelpers = css`
  .font-family-monospace {
    font-family: monospace;
  }
  .source-code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

export const invertIcon = css`
  ${({ theme }) =>
    !theme.dark &&
    css`
      filter: invert(0.75);
    `}
`;

export const invertIconReverse = css`
  ${({ theme }) =>
    theme.dark &&
    css`
      filter: invert(0.75);
    `}
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Akkurat';
    src: url(${AkkuratFont}) format("truetype");
  }

  @font-face {
    font-family: 'Lazzer Semibold';
    src: url(${LazzerFont}) format("truetype");
  }

  * {
    box-sizing: border-box;
  }
  
  .invert {
    ${invertIcon}
  }

  .invert-reverse {
    ${invertIconReverse}
  }
  body {
    color: ${({ theme }) => theme.color.text};
    background-color: ${({ theme }) => theme.color.base};
  }
  body, button {
    margin: 0;
    font-family: 'Akkurat', sans-serif;
    letter-spacing: 0.15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  p {
    margin: 0;
  }

  .monaco-editor .mtk22 {
    background-color: ${({ theme }) => theme.color.placeholderBackground};
  }

  .title-link {
    font-size: 1rem;
    margin-right: ${units.margin.sm};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }


  ${normalized};
  ${colorHelpers};
  ${borderHelpers};
  ${fontFamilyHelpers};
  ${getStyle(fontStylesMap)};
  ${getStyle(flexStylesMap)}
  ${getStyle(spacingStylesMap)};
  ${getStyle(miscStylesMap)}

`;
// end of GlobalStyles

type Classnames =
  | SpacingClassname
  | FlexClassnames
  | FontClassnames
  | MiscClassnames
  | 'txt-translucent';
export const cl = (...classNames: Classnames[]) => ({ className: classNames.join(' ') });
const combinedStylesMap = merge(spacingStylesMap, flexStylesMap, fontStylesMap, miscStylesMap);
export const st = (...classNames: Classnames[]) =>
  classNames.map((cls) => combinedStylesMap[cls]).join('\n');

/** ============ Custom styled components ========== */

export const CodePlaceholder = styled.span`
  padding: 0 ${units.padding.xs};
  margin: 0;
  font-family: 'Source Code Pro', sans-serif;
  white-space: normal;
  height: 1rem;
  color: ${({ theme }) => theme.color.mainBrighter};
`;
export const placeholderStyle = (hasError?: boolean) => css`
  padding: ${hasError ? '0' : units.padding.xs};
  margin: 0 ${hasError ? '0' : units.margin.xs};
  font-family: 'Source Code Pro', sans-serif;
  white-space: normal;
  height: 1rem;
  color: ${({ theme }) => (hasError ? theme.color.alert : theme.color.placeholderText)};
  background-color: ${({ theme }) =>
    hasError ? 'transparent' : theme.color.placeholderBackground};
`;
