export const deploymentSetQueryKeys = {
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'sets']
  all: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'sets',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'sets', 'detail', 'my-set']
  detail: (orgId: string | undefined, appId: string | undefined, setId: string | undefined) => [
    ...deploymentSetQueryKeys.all(orgId, appId),
    'detail',
    setId,
  ],
};
