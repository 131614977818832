import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { applicationsQueryKeys } from '../applicationsQueryKeys';

const useApplicationDeleteMutation = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ appId }: { appId: string | undefined }) =>
      makeRequest('DELETE', `/orgs/${orgId}/apps/${appId}`),
    onSuccess: (_, { appId }) => {
      queryClient.removeQueries({
        queryKey: applicationsQueryKeys.detail(orgId, appId),
      });
      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.list(orgId),
      });
    },
  });
};

export default useApplicationDeleteMutation;
