import { DynamicFormSchema, RowSchema, SectionSchema } from '@src/types/dynamic-form';

import { FieldProps, RowProps, SectionProps } from './dynamic-form-types';

export const isRowSchema = (value?: RowSchema | SectionSchema): value is RowSchema => {
  return value ? Boolean((value as RowSchema).type === 'row') : false;
};

export const isSectionSchema = (value?: RowSchema | SectionSchema): value is SectionSchema => {
  return value ? Boolean((value as SectionSchema).title) : false;
};

export const isSection = (value?: FieldProps | SectionProps | RowProps): value is SectionProps => {
  return value ? Boolean((value as SectionProps).sectionName) : false;
};

export const isField = (value?: FieldProps | SectionProps | RowProps): value is FieldProps => {
  return value ? Boolean((value as FieldProps).path) : false;
};

export const isRow = (value?: FieldProps | SectionProps | RowProps): value is RowProps => {
  return value ? Boolean((value as RowProps).rowName) : false;
};

export const isJSONSchemaField = (schema?: DynamicFormSchema): schema is DynamicFormSchema =>
  schema ? Boolean(schema?.uiHints?.inputType === 'textarea' && schema?.type === 'object') : false;
