/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-unescaped-entities */
import { Tooltip } from '@humanitec/ui-components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { windowEnv } from '@src/environment';
import { MatchParams } from '@src/models/routing';
import { CodePlaceholder } from '@src/styles/global-styles';

import { CodeLine, DescriptionLink } from './styles';

const CurlSnippet = () => {
  const { t } = useTranslation();
  const manageImagesTranslations = t('ACCOUNT_SETTINGS').MANAGE_IMAGES;
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return (
    <React.Fragment>
      <CodeLine>curl \</CodeLine>
      <CodeLine>
        --request POST "{windowEnv.BASE_URL}/orgs/{orgId}/artefact-versions" \
      </CodeLine>
      <CodeLine>
        --header "Authorization: Bearer{' '}
        <Tooltip
          width={200}
          text={
            <Trans i18nKey={'ACCOUNT_SETTINGS.MANAGE_IMAGES.HUMANITEC_TOKEN_DESCRIPTION'}>
              <DescriptionLink
                target={'_blank'}
                rel={'noopener noreferrer'}
                href={'https://api-docs.humanitec.com/#section/Introduction/Authentication'}
              />
            </Trans>
          }
          position={'left'}
          triggerComponent={<CodePlaceholder>{'${HUMANITEC_TOKEN}'}</CodePlaceholder>}
        />
        " \
      </CodeLine>
      <CodeLine>--header "Content-Type: application/json" \</CodeLine>
      <CodeLine>{"--data-raw '{ "}</CodeLine>
      <CodeLine>
        {/* prettier-ignore */}
        <span>    "name": </span>"'
        <Tooltip
          width={250}
          text={manageImagesTranslations.CI_NAME_DESCRIPTION}
          triggerComponent={<CodePlaceholder>{'${IMAGE_NAME}'}</CodePlaceholder>}
        />
        '",
      </CodeLine>
      <CodeLine>
        {/* prettier-ignore */}
        <span>    "version": </span>"'
        <Tooltip
          width={250}
          text={manageImagesTranslations.CI_VERSION_DESCRIPTION}
          triggerComponent={<CodePlaceholder>{'${IMAGE_TAG}'}</CodePlaceholder>}
        />
        '",
      </CodeLine>
      <CodeLine>
        {/* prettier-ignore */}
        <span>    "type": </span>"container",
      </CodeLine>
      <CodeLine>
        {/* prettier-ignore */}
        <span>    "commit": </span>"'
        <Tooltip
          width={250}
          text={manageImagesTranslations.CI_COMMIT_SHA_DESCRIPTION}
          triggerComponent={<CodePlaceholder>{'${COMMIT_SHA}'}</CodePlaceholder>}
        />
        '",
      </CodeLine>
      <CodeLine>
        {/* prettier-ignore */}
        <span>    "ref": </span>"'
        <Tooltip
          width={250}
          text={manageImagesTranslations.CI_REF_DESCRIPTION}
          triggerComponent={<CodePlaceholder>{'${GIT_REF}'}</CodePlaceholder>}
        />
        '"
      </CodeLine>
      <CodeLine>{"}'"}</CodeLine>
    </React.Fragment>
  );
};

export default CurlSnippet;
