import { chain } from 'lodash';

import {
  AppValueVersionDeleted,
  AppValueVersionItem,
  AppValueVersionPurged,
  AppValueVersionUnpurged,
} from '@src/hooks/react-query/shared-values/sharedValueVersionsTypes';
/**
 * Extract the version for the value specific for this key and change Id
 */
export const getSelectedVersion = (
  sharedAppValuesHistory: Record<string, AppValueVersionItem[]> | undefined,
  keyValue: string,
  selectedChangeId: string | undefined
): AppValueVersionUnpurged | AppValueVersionPurged | AppValueVersionDeleted | undefined =>
  chain(sharedAppValuesHistory) // use lodash chain to perform actions step by step
    .get(keyValue) // get value of an object for key 'keyValue'
    .filter(({ changeId }) => changeId === selectedChangeId) // filter to get the value based on change id
    .head() // get first element , undefined if array is empty
    .value(); // stop the chain, get the value out
