import { DropdownItem, MultiSelectGroup } from '@humanitec/ui-components';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SectionHeader from '@src/components/shared/SectionHeader';
import useEventsQuery from '@src/hooks/react-query/events/useEventsQuery';
import useWebhooksListQuery from '@src/hooks/react-query/webhooks/queries/useWebhooksListQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { useWebhooksStore } from '@src/hooks/zustand/useWebhooksStore';
import { EventTrigger } from '@src/models/webhook';
import { units } from '@src/styles/variables';
import { capitalize } from '@src/utilities/string-utility';

import { NewWebhookForm } from './components/components/NewWebhookForm/NewWebhookForm';
import { WebhookGrid, WebhooksWrapperGrid } from './components/components/styles';
import WebhookComponent from './components/Webhook';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

// Add padding to make headers line up with card
const CardGridWrapper = styled(WebhookGrid)`
  padding: 0 ${units.padding.md};
`;

const WebhookWithMargin = styled(WebhookComponent)`
  margin-bottom: ${units.margin.md};
`;

const SubHeading = styled.span`
  margin-bottom: ${units.margin.md};
  font-size: ${units.fontSize.sm};
`;

export const generateTriggerOptions = (events: EventTrigger[]) => {
  const triggerScopes = events.map((event) => event.scope) || [];
  const uniqueScopes = uniq(triggerScopes);

  return [
    ...uniqueScopes.map((scope) => ({
      title: capitalize(scope),
      key: scope,
      items: events
        .filter((event) => event.scope === scope)
        .map((event) => ({
          label: capitalize(event.type),
          value: event.type,
          id: event.type,
        })),
    })),
  ];
};

const Webhooks = () => {
  // i18n
  const { t } = useTranslation();
  const webhooksTranslations = t('APP_SETTINGS').WEBHOOKS;

  // Context
  const canUpdateWebhook = useRBAC('updateWebhook');

  // React Query
  const { data: webhooks } = useWebhooksListQuery();
  const { data: events } = useEventsQuery();

  // Zustand
  const { lastCreatedWebhookIds } = useWebhooksStore();

  const triggerOptions: (MultiSelectGroup | DropdownItem<string>)[] = useMemo(() => {
    if (events) {
      return generateTriggerOptions(events);
    }
    return [];
  }, [events]);

  return (
    <Container>
      <SubHeading>{webhooksTranslations.MANAGE_WEBHOOKS_DESCRIPTION}</SubHeading>
      {canUpdateWebhook && events && (
        <NewWebhookForm
          triggerOptions={triggerOptions}
          triggers={events}
          existingIds={webhooks ? webhooks?.map((webhook) => webhook.id) : []}
        />
      )}
      {webhooks && webhooks.length > 0 && (
        <SectionHeader backgroundColor={'transparent'}>
          <WebhooksWrapperGrid>
            <div>{webhooksTranslations.ACTIVE}</div>
            <CardGridWrapper>
              <div>{webhooksTranslations.WEBHOOK_NAME}</div>
              <div>{webhooksTranslations.URL}</div>
              <div>{webhooksTranslations.HEADERS}</div>
              <div>{webhooksTranslations.TRIGGERS}</div>
              <div />
            </CardGridWrapper>
          </WebhooksWrapperGrid>
        </SectionHeader>
      )}
      {webhooks
        ?.sort((w) => (lastCreatedWebhookIds?.includes(w.id) ? -1 : 0))
        ?.map((webhook) => (
          <WebhookWithMargin key={webhook.id} triggerOptions={triggerOptions} webhook={webhook} />
        ))}
    </Container>
  );
};

export default Webhooks;
