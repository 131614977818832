export const applicationsQueryKeys = {
  // e.g. ['orgs', 'my-org', 'apps']
  all: (orgId: string | undefined) => ['orgs', orgId, 'apps'],
  // e.g. ['orgs', 'my-org', 'apps', 'list']
  list: (orgId: string | undefined) => [...applicationsQueryKeys.all(orgId), 'list'],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'detail']
  detail: (orgId: string | undefined, appId: string | undefined) => [
    ...applicationsQueryKeys.all(orgId),
    appId,
    'detail',
  ],
};
