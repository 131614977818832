import { WalInput } from '@humanitec/ui-components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { convertToId } from '@src/utilities/string-utility';

interface ContainerNameStepProps {
  currentContainerIds: string[];
  selectedImage?: string;
}

const ContainerNameStep = ({ currentContainerIds, selectedImage }: ContainerNameStepProps) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const { setValue } = useFormContext();

  useEffect(() => {
    if (selectedImage) {
      const imageNameSegments = selectedImage.split('/');
      if (imageNameSegments) {
        const imageName = imageNameSegments[imageNameSegments?.length - 1].split(':')[0];
        setValue('containerName', convertToId(imageName));
      }
    }
  }, [selectedImage, setValue]);

  return (
    <>
      <form>
        <WalInput
          name={'containerName'}
          required
          maxLength={63}
          standardValidation={[
            { type: 'idStartWithAlphabetic' },
            { type: 'existingId', ids: currentContainerIds },
          ]}
          label={imageTranslations.CONTAINER_NAME}
        />
      </form>
    </>
  );
};

export default ContainerNameStep;
