const appValuesQueryKeys = {
  all: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'values',
  ],
  list: (orgId: string | undefined, appId: string | undefined) => [
    ...appValuesQueryKeys.all(orgId, appId),
    'list',
  ],
};

export default appValuesQueryKeys;
