import { WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface InfoModalWithDismissProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  title: string;
  confirmMessage: string;
  navigateFunction?: () => void;
}

const InfoModalWithDismiss = ({
  state,
  title,
  confirmMessage,
  navigateFunction,
}: InfoModalWithDismissProps) => {
  const [open, setOpen] = state;

  const navigate = () => {
    setOpen(false);
    if (navigateFunction) {
      navigateFunction();
    }
  };

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <WalModal
      title={title}
      content={confirmMessage}
      actions={{
        cancel: {
          props: {
            onClick: navigate,
          },
          text: uiTranslations.OK,
        },
      }}
      disableClickOutside
      openState={[open, setOpen]}
    />
  );
};

export default InfoModalWithDismiss;
