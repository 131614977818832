import { Icon, ObjectTypeProps, ViewEdit } from '@humanitec/ui-components';
import { rem } from 'polished';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import MembersRoleDropdown from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/AppMembers/components/MemberRolesDropdown';
import { AppRoles, Role } from '@src/models/role';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { capitalize } from '@src/utilities/string-utility';

const Wrapper = styled.div`
  margin-bottom: ${units.margin.sm};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${rem(260)} ${rem(270)} ${rem(170)} 1fr;
  width: 100%;
  align-items: center;
`;

interface AppMembersViewEditProps {
  member: Role<AppRoles>;
  onConfirm: (member: Role<AppRoles>, role: AppRoles) => void;
  onDelete: (id: string) => void;
  editable?: boolean;
}
const AppMembersViewEdit = ({ member, onConfirm, onDelete, editable }: AppMembersViewEditProps) => {
  const formMethods = useWalhallForm();

  // i18n
  const { t } = useTranslation();
  const translations = t('APP_SETTINGS').APP_MEMBERS;

  const handleDelete = (): void => {
    onDelete(member.id);
  };

  const handleConfirm = (formValues: { role: AppRoles }): void => {
    onConfirm(member, formValues.role);
  };

  const renderIcon = () => <Icon name={member.type === 'service' ? 'robot' : 'person'} />;
  return (
    <Wrapper>
      <FormProvider {...formMethods}>
        <ViewEdit
          readonly={!editable}
          hasDeleteButton
          editModeContent={
            <ContentWrapper>
              <span>{member.name}</span>
              <span>{member.email}</span>
              <MembersRoleDropdown defaultValue={member.role} />
              <span>{renderIcon()}</span>
            </ContentWrapper>
          }
          viewModeContent={
            <ContentWrapper>
              <span>{member.name}</span>
              <span>{member.email}</span>
              <span>{capitalize(member.role)}</span>
              <span>{renderIcon()}</span>
            </ContentWrapper>
          }
          onConfirm={handleConfirm}
          onDelete={handleDelete}
          sectionName={translations.APP_MEMBER as ObjectTypeProps}
        />
      </FormProvider>
    </Wrapper>
  );
};

export default AppMembersViewEdit;
