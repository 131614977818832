import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import makeRequest from '@src/utilities/make-request';

import { apiTokensQueryKeys } from '../apiTokensQueryKeys';

interface ApiTokensByUserCreateMutationVariables {
  description: string;
  expires_at?: Date;
  id: string;
  type: string;
}

interface CreateTokenErrorRensponse {
  error: string;
  message: string;
}

/* Mutation to create api tokens related to a particular service user  */
const useApiTokensByUserCreateMutations = (
  userId: string | undefined,
  successCallback?: (token: string) => void,
  errorCallback?: (errorMessage?: string) => void
) => {
  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError<CreateTokenErrorRensponse>,
    ApiTokensByUserCreateMutationVariables
  >({
    mutationFn: (payload) => makeRequest('POST', `/users/${userId}/tokens`, payload),
    onSuccess: (response) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: apiTokensQueryKeys.listApiTokensByUserId(userId),
      });
      if (successCallback) {
        successCallback(response?.data?.token);
      }
    },
    onError: (error) => {
      const errorResponse = error?.response?.data;
      errorCallback?.(errorResponse?.message || '');
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useApiTokensByUserCreateMutations;
