import { WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { Workload } from '@src/models/deployment-set';
import { useWalhallForm } from '@src/utilities/form';

import WorkloadNameInput from '../WorkloadNameInput';

interface DuplicateWorkloadModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  workload: Workload;
}

const DuplicateWorkloadModal = ({ openState, workload }: DuplicateWorkloadModalProps) => {
  // Component state
  const [, setOpen] = openState;

  // i18n
  const { t } = useTranslation();
  const { t: tViewDeployment } = useTranslation('viewDeployment');
  const uiTranslations = t('UI');
  const workloadTranslations = tViewDeployment('WORKLOADS');

  const { addWorkload } = useDeltaUtils();

  // Form
  const formMethods = useWalhallForm();

  return (
    <FormProvider {...formMethods}>
      <WalModal
        openState={openState}
        title={workloadTranslations.DUPLICATE_WORKLOAD}
        content={<WorkloadNameInput />}
        handleFormSubmit={({ workloadName }: { workloadName: string }) => {
          addWorkload(workloadName, workload);
          setOpen(false);
        }}
        actions={{
          main: {
            props: {
              type: 'submit',
            },
            text: uiTranslations.DUPLICATE,
          },
          cancel: {},
        }}
      />
    </FormProvider>
  );
};

export default DuplicateWorkloadModal;
