import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Freetrial from '@src/components/shared/Freetrial';
import useAuthenticateUserMutation from '@src/hooks/react-query/user/useAuthenticateUserMutation';
import { useCurrentUserStore } from '@src/hooks/zustand/useCurrentUserStore';
import { cl } from '@src/styles/global-styles';

import AuthButtons from '../../components/AuthButtons/AuthButtons';

const Wrapper = styled.div`
  max-width: ${rem(300)};
`;

const Login = () => {
  const { t } = useTranslation();

  const authTranslations = t('AUTHENTICATE');

  const { authErrorMessage: authenticateFailedReason } = useCurrentUserStore();

  const { mutate: authenticateGoogleToken } = useAuthenticateUserMutation({
    provider: 'google',
    authenticationType: 'login',
  });

  const googleAuthSuccess = (googleToken: string): void => {
    authenticateGoogleToken(googleToken);
  };

  return (
    <Wrapper>
      <h1 {...cl('mb-xl', 'txt-center')}>{authTranslations.LOG_IN}</h1>
      <>
        <AuthButtons
          isInvite={false}
          authenticateFailedReason={authenticateFailedReason}
          type={'login'}
          googleAuthSuccess={googleAuthSuccess}
        />
        <div {...cl('txt-center')}>
          <span className={'txt-sm'}>{authTranslations.DONT_HAVE_AN_ACCOUNT}</span> <Freetrial />
        </div>
      </>
    </Wrapper>
  );
};

export default Login;
