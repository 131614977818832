import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { Invite } from '@src/models/user';
import { removeInviteToken } from '@src/utilities/local-storage';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';

import { orgQueryKeys } from '../organisations/orgQueryKeys';
import { userQueryKeys } from './userQueryKeys';

export interface AcceptInvitePayload {
  provider: string;
  token: string;
  privacy_policy_accepted?: boolean;
}

const useAcceptInviteMutation = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      invite: Invite;
      payload?: AcceptInvitePayload;
    }
  >({
    mutationFn: ({ invite, payload }) => {
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser() });
      queryClient.cancelQueries({ queryKey: orgQueryKeys.list() });
      return makeRequest('POST', `/invitations/${invite.token}`, payload);
    },
    onSuccess: (_, { invite }) => {
      // Remove token from local storage if it exists.
      removeInviteToken();
      // Redirect to newly joined org
      navigate(generateAppURL(invite.org.id));
      queryClient.invalidateQueries({ queryKey: userQueryKeys.currentUser() });
      queryClient.invalidateQueries({ queryKey: orgQueryKeys.list() });
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });
};

export default useAcceptInviteMutation;
