import { IconNames } from '@humanitec/ui-components';

import { RegistryType } from '@src/models/registry';

export const getRegistryTitle = (type: RegistryType): string => {
  switch (type) {
    case 'basic':
      return 'Basic container registry';
    case 'public':
      return 'Public Registry';
    case 'secret_ref':
      return 'Secret Registry';
    case 'amazon_ecr':
      return 'Amazon elastic container registry';
    case 'google_gcr':
      return 'Google container registry';
    case 'microsoft_acr':
      return 'Azure container registry';
    // no default
  }
};

export const getRegistryIcon = (type: RegistryType): IconNames => {
  switch (type) {
    case 'basic':
      return 'registry';
    case 'public':
      return 'registry';
    case 'secret_ref':
      return 'registry';
    case 'amazon_ecr':
      return 'aws';
    case 'google_gcr':
      return 'gcp';
    case 'microsoft_acr':
      return 'azure';
    // no default
  }
};
