import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { PipelineApprovalRequest } from '@src/models/pipeline';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from '../queries/pipelineRunsQueryKeys';

interface UseDenyRequestMutationProps {
  appId: string;
  pipelineId: string;
  runId: string;
  onError?: (err: AxiosError, variables: any) => void;
  filters?: { run?: string };
}

const useDenyRequestMutation = ({
  appId,
  pipelineId,
  runId,
  onError,
  filters,
}: UseDenyRequestMutationProps) => {
  const { orgId } = useParams();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      jobId: string;
      approvalId: string;
    }
  >({
    mutationFn: ({ jobId, approvalId }) => {
      return makeRequest<PipelineApprovalRequest>(
        'POST',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${runId}/jobs/${jobId}/approvals/${approvalId}/deny`,
        {}
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: pipelineRunsQueryKeys.pipelineRunApprovals(orgId, appId, filters),
      });
    },
    onError: (error, variables) => {
      onError?.(error, variables);
      datadogRum.addError(error);
    },
  });
};

export default useDenyRequestMutation;
