import { To } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NonStyledLink } from '@src/components/shared/NonStyledLink';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

import { MenuItem, WalMenu } from '../../molecules/Menu/Menu';
import Icon from '../Icon/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.span<{ $marginRight: boolean }>`
  ${ellipsisStyle()};
  ${({ $marginRight }) =>
    $marginRight &&
    css`
      margin-right: ${units.margin.md};
    `}
  font-size: ${units.fontSize.sm};
`;

const Link = styled(NonStyledLink)<{ $marginRight: boolean }>`
  ${ellipsisStyle()};
  &:hover {
    color: ${({ theme }) => theme.color.main};
    text-decoration: underline;
  }
  ${({ $marginRight }) =>
    $marginRight &&
    css`
      margin-right: ${units.margin.md};
    `}
`;

interface LinkDropdownProps {
  /**
   * Text to display (on the link).
   */
  label: string;
  /**
   * URL to navigate to.
   */
  to?: To;
  /**
   * Items that will be displayed in the dropdown.
   */
  items?: MenuItem<string>[];
  /**
   * aria-label for the menu toggle.
   */
  menuAriaLabel?: string;
  /**
   * white-space: nowrap will be enabled unless this prop is passed
   */
  disableNoWrap?: boolean;
}

const LinkDropdown = ({ to, label, items, menuAriaLabel, disableNoWrap }: LinkDropdownProps) => (
  <Wrapper>
    {to ? (
      <Link $marginRight={Boolean(items?.length)} to={to}>
        {label}
      </Link>
    ) : (
      <Name $marginRight={Boolean(items?.length)}>{label}</Name>
    )}
    {items && items.length > 1 && (
      <WalMenu
        ariaLabel={menuAriaLabel}
        toggle={<Icon name={'arrow-down'} size={12} />}
        items={items}
        maxSize={'large'}
        disableNoWrap={disableNoWrap}
      />
    )}
  </Wrapper>
);

export default LinkDropdown;
