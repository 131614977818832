import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ResourceConflictChange, ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionDeleteMutationVariables {
  defId: string;
  force?: boolean;
}
const useResourceDefinitionDeleteMutation = (errorCallback?: (error: AxiosError) => void) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceDefinition>,
    AxiosError<ResourceConflictChange[]>,
    ResourceDefinitionDeleteMutationVariables
  >({
    mutationFn: ({ defId, force }) =>
      makeRequest('DELETE', `/orgs/${orgId}/resources/defs/${defId}?force=${force || false}`),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listDefinitions(orgId) });
    },
    onError: errorCallback,
  });
  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionDeleteMutation;
