import { Icon, WalInput, WalTable } from '@humanitec/ui-components';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import usePurgeVersionHistoryMutation from '@src/hooks/react-query/shared-values/mutations/usePurgeVersionHistoryMutation';
import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import {
  AppValueVersionDeleted,
  AppValueVersionPurged,
  AppValueVersionUnpurged,
} from '@src/hooks/react-query/shared-values/sharedValueVersionsTypes';
import { units } from '@src/styles/variables';

import SharedAppValueHistoryForm from './SharedAppValueHistoryForm';

const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${units.margin.lg} 0;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${units.margin.lg} 0;
  padding: ${units.padding.md};
  background: ${({ theme }) => theme.color.alertTransparentFlat};
`;

type SharedAppValueHistorPurgeProps = {
  selectedChangeId: string | undefined;
  keyValue: string;
  setOnSubmit: (onSubmit: (onSubmitSuccess: () => void) => void) => void;
  /** If this component is used in Environment Override version values screen */
  isEnvironmentOverride: boolean;
  purgeHistoryItem: AppValueVersionUnpurged | AppValueVersionPurged | AppValueVersionDeleted;
};

const SharedAppValueHistoryPurge = ({
  selectedChangeId,
  keyValue,
  setOnSubmit,
  isEnvironmentOverride,
  purgeHistoryItem,
}: SharedAppValueHistorPurgeProps) => {
  const [purgeError, setPurgeError] = useState<string | undefined>(undefined);

  // data fetching
  const { data: sharedAppValuesHistory } = useSharedAppValuesHistoryQuery(isEnvironmentOverride);

  const { register, watch, resetField, setValue } = useFormContext();

  // reset comment value, in case moving from purge to revert modal. This also helps to solve the bug of loosing focus after entering first character
  useEffect(() => {
    setValue('comment', '');
  }, [setValue]);

  const { mutate: purgeVersion } = usePurgeVersionHistoryMutation(
    keyValue,
    () => {
      resetField('comment');
      resetField('purgeChangeId');
      // onPurgeSubmit();
    },
    (err: AxiosError<{ message: string }>) => {
      setPurgeError(err?.response?.data.message || '');
    }
  );

  const subscription = watch((formData) => {
    setOnSubmit((onSubmitSuccess: () => void) => {
      purgeVersion(
        {
          comment: formData.comment !== '' ? formData.comment : undefined,
          purgeChangeId: formData.purgeChangeId,
          isEnvironmentOverride,
        },
        { onSuccess: () => onSubmitSuccess() }
      );
    });
  });

  useEffect(() => () => subscription.unsubscribe(), [subscription]);

  // translation
  const { t } = useTranslation();
  const valueHistoryTranslations = t('APP_SETTINGS').VALUE_HISTORY;

  if (!selectedChangeId) {
    // in rare cases where purgeChangeId is not passed
    return (
      <WarningWrapper>
        <Icon name={'warning'} marginRight={'md'} />
        <p>{valueHistoryTranslations.RUNTIME_ERROR_TEXT}</p>
      </WarningWrapper>
    );
  }

  const item = (sharedAppValuesHistory || {})[keyValue];

  const commonVersions = item
    ?.filter(
      (version) =>
        version.purgeChangeId === purgeHistoryItem?.purgeChangeId &&
        version.changeId !== purgeHistoryItem?.changeId
    )
    .map((version) => ({ data: version }));

  const CommonVersions = (
    <>
      <div className={'mt-md'}>{valueHistoryTranslations.SAME_VALUE_MESSAGE}</div>
      <WalTable
        className={'mt-md'}
        caption={t('APP_SETTINGS.VALUE_HISTORY.TITLE', { keyValue })}
        rows={commonVersions}
        columns={[
          {
            label: 'Edit Date',
            prop: 'editedDate',
          },
          {
            label: 'Edited By',
            prop: 'editedBy',
          },
          {
            label: 'Description',
            prop: 'description',
            justifyContent: 'flex-start',
            ellipsisTooltip: {
              maxWidth: 180,
              maxCharacters: 10,
              text: (row) => row.data.description,
            },
          },
        ]}
      />
    </>
  );

  return (
    <>
      <p>{valueHistoryTranslations.PURGE_WARNING}</p>

      <WarningWrapper>
        <Icon name={'warning'} marginRight={'md'} />
        <p>{valueHistoryTranslations.PURGE_WARNING2}</p>
      </WarningWrapper>
      {purgeError !== undefined && (
        <ErrorWrapper>
          <Icon name={'warning'} marginRight={'md'} />
          <p>{valueHistoryTranslations.PURGE_ERROR + (purgeError !== '' && ': ' + purgeError)}</p>
        </ErrorWrapper>
      )}
      <SharedAppValueHistoryForm
        historyItem={purgeHistoryItem}
        footer={commonVersions?.length > 0 ? CommonVersions : undefined}
        showBlockquote
        showAlert
      />

      <input
        type={'hidden'}
        value={purgeHistoryItem.purgeChangeId || purgeHistoryItem.changeId}
        {...register('purgeChangeId')}
      />
      <WalInput
        className={'mt-md'}
        label={valueHistoryTranslations.COMMENT}
        labelAbove
        name={'comment'}
        description={valueHistoryTranslations.COMMENT_DESCRIPTION}
      />
    </>
  );
};
export default SharedAppValueHistoryPurge;
