import { useState } from 'react';

import { WorkloadProfileVersion } from '@src/models/workload-profile';

import WYSIWYG from '../WYSIWYG/WYSIWYG';
import ViewWorkloadProfile from './ViewWorkloadProfile';

const WorkloadProfilePlayground = () => {
  const [schema, setSchema] = useState<WorkloadProfileVersion['spec_definition']>();

  return (
    <WYSIWYG
      defaultEditorContent={{
        properties: {
          annotations: {
            feature_name: 'humanitec/annotations',
            type: 'feature',
            ui_hints: {
              order: 6,
            },
            version: '0.1',
          },
          containers: {
            feature_name: 'humanitec/containers',
            title: 'Containers',
            type: 'collection',
            ui_hints: {
              order: -1,
            },
            version: '0.1',
          },
          extraContainers: {
            feature_name: 'humanitec/extra-containers',
            type: 'feature',
            ui_hints: {
              hidden: true,
            },
            version: '0.1',
          },
          extraVolumes: {
            feature_name: 'humanitec/extra-volumes',
            type: 'feature',
            ui_hints: {
              hidden: true,
            },
            version: '0.1',
          },
          imagePullSecrets: {
            feature_name: 'humanitec/image-pull-secrets',
            type: 'feature',
            ui_hints: {
              hidden: true,
            },
            version: '0.1',
          },
          ingress: {
            feature_name: 'humanitec/ingress',
            type: 'feature',
            ui_hints: {
              order: 2,
            },
            version: '0.1',
          },
          labels: {
            feature_name: 'humanitec/labels',
            type: 'feature',
            ui_hints: {
              order: 5,
            },
            version: '0.1',
          },
          service: {
            feature_name: 'humanitec/service',
            type: 'feature',
            ui_hints: {
              order: 4,
            },
            version: '0.1',
          },
          serviceAccountName: {
            feature_name: 'humanitec/service-account',
            type: 'feature',
            ui_hints: {
              order: 3,
            },
            version: '0.1',
          },
          variables: {
            feature_name: 'humanitec/extra-variables',
            type: 'feature',
            ui_hints: {
              hidden: true,
            },
            version: '0.1',
          },
          volumes: {
            feature_name: 'humanitec/volumes',
            type: 'feature',
            ui_hints: {
              hidden: true,
              order: 8,
            },
            version: '0.1',
          },
        },
        runtime_properties: [
          {
            feature_name: 'humanitec/replicas',
            type: 'feature',
            ui_hints: {
              order: -2,
            },
            version: '0.1',
          },
        ],
      }}
      onEditorContentChange={setSchema}
      renderedContent={<ViewWorkloadProfile schema={schema} />}
      examples={[]}
    />
  );
};

export default WorkloadProfilePlayground;
