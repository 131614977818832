import { create } from 'zustand';

import { ResourceAccount } from '@src/models/resource-account';

type ResourcesStore = {
  lastCreatedResourceAccountIds: string[];
  lastCreatedResourceAccount?: ResourceAccount;
  pushToLastCreatedResourceAccounts: (resourceAccount: ResourceAccount) => void;
  lastCreatedResourceDefinitions: string[];
  pushIdToLastCreatedResourceDefinitions: (id: string) => void;
};

export const useResourcesStore = create<ResourcesStore>((set) => ({
  lastCreatedResourceAccounts: [],
  lastCreatedResourceAccountIds: [],
  pushToLastCreatedResourceAccounts: (resourceAccount) => {
    set((state) => ({
      ...state,
      lastCreatedResourceAccountIds: [...state.lastCreatedResourceAccountIds, resourceAccount.id],
      lastCreatedResourceAccount: resourceAccount,
    }));
  },
  lastCreatedResourceDefinitions: [],
  pushIdToLastCreatedResourceDefinitions: (id: string) => {
    set((state) => ({
      ...state,
      lastCreatedResourceDefinitions: [...state.lastCreatedResourceDefinitions, id],
    }));
  },
}));
