import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import AppValuesLink from '@src/components/shared/AppValuesLink';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { KeyValue } from '@src/models/general';
import { MatchParams } from '@src/models/routing';

import VariablesInputs from './components/VariablesInputs/VariablesInputs';

const VariablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ConfigToggleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

interface VariablesProps {
  deltaPath: string;
}

const Variables = ({ deltaPath }: VariablesProps) => {
  const { moduleId } = useParams<keyof MatchParams>() as MatchParams;

  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { updateWorkload, data } = useDeltaUtils<Record<string, string>>(deltaPath);

  const variablesValues = Object.entries(data || {}).map(([key, value]) => ({
    key,
    value,
  }));

  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  const editValue = (oldValue: KeyValue, newValue: KeyValue) => {
    const keyChanged = oldValue.key !== newValue.key;
    const valueChanged = oldValue.value !== newValue.value;
    const changed = keyChanged || valueChanged;

    if (changed) {
      updateWorkload(
        !keyChanged && valueChanged
          ? [
              {
                key: oldValue.key,
                op: 'replace',
                value: newValue.value,
              },
            ]
          : [
              {
                key: oldValue.key,
                op: 'remove',
              },
              {
                key: newValue.key,
                op: 'add',
                value: newValue.value,
              },
            ]
      );
    }
  };

  const addValue = (value: KeyValue) => {
    updateWorkload([
      {
        key: value.key,
        op: 'add',
        value: value.value,
      },
    ]);
  };

  const deleteValue = (configValue: KeyValue) => {
    updateWorkload([
      {
        key: configValue.key,
        op: 'remove',
      },
    ]);
  };

  return (
    <VariablesWrapper>
      {variablesValues.length === 0 && !draftModeActive ? (
        <span className={'txt-md'}>{sectionsTranslations.NO_VARIABLES_DEFINED}</span>
      ) : (
        <>
          <ConfigToggleWrapper>
            <AppValuesLink />
          </ConfigToggleWrapper>
          <VariablesInputs
            key={moduleId}
            editValue={editValue}
            addValue={addValue}
            deleteValue={deleteValue}
            variableValues={variablesValues}
            inputsId={deltaPath}
          />
        </>
      )}
    </VariablesWrapper>
  );
};

export default Variables;
