import { WalModal, WalTable, WalTableRow } from '@humanitec/ui-components';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useEnvironmentTypesDeleteMutation } from '@src/hooks/react-query/environment-types/mutations/useEnvironmentTypesDeleteMutation';
import { ApplicationEnvironment } from '@src/models/application';
import { EnvironmentType } from '@src/models/environment-type';
import { units } from '@src/styles/variables';

const DeleteWarning = styled.div`
  margin: ${units.margin.sm} 0;
`;

interface DeleteEnvironmentTypeModalProps {
  envType: EnvironmentType;
  associatedEnvs?: (ApplicationEnvironment & {
    appName: string;
  })[];
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const DeleteEnvironmentTypeModal = ({
  envType,
  associatedEnvs,
  openState,
}: DeleteEnvironmentTypeModalProps) => {
  const [open, setOpen] = openState;
  // React query
  const { mutate: deleteEnvType } = useEnvironmentTypesDeleteMutation();

  const { t } = useTranslation();
  const title = t('MANAGE_ENV_TYPES.DELETE_ENV_TYPE_MODAL_TITLE', {
    replace: { name: envType.id },
  });
  const uiTranslations = t('UI');
  const translations = t('MANAGE_ENV_TYPES');

  return (
    <WalModal
      openState={[open, setOpen]}
      title={title}
      subTitle={translations.MODAL_SUBTITLE}
      size={'large'}
      content={
        <>
          <WalTable
            caption={translations.ASSOCIATED_ENVIRONMENTS_TABLE_CAPTION}
            columns={[
              {
                prop: 'name',
                label: translations.ENVIRONMENT_NAME,
              },
              {
                prop: 'appName',
                label: translations.BELONGS_TO_APP,
                justifyContent: 'flex-start',
              },
            ]}
            rows={associatedEnvs?.map((env): WalTableRow => ({ data: env })) ?? []}
            tableRowStyle={'base'}
          />
          <DeleteWarning>{translations.WILL_DELETE_ENVS}</DeleteWarning>
        </>
      }
      actions={{
        main: {
          props: {
            variant: 'danger',
            onClick: () => {
              deleteEnvType({ envTypeId: envType.id, force: true });
              setOpen(false);
            },
          },
          text: uiTranslations.DELETE,
        },
        cancel: {},
      }}
    />
  );
};

export default DeleteEnvironmentTypeModal;
