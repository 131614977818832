import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { environmentTypesQueryKeys } from '@src/hooks/react-query/environment-types/environmentTypesQueryKeys';
import { rolesQueryKeys } from '@src/hooks/react-query/roles/rolesQueryKeys';
import { userQueryKeys } from '@src/hooks/react-query/user/userQueryKeys';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

export const useEnvironmentTypesCreateMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<EnvironmentType>,
    AxiosError<ApiErrorResponse>,
    {
      envType: EnvironmentType;
    }
  >({
    mutationFn: ({ envType }) => {
      return makeRequest('POST', `/orgs/${orgId}/env-types`, envType);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: environmentTypesQueryKeys.listEnvironmentTypes(orgId),
      });
      queryClient.invalidateQueries({
        queryKey: rolesQueryKeys.envTypeRoles(orgId, res.data.id),
      });
      queryClient.invalidateQueries({
        queryKey: userQueryKeys.currentUser(),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};
