import { WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useRoleCreateMutation from '@src/hooks/react-query/roles/mutations/useRoleCreateMutation';
import { useRolesStore } from '@src/hooks/zustand/useRolesStore';
import { Role } from '@src/models/role';
import { useWalhallForm } from '@src/utilities/form';

import ServiceUsersAddEditForm from '../ServiceUsersAddForm';

interface AddNewServiceUserModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

// Custom styles to make sure the hover color in the role field dropdown highlights the total length of text when there is an overflow
const StyledModal = styled(WalModal)`
  ul {
    min-width: fit-content;
  }
`;

const AddNewServiceUserModal = ({ openState }: AddNewServiceUserModalProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const serviceUsersTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS;
  const [open, setOpen] = openState;

  // Form
  const formMethods = useWalhallForm();
  const { reset, watch } = formMethods;
  const { name } = watch();
  // Zustand
  const { pushIdToLastCreatedServiceUsersIds } = useRolesStore();

  // Mutations
  const { mutate: createNewServiceUser, isPending } = useRoleCreateMutation(undefined, (data) => {
    pushIdToLastCreatedServiceUsersIds(data?.id);
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit = (formValues: Pick<Role, 'email' | 'role'>) => {
    formValues.email = '@service-user.humanitec.io'; // this is the default email value for service users (since all users are stored with emails). You can also omit the email field and whatever is the default value on the backend would be used instead.
    createNewServiceUser(formValues);
    setOpen(false);
  };

  return (
    <FormProvider {...formMethods}>
      <StyledModal
        size={'medium'}
        openState={[open, setOpen]}
        title={serviceUsersTranslations.ADD_SERVICE_USER}
        disableOverflow
        content={<ServiceUsersAddEditForm />}
        handleFormSubmit={onSubmit}
        actions={{
          main: {
            text: serviceUsersTranslations.CREATE_NEW_SERVICE_USER,
            props: {
              disabled: Boolean(name?.trim()) === false,
              loading: isPending,
              type: 'submit',
            },
          },
          cancel: {
            text: uiTranslations.CLOSE,
          },
        }}
      />
    </FormProvider>
  );
};

export default AddNewServiceUserModal;
