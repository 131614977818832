import { TabGroupOptionProps } from '@humanitec/ui-components';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.div`
  flex: 1;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.text};
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-position: inside;
`;

const ListItem = styled.li`
  padding: ${rem(8)} 0;
  font-size: ${units.fontSize.sm};
`;

const DocsLink = styled.a`
  font-size: ${units.fontSize.sm};
`;

/**
 * Returns the documentation links.
 */
const DocumentationLinks = () => {
  // i18n
  const { t } = useTranslation();
  const translations = t('SUPPORT').DOCUMENTATION;
  const options: TabGroupOptionProps[] = [
    {
      label: translations.GET_STARTED,
      value: translations.GET_STARTED_LINK,
    },
    { label: translations.TROUBLESHOOTING, value: translations.TROUBLESHOOTING_LINK },
    { label: translations.API_REFERENCE, value: translations.API_REFERENCE_LINK },
  ];

  const links = options.map((option, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <ListItem key={`doc-link-${index}`}>
      <DocsLink href={option.value} target={'_blank'} rel={'noopener noreferrer'}>
        {option.label}
      </DocsLink>
    </ListItem>
  ));

  return (
    <React.Fragment>
      <Header>
        <HeaderText>{translations.DESCRIPTION}</HeaderText>
      </Header>
      <List>{links}</List>
    </React.Fragment>
  );
};

export default DocumentationLinks;
