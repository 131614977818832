/**
 * Normalize certain native HTML elements
 */
import { rem } from 'polished';
import { css } from 'styled-components';

import { units } from '@src/styles/variables';

export const linkStyle = css`
  color: ${({ theme }) => theme.color.mainBrighter};
  font-size: ${units.fontSize.sm};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.main};
  }
`;

const headings = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    font-family: 'Lazzer Semibold', sans-serif;
  }
  h1 {
    font-size: ${rem(36)};
  }

  h2 {
    font-size: ${rem(24)};
  }

  h3 {
    font-size: ${rem(18)};
    line-height: ${rem(24)};
  }

  h4 {
    font-size: ${rem(16)};
  }

  h5 {
    font-size: ${rem(14)};
  }
`;

export const normalized = css`
  a {
    ${linkStyle};
  }

  button {
    margin: 0;
  }
  ${headings};
`;
