import { Icon, WarningSection } from '@humanitec/ui-components';
import styled, { css } from 'styled-components';

import { DeploymentError, DeploymentErrorScope } from '@src/models/deployment-object';
import { units } from '@src/styles/variables';

const DeploymentErrorRow = styled.div<{ $last?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Source Code Pro', sans-serif;
  font-size: ${units.fontSize.sm};
  ${({ $last }) =>
    !$last &&
    css`
      margin-bottom: ${units.margin.md};
    `}

  color: ${({ theme }) => theme.color.alertBrighter};
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
`;

interface DisplayDeploymentErrorsProps {
  errors: DeploymentError[];
  scope: DeploymentErrorScope;
  object_id?: string;
}

const DisplayDeploymentErrors = ({ errors, scope, object_id }: DisplayDeploymentErrorsProps) => {
  const filtered = scope
    ? errors.filter(
        (depl) => depl.scope === scope && (object_id ? depl.object_id === object_id : true)
      )
    : errors;

  return filtered.length > 0 ? (
    <WarningSection mode={'alert'}>
      {filtered?.map((deploymentError, index) => (
        <DeploymentErrorRow
          $last={index === filtered.length - 1}
          key={`${deploymentError?.code}_${deploymentError?.scope}_${deploymentError.object_id}`}>
          <Summary>
            <Icon name={'warning'} size={12} marginRight={'sm'} overrideColor={'alert-brighter'} />
            {deploymentError.summary}
          </Summary>
          <div>{deploymentError.message}</div>
        </DeploymentErrorRow>
      ))}
    </WarningSection>
  ) : (
    <></>
  );
};

export default DisplayDeploymentErrors;
