import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const usePipelineSchemaQuery = (
  versionId?: string,
  customParams?: { appId?: string; pipelineId?: string }
): QueryResponse<string> => {
  const { orgId, appId: routerAppId, pipelineId: routerPipelineId } = useParams();

  const appId = customParams?.appId || routerAppId;
  const pipelineId = customParams?.pipelineId || routerPipelineId;

  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.schema(orgId, appId, pipelineId, versionId),
    queryFn: () => {
      return makeRequest<string>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/schema${
          versionId ? `?version=${versionId}` : ''
        }`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId && appId && pipelineId && pipelineId !== 'create'),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineSchemaQuery;
