import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';

import useEnvironmentRuntimeQuery from './react-query/environments/queries/useEnvironmentRuntimeQuery';

export const useGetReplicaSetRuntimeStatus = (workloadId: string) => {
  // Context
  const { onLatestDeployment } = useDeploymentOrDeltaContext();

  // React Query
  const { data } = useEnvironmentRuntimeQuery();

  const { environmentRuntime } = data || {};

  return onLatestDeployment ? environmentRuntime?.modules?.[workloadId]?.status_class : undefined;
};
