import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { Registry } from '@src/models/registry';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { registriesQueryKeys } from '../registriesQueryKeys';

const useRegistryUpdateMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<AxiosResponse, AxiosError, Partial<Registry>>({
    mutationFn: (registry) =>
      makeRequest('PATCH', `/orgs/${orgId}/registries/${registry.id}`, registry),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: registriesQueryKeys.list(orgId),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useRegistryUpdateMutation;
