import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li<{ removeMarginTop?: boolean }>`
  margin: ${units.margin.lg} 0;
  ${({ removeMarginTop }) =>
    removeMarginTop &&
    css`
      margin-top: 0;
    `}
  font-size: ${units.fontSize.sm};
`;

/**
 * Returns the documentation links.
 */
const SupportForm = () => {
  const { t } = useTranslation();
  const translations = t('SUPPORT').GET_SUPPORT;

  return (
    <React.Fragment>
      <List>
        <ListItem removeMarginTop>
          <a
            className={'txt-sm'}
            href={'https://calendly.com/humanitec-ut/quick-support'}
            target={'_blank'}
            rel={'noopener noreferrer'}>
            {translations.BOOK_CALL}
          </a>
        </ListItem>
        <ListItem>
          {translations.EMAIL}
          <a
            className={'txt-sm'}
            href={'mailto:product@humanitec.com'}
            target={'_blank'}
            rel={'noopener noreferrer'}>
            product@humanitec.com
          </a>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export default SupportForm;
