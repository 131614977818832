import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionCreateMutationVariables {
  resourceDefinition: Partial<ResourceDefinition>;
}
const useResourceDefinitionCreateMutation = (
  errorCallback?: (error: AxiosError) => void,
  successCallback?: (res: ResourceDefinition) => void
) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceDefinition>,
    AxiosError<ApiErrorResponse>,
    ResourceDefinitionCreateMutationVariables
  >({
    mutationFn: ({ resourceDefinition }) =>
      makeRequest('POST', `/orgs/${orgId}/resources/defs`, resourceDefinition),
    onSuccess: (res) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listDefinitions(orgId) });
      if (successCallback) {
        successCallback(res.data);
      }
    },
    onError: errorCallback,
  });
  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionCreateMutation;
