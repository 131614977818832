import { rem } from 'polished';
import { KeyboardEvent, KeyboardEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

const ToggleSwitchLabel = styled.label<{ noLabelMargin?: boolean }>`
  align-items: center;
  display: flex;
  font-size: ${units.fontSize.sm};
  cursor: pointer;
  ${({ noLabelMargin }) =>
    noLabelMargin &&
    css`
      margin-top: 0 !important;
    `}

  color: ${({ theme }) => theme.color.text};
`;

interface ToggleSwitchIndicatorProps {
  size: 'small' | 'medium';
  checked: boolean;
  disabled: boolean;
  onKeyDown: KeyboardEventHandler<HTMLDivElement>;
}

const ToggleSwitchIndicator = styled.div<ToggleSwitchIndicatorProps>`
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  transition: 0.2s linear;
  margin-right: ${units.margin.sm};
  z-index: 0;
  background-color: ${({ theme }) => theme.color.baseLayer};
  border: ${rem(1)} solid transparent;
  box-shadow:
    inset 1px 1px 3px 0 rgba(0, 0, 0, 0.5),
    0 1px 1px 0 hsla(0, 0%, 100%, 0.05);
  &:hover {
    border-color: ${({ theme }) => theme.color.mainBrighter};
  }

  ${({ checked, theme }) =>
    checked &&
    css`
      background-color: ${theme.color.main};
      box-shadow: unset;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.color.baseOutline};
      pointer-events: none;
      opacity: 0.48;
      border-color: transparent;
    `}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: ${rem(16)};
          width: ${rem(28)};
          border-radius: ${rem(12)};
        `;
      case 'medium':
        return css`
          height: ${rem(18)};
          width: ${rem(32)};
          border-radius: ${rem(18)};
        `;
      // no default
    }
  }}
`;

interface ToggleSwitchSliderProps {
  size: 'small' | 'medium';
  checked: boolean;
  disabled: boolean;
}

const ToggleSwitchSlider = styled.div<ToggleSwitchSliderProps>`
  background-color: white;
  left: 1px;
  line-height: ${rem(16)};
  position: absolute;
  transition: 0.2s linear;
  top: 0;
  z-index: 1;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  top: ${rem(1)};

  &:before,
  &:after {
    content: '';
    font-size: ${rem(14)};
    font-weight: normal;
    text-align: center;
  }
  &:before {
    display: none;
  }

  &:after {
    display: block;
  }
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: ${rem(12)};
          width: ${rem(12)};
          border-radius: 50%;
        `;
      case 'medium':
        return css`
          height: ${rem(14)};
          width: ${rem(14)};
          border-radius: 50%;
        `;
      // no default
    }
  }}

  ${({ size, checked }) => {
    switch (size) {
      case 'small':
        return (
          checked &&
          css`
            left: ${rem(13)};
          `
        );
      case 'medium':
        return (
          checked &&
          css`
            left: ${rem(14)};
          `
        );
      // no default
    }
  }}

  border-color: ${({ theme }) => theme.color.main};
  &:before {
    display: block;
  }
  &:after {
    display: none;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: white;
  }
`;

const ToggleInput = styled.input`
  display: none;
`;

export interface ToggleProps {
  id?: string;
  name: string;
  /** size of the toggle */
  size?: 'small' | 'medium';
  label?: string;
  onChange?: (checked: boolean) => void;
  /** defaultChecked. Don't pass props that can change. Use `setValue` if you need to change the value from outside the component. */
  defaultChecked?: boolean;
  className?: string;
  disabled?: boolean;
  noLabelMargin?: boolean;
}

export const Toggle = ({
  id,
  name,
  size = 'small',
  label,
  onChange,
  className,
  disabled,
  noLabelMargin,
  defaultChecked,
}: ToggleProps) => {
  const { register, watch, setValue } = useFormContext();
  const checked = watch(name);

  const toggleId = id || name;

  const onToggle = () => {
    setValue(name, !checked);
    if (onChange) {
      onChange(!checked);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.keyCode === KeyBindings.ENTER) {
      onToggle();
    }
  };

  return (
    <ToggleSwitchLabel
      htmlFor={toggleId}
      className={className}
      noLabelMargin={noLabelMargin}
      onClick={(e) => e.stopPropagation()}>
      <ToggleInput
        {...register(name)}
        disabled={disabled}
        id={toggleId}
        type={'checkbox'}
        defaultChecked={Boolean(defaultChecked)}
        onClick={() => onToggle()}
      />
      <ToggleSwitchIndicator
        role={'switch'}
        data-testid={`toggle-indicator${name ? `-${name}` : ''}`}
        aria-checked={checked}
        checked={checked}
        disabled={Boolean(disabled)}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        size={size}>
        <ToggleSwitchSlider checked={checked} disabled={Boolean(disabled)} size={size} />
      </ToggleSwitchIndicator>
      <span className={'txt-sm'}>{label}</span>
    </ToggleSwitchLabel>
  );
};
