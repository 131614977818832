import { Toggle } from '@humanitec/ui-components';
import { MouseEvent, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useWalhallContext } from '@src/context/walhallContext';
import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';
import { useWalhallForm } from '@src/utilities/form';

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ThemeSwitcher = ({ className }: { className?: string }) => {
  // Form
  const formMethods = useWalhallForm();
  const { setValue } = formMethods;

  // Context
  const { theme } = useWalhallContext();

  // i18n
  const { t } = useTranslation();
  const headerTranslations = t('COMPONENTS').APP_HEADER;

  // Zustand
  const { setTheme } = useUserPreferencesStore();

  useEffect(() => {
    setValue('darktheme', theme === 'dark');
  }, [theme, setValue]);

  const clickToggleWrapper = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const toggleChange = (checked: boolean) => {
    setTheme(checked ? 'dark' : 'light');
  };

  return (
    <ToggleWrapper className={className} onClick={clickToggleWrapper}>
      <FormProvider {...formMethods}>
        <Toggle
          name={'darktheme'}
          onChange={toggleChange}
          size={'small'}
          label={headerTranslations.DARK_THEME}
        />
      </FormProvider>
    </ToggleWrapper>
  );
};

export default ThemeSwitcher;
