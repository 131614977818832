import { InfoTextBox } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import { MatchParams } from '@src/models/routing';
import { generateDraftURL } from '@src/utilities/navigation';

const DeploymentInfo = () => {
  // Context
  const { onRunningDeployment, onLatestDeployment, onCloneDeployment } =
    useDeploymentOrDeltaContext();

  // React Query
  const { data: activeDelta } = useDeploymentDeltaQuery();

  // i18n
  const { t } = useTranslation();
  const viewAppTranslations = t('VIEW_APPLICATION');

  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  return (
    <>
      {onRunningDeployment
        ? activeDelta && (
            <InfoTextBox
              text={viewAppTranslations.CURRENTLY_RUNNING_INFO}
              link={{
                text: viewAppTranslations.DRAFT_SMALL,
                to: generateDraftURL(orgId, appId, envId, activeDelta?.id, 'workloads'),
              }}
            />
          )
        : onLatestDeployment
          ? activeDelta && (
              <InfoTextBox
                text={viewAppTranslations.DEPLOYING_INFO}
                link={{
                  text: viewAppTranslations.DRAFT_SMALL,
                  to: generateDraftURL(orgId, appId, envId, activeDelta?.id, 'workloads'),
                }}
              />
            )
          : onCloneDeployment
            ? activeDelta && (
                <InfoTextBox
                  text={viewAppTranslations.CLONE_NOT_EDITABLE}
                  link={{
                    text: viewAppTranslations.DRAFT_SMALL,
                    to: generateDraftURL(orgId, appId, envId, activeDelta?.id, 'workloads'),
                  }}
                />
              )
            : activeDelta && (
                <InfoTextBox
                  text={viewAppTranslations.OLD_DEPLOYMENT_CHANGES}
                  link={{
                    text: viewAppTranslations.DRAFT_SMALL,
                    to: generateDraftURL(orgId, appId, envId, activeDelta?.id, 'workloads'),
                  }}
                />
              )}
    </>
  );
};

export default DeploymentInfo;
