import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import WalDropdownMenu from '../../molecules/Menu/DropdownMenu/DropdownMenu';
import { syntaxHighlightingItems, SyntaxHighlightingMode } from './editor-constants';

const SyntaxHighlightingDropdown = styled(WalDropdownMenu)`
  margin-bottom: ${units.margin.md};
`;

const SelectSyntax = ({
  selectedHighlightingSyntax,
  setSelectedHighlightingSyntax,
}: {
  selectedHighlightingSyntax: string;
  setSelectedHighlightingSyntax: (selectedSyntax: SyntaxHighlightingMode) => void;
}) => {
  const formMethods = useWalhallForm();
  return (
    <FormProvider {...formMethods}>
      <SyntaxHighlightingDropdown
        dataTestId={'language-selector'}
        items={syntaxHighlightingItems}
        defaultValue={selectedHighlightingSyntax}
        allowUpdatesToDefaultValue
        onItemClick={(id) => setSelectedHighlightingSyntax(id as SyntaxHighlightingMode)}
      />
    </FormProvider>
  );
};

export default SelectSyntax;
