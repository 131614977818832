import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import { DeploymentSetModuleResource } from '@src/models/deployment-set';
import { ResourceType } from '@src/models/resources';

export const mapResourceEntry = (
  [id, entry]: [string, DeploymentSetModuleResource],
  externalOrShared: 'shared' | 'externals',
  resourceTypes: ResourceType[],
  customVolumesPath?: string
): ResourceDependency & { path: string } => ({
  id,
  type: entry.type,
  class: entry.class,
  category: resourceTypes.find((rt) => rt.type === entry.type)?.category,
  typeName:
    resourceTypes.find((rt) => rt.type === entry.type)?.name ||
    (entry.type === 'emptyDir' ? entry.type : ''),
  path:
    externalOrShared === 'externals'
      ? `/${
          entry.type === 'emptyDir' ? customVolumesPath || 'spec/volumes' : externalOrShared
        }/${id}`
      : `/${id}`,
  externalOrShared,
});
