import { rem } from 'polished';
import styled, { css } from 'styled-components';

import SpinnerSingleSVG from '../../assets/svg/spinner.svg?react';

export type SpinnerColor = 'main' | 'text' | 'white';
export type SpinnerSize = 'small' | 'medium' | 'large';

export interface SpinnerProps {
  /** The color of the spinner */
  color?: SpinnerColor;
  /** The size of the spinner */
  size?: SpinnerSize;
  /** The diameter of the spinner */
  diameter?: number;
  /** Class name passed from styled components */
  className?: string;
}

const SPINNER_SIZE = {
  large: rem(48),
  medium: rem(32),
  small: rem(16),
};

const SpinnerSingle = styled(SpinnerSingleSVG)<{ $color: SpinnerColor }>`
  ${({ $color, theme }) => css`
    path {
      fill: ${theme.color[$color]};
    }
  `};
`;

const SpinnerWrapper = styled.div<{ size: SpinnerSize; diameter?: number }>`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
  animation: spin 2s infinite linear;
  ${({ size, diameter }) => {
    if (diameter) {
      return css`
        height: ${rem(diameter)};
        width: ${rem(diameter)};
      `;
    }
    switch (size) {
      case 'large':
        return css`
          height: ${SPINNER_SIZE.large};
          width: ${SPINNER_SIZE.large};
        `;
      case 'small':
        return css`
          height: ${SPINNER_SIZE.small};
          width: ${SPINNER_SIZE.small};
        `;
      case 'medium':
      default:
        return css`
          height: ${SPINNER_SIZE.medium};
          width: ${SPINNER_SIZE.medium};
        `;
    }
  }};
`;

/**
 * Returns a spinner component.
 */
export const Spinner = ({ size = 'medium', diameter, className, color = 'text' }: SpinnerProps) => (
  <SpinnerWrapper size={size} diameter={diameter} className={className} data-testid={'spinner'}>
    <SpinnerSingle $color={color || 'text'} />
  </SpinnerWrapper>
);
