import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';

export const WalLabel = styled.label<{ error?: boolean }>`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
  margin: 0 0 ${rem(2.75)} 0;
  height: 0.9rem;
  display: inline-flex;
  white-space: wrap;
  ${({ error }) => {
    if (error) {
      return css`
        color: ${({ theme }) => theme.color.alertBrighter};
        font-size: ${units.fontSize.sm};
      `;
    }
  }}
`;
