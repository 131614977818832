import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const usePipelineYamlValidationSchemaQuery = (): QueryResponse<string> => {
  const { orgId } = useParams();

  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.validationSchema(orgId),
    queryFn: () => {
      return makeRequest<string>('GET', `/orgs/${orgId}/pipeline-schemas/latest`);
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineYamlValidationSchemaQuery;
