import { ReactNode } from 'react';

import {
  HumanitecWorkloadProfileFeatureKeys,
  WorkloadFeatureDefinition,
} from '@src/models/workload-profile-v2';

interface Base {
  featureName: HumanitecWorkloadProfileFeatureKeys;
  order: number;
  deltaPath: string;
  hidden: boolean;
  title?: string;
}

/**
 * A 'spec' feature is a feature that's defined as part of the workload profile, and can make changes to the delta.
 * As opposed to a 'runtime' feature which cannot be edited & only displayed in the running deployment.
 */
export interface HumanitecSpecFeature extends Base {
  type: 'spec';
  featurePath: string;
  featureDefinition: WorkloadFeatureDefinition;
}

/**
 * A 'runtime' feature is a feature that is only displayed on the running deployment.
 */
export interface HumanitecRuntimeFeature extends Base {
  type: 'runtime';
  featureDefinition: WorkloadFeatureDefinition;
}

/**
 * A 'hardcoded' featire is one that is not defined in the workload profile, but is always present in the workload.
 * 'Resource dependencies' is the only instance of this so far.
 */
export interface HumanitecHardcodedFeature extends Base {
  type: 'hardcoded';
  element: ReactNode;
}

export type HumanitecFeatureType =
  | HumanitecSpecFeature
  | HumanitecRuntimeFeature
  | HumanitecHardcodedFeature;

export const isSpecFeature = (value: HumanitecFeatureType): value is HumanitecSpecFeature => {
  return Boolean((value as HumanitecSpecFeature).type === 'spec');
};

export const isRuntimeFeature = (value: HumanitecFeatureType): value is HumanitecRuntimeFeature => {
  return Boolean((value as HumanitecRuntimeFeature).type === 'runtime');
};

export const isHardcodedFeature = (
  value: HumanitecFeatureType
): value is HumanitecHardcodedFeature => {
  return Boolean((value as HumanitecHardcodedFeature).type === 'hardcoded');
};
