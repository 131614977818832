import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { Pipeline } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const usePipelineUpdateMutation = () => {
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;
  const queryClient = useQueryClient();

  const { data, ...results } = useMutation<
    AxiosResponse,
    AxiosError<ApiErrorResponse & { details: { errors: string[] } }>,
    { yamlData: string; etag: string }
  >({
    mutationFn: ({ yamlData, etag }) => {
      return makeRequest<Pipeline>(
        'PATCH',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}`,
        yamlData,
        undefined,
        undefined,
        'application/x-yaml',
        etag
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: pipelinesQueryKeys.list(orgId, appId) });
      queryClient.invalidateQueries({
        queryKey: pipelinesQueryKeys.details(orgId, appId, pipelineId),
      });
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });

  return { ...results, data: data?.data };
};

export default usePipelineUpdateMutation;
