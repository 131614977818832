/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line no-restricted-imports
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';

export const useWalhallForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  props?: UseFormProps<TFieldValues, TContext>
) =>
  useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    ...props,
  });

/**
 * Returns the changed form fields based on the provided form values and changed fields.
 *
 * @param {FieldValues} formValues - The form values.
 * @param {Record} changedFields - The changed fields.
 * @returns {Partial} - The changed form fields.
 */
export const getChangedFormFields = (
  formValues: FieldValues,
  changedFields: Record<string, any>
) => {
  const changes: Partial<FieldValues> = {};

  for (const key in changedFields) {
    if (changedFields.hasOwnProperty(key) && key in formValues) {
      const formProperty = key;
      const formPropertyValue = formValues[formProperty];
      const propertyWithChanges = changedFields[formProperty];

      // if property value is an array and it was changed, then assign the entire array
      if (
        Array.isArray(formPropertyValue) &&
        JSON.stringify(propertyWithChanges).includes('true')
      ) {
        changes[formProperty] = formPropertyValue;
      } else if (typeof propertyWithChanges === 'object') {
        const subChanges = getChangedFormFields(formPropertyValue, propertyWithChanges);
        if (Object.keys(subChanges).length > 0) {
          changes[formProperty] = subChanges;
        }
      } else if (propertyWithChanges) {
        changes[formProperty] = formPropertyValue;
      }
    }
  }

  return changes;
};
