import { MultistepModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ContainerNameStep from '@src/components/shared/AddContainerImageModal/components/ContainerNameStep';
import SelectImageStep from '@src/components/shared/AddContainerImageModal/components/SelectImageStep';
import useArtefactsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactsQuery';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { WorkloadContainer } from '@src/models/deployment-set';
import { DEFAULT_RESOURCES } from '@src/utilities/deployment-delta-utils';
import { useWalhallForm } from '@src/utilities/form';

interface AddContainerImageModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  deltaPath: string;
  currentContainerIds: string[];
  /* whether the current image is external */
  currentImageIsExternal?: boolean;
  /* callback function that will be called when a new container is created */
  containerCreated?: (containerId: string) => void;
}

const AddContainerImageModal = ({
  openState,
  currentContainerIds,
  currentImageIsExternal,
  containerCreated,
  deltaPath,
}: AddContainerImageModalProps) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm<{ imageName: string; containerName: string }>();
  const { getValues, trigger } = formMethods;

  // React Query
  const { data: artefacts = [] } = useArtefactsQuery();
  const { data: activeDelta } = useDeploymentDeltaQuery();

  const [selectedImage, setSelectedImage] = useState<string>();
  const [currentStepState, setCurrentStep] = useState<number>(0);

  const { updateWorkload: addContainer } = useDeltaUtils(deltaPath);

  const closeModal = () => {
    openState[1](false);
  };

  const createContainer = async () => {
    if ((await trigger()) && activeDelta) {
      const newContainer: WorkloadContainer = {
        variables: {},
        files: {},
        volume_mounts: {},
        resources: DEFAULT_RESOURCES,
        image: selectedImage,
      };

      addContainer([
        {
          key: getValues('containerName'),
          op: 'add',
          value: newContainer,
        },
      ]);

      if (containerCreated) {
        containerCreated(getValues('containerName'));
      }
      closeModal();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <MultistepModal
        openState={openState}
        currentStepState={[currentStepState, setCurrentStep]}
        title={imageTranslations.CREATE_NEW_CONTAINER}
        size={'large'}
        steps={[
          {
            title: imageTranslations.SELECT_AN_IMAGE,
            content: (
              <SelectImageStep
                artefacts={artefacts}
                currentImageIsExternal={currentImageIsExternal}
                onUpdateImageName={(imageName) => {
                  setSelectedImage(imageName);
                  setCurrentStep(1);
                }}
              />
            ),
            actions: [{ label: uiTranslations.CLOSE, variant: 'primary' }],
          },
          {
            title: imageTranslations.NAME_THIS_CONTAINER,
            content: (
              <ContainerNameStep
                currentContainerIds={currentContainerIds}
                selectedImage={selectedImage}
              />
            ),
            actions: [
              { label: uiTranslations.BACK },
              { label: uiTranslations.CREATE, variant: 'primary', callback: createContainer },
            ],
          },
        ]}
      />
    </FormProvider>
  );
};

export default AddContainerImageModal;
