import { UnitsInput, WalInput } from '@humanitec/ui-components';
import { set } from 'lodash';
import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { DeploymentSetResources } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import {
  checkMemoryValueValidity,
  convertK8sUnitToStandard,
  convertStandardUnitToK8s,
  UnitValuePayload,
} from '@src/utilities/memory-units';

const SettingName = styled.div`
  flex-basis: ${rem(70)};
  font-size: ${units.fontSize.sm};
  margin-right: ${units.margin.sm};
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${rem(80)} ${rem(150)} ${rem(150)};
  height: fit-content;
  column-gap: ${rem(20)};
  align-items: center;
  row-gap: ${units.margin.sm};
`;

const CPUInput = styled(WalInput)`
  input {
    width: 100%;
  }
`;

const MemoryInput = styled(UnitsInput)`
  .input-wrapper {
    width: 100%;
  }
`;

interface AssignResourcesProps {
  deltaPath: string;
}

export const AssignResources = ({ deltaPath }: AssignResourcesProps) => {
  // Form
  const methods = useWalhallForm();
  const { setValue, getValues } = methods;

  // Component state
  const [maxMemory, setMaxMemory] = useState<UnitValuePayload | undefined>(undefined);
  const [minMemory, setMinMemory] = useState<UnitValuePayload | undefined>(undefined);

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // Delta hook
  const { updateWorkload, data: containerResources } =
    useDeltaUtils<DeploymentSetResources>(deltaPath);

  /**
   * Set resources.
   */
  useEffect(() => {
    setMaxMemory(convertK8sUnitToStandard(containerResources?.limits?.memory));
    setMinMemory(convertK8sUnitToStandard(containerResources?.requests?.memory));
  }, [containerResources]);

  /**
   * Make request to update the delta.
   */
  const updateDelta = useCallback(
    (resources: DeploymentSetResources) => {
      updateWorkload([
        {
          op: 'add',
          value: resources,
        },
      ]);
    },
    [updateWorkload]
  );

  /**
   * onChange of Memory input.
   */
  const onChangeMemory = useCallback(
    (payload: UnitValuePayload, maxOrMin: 'requests' | 'limits') => {
      let resources = { ...containerResources };

      // Always round memory value
      const k8sUnit = convertStandardUnitToK8s({
        ...payload,
        value: Math.round(payload.value),
      });

      const memoryValueValidity = k8sUnit
        ? checkMemoryValueValidity(
            k8sUnit,
            resources?.[maxOrMin === 'limits' ? 'requests' : 'limits']?.memory,
            maxOrMin
          )
        : undefined;

      // If the value is the same, reset the input.
      if (k8sUnit && k8sUnit === resources?.[maxOrMin]?.memory) {
        if (maxOrMin === 'limits') setMaxMemory(convertK8sUnitToStandard(k8sUnit));
        else setMinMemory(convertK8sUnitToStandard(k8sUnit));
        return;
      }

      resources = {
        ...resources,
        [maxOrMin]: {
          ...resources[maxOrMin],
          memory: k8sUnit || undefined,
        },
      };

      // If the value of min > max automatically adapt max to the same value as min
      if (memoryValueValidity === 'minGreaterThanMax' && k8sUnit) {
        set(resources, 'limits.memory', k8sUnit);
      }
      // If the value of max < min automatically adapt min to the same value as max
      if (memoryValueValidity === 'maxLessThanMin' && k8sUnit) {
        set(resources, 'requests.memory', k8sUnit);
      }

      updateDelta(resources);
    },
    [containerResources, updateDelta]
  );

  /**
   * onChange of CPU input.
   */
  const onChangeCPU = useCallback(
    (payload: string, maxOrMin: 'requests' | 'limits') => {
      let resources = { ...containerResources };

      let k8sUnit: string | undefined;

      // Check if the value has a decimal value and it's greater than 3. If true, round to 3 places, otherwise value stays the same as inputted.
      const splitPoint = payload.split('.');
      const greaterThanThree = splitPoint.length > 1 && payload.split('.')[1].length > 3;

      k8sUnit = greaterThanThree ? (k8sUnit = parseFloat(payload).toFixed(3)) : payload;

      // If the rounded value is the same, reset input value and exit the function(don't update delta)
      if (k8sUnit && resources?.[maxOrMin]?.cpu === k8sUnit) {
        if (maxOrMin === 'limits') {
          setValue('maxCpu', k8sUnit);
        } else {
          setValue('minCpu', k8sUnit);
        }
        return;
      }

      // If the value of min > max automatically adapt max to the same value as min
      if (maxOrMin === 'requests' && payload > getValues('maxCpu')) {
        setValue('maxCpu', payload);
      }
      // If the value of max < min automatically adapt min to the same value as max
      if (maxOrMin === 'limits' && payload < getValues('minCpu')) {
        setValue('minCpu', payload);
      }

      resources = {
        ...resources,
        [maxOrMin]: {
          ...resources[maxOrMin],
          cpu: k8sUnit || undefined,
        },
      };

      updateDelta(resources);
    },
    [containerResources, setValue, updateDelta, getValues]
  );

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <SettingName>CPU</SettingName>
        <CPUInput
          noBorderRadius={'right'}
          id={'minCpu'}
          name={'minCpu'}
          label={'Min'}
          viewMode={!draftModeActive}
          onBlur={(payload: string) => onChangeCPU(payload, 'requests')}
          defaultValue={containerResources?.requests?.cpu}
        />
        <CPUInput
          noBorderRadius={'left'}
          id={'maxCpu'}
          name={'maxCpu'}
          label={'Max'}
          viewMode={!draftModeActive}
          onBlur={(payload: string) => onChangeCPU(payload, 'limits')}
          defaultValue={containerResources?.limits?.cpu}
        />
        <SettingName>Memory</SettingName>
        <MemoryInput
          noBorderRadius={'right'}
          id={'minMemory'}
          name={'minMemory'}
          label={'Min'}
          viewMode={!draftModeActive}
          onChange={(payload: UnitValuePayload) => onChangeMemory(payload, 'requests')}
          defaultValue={minMemory}
          hideLabel={false}
        />
        <MemoryInput
          noBorderRadius={'left'}
          id={'maxMemory'}
          name={'maxMemory'}
          label={'Max'}
          viewMode={!draftModeActive}
          onChange={(payload: UnitValuePayload) => onChangeMemory(payload, 'limits')}
          defaultValue={maxMemory}
          hideLabel={false}
        />
      </FormProvider>
    </Wrapper>
  );
};
