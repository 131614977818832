import { createContext, useContext } from 'react';

interface DeploymentOrDeltaContextProps {
  /* Used to represent when in draft mode. i.e when contents of a workload can be editted. */
  draftModeActive: boolean;
  onRunningDeployment: boolean;
  onLatestDeployment: boolean;
  onCloneDeployment: boolean;
}

export const DeploymentOrDeltaContext = createContext<DeploymentOrDeltaContextProps>({
  draftModeActive: false,
  onRunningDeployment: false,
  onLatestDeployment: false,
  onCloneDeployment: false,
});

export const useDeploymentOrDeltaContext = () => useContext(DeploymentOrDeltaContext);
