import { rem, rgba } from 'polished';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

const TagElement = styled.div<{ variant?: string }>`
  padding: ${rem(3)} ${rem(6)};
  font-size: ${units.fontSize.sm};
  border-radius: ${rem(4)};

  ${({ variant, theme }) => {
    switch (variant) {
      case 'warning':
        return css`
          border: 1px solid ${theme.color.yellow};
          background-color: ${rgba(theme.color.yellow, 0.05)};
        `;
      case 'danger':
        return css`
          border: 1px solid ${theme.color.alert};
          background-color: ${rgba(theme.color.alert, 0.05)};
        `;
      case 'success':
        return css`
          border: 1px solid ${theme.color.green};
          background-color: ${rgba(theme.color.green, 0.05)};
        `;
      case 'transparent':
        return css`
          border: none;
          background-color: none;
        `;
      default:
        return css`
          background-color: ${theme.color.base};
        `;
    }
  }}
`;

interface TagProps {
  text: string;
  variant?: 'warning' | 'danger' | 'success' | 'transparent';
  className?: string;
  dataTestId?: string;
}

export const Tag = ({ text, variant, className, dataTestId }: TagProps) => (
  <TagElement className={className} variant={variant} data-testid={dataTestId || 'tag'}>
    {text}
  </TagElement>
);
