import { cloneDeep, set as lodashSet } from 'lodash';
import { create } from 'zustand';

import { DeltaCurrentActionsAll } from '../react-query/deployment-delta/mutations/useDeploymentDeltaUpdateMutation';

type DeploymentDeltasStore = {
  // The current delta action. To differentiate between actions in the delta.
  currentDeltaAction?: DeltaCurrentActionsAll;
  lastVisitedDeltas?: {
    [appId: string]: {
      [envId: string]: string;
    };
  };
  setCurrentDeltaAction: (action: DeltaCurrentActionsAll) => void;
  setLastVisitedDelta: (appId: string, envId: string, deltaId: string) => void;
};

export const useDeploymentDeltasStore = create<DeploymentDeltasStore>((set) => {
  return {
    setCurrentDeltaAction: (action) => {
      set(() => ({
        currentDeltaAction: action,
      }));
    },
    setLastVisitedDelta: (appId, envId, deltaId) => {
      set(({ lastVisitedDeltas }) => {
        const newState = cloneDeep(lastVisitedDeltas) || {};

        lodashSet(newState, [appId, envId], deltaId);

        return {
          lastVisitedDeltas: newState,
        };
      });
    },
  };
});
