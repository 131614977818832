import { useQuery } from '@tanstack/react-query';

import { User } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from './userQueryKeys';

const useGetUserByIdQuery = (userId: string) => {
  const { data, ...queryResult } = useQuery({
    queryKey: userQueryKeys.userByUserId(userId),
    queryFn: () => makeRequest<User>('GET', `/users/${userId}`),
    enabled: Boolean(userId),
  });

  return { ...queryResult, data: data?.data };
};

export default useGetUserByIdQuery;
