import { create } from 'zustand';

export interface CurrentPausingEnvironmentStore {
  currentPausingEnvIdSet: Set<string>;
  addCurrentPausingEnvId: (envId: string) => void;
  isEnvPausing: (envId: string) => boolean;
  removeCurrentPausingEnvId: (envId: string) => void;
}
const useCurrentPausingEnvironments = create<CurrentPausingEnvironmentStore>((set, get) => ({
  currentPausingEnvIdSet: new Set([]),
  addCurrentPausingEnvId: (envId) =>
    set((state) => ({ currentPausingEnvIdSet: state.currentPausingEnvIdSet.add(envId) })),
  isEnvPausing: (envId) => get().currentPausingEnvIdSet.has(envId),
  removeCurrentPausingEnvId: (envId) =>
    set((state) => {
      state.currentPausingEnvIdSet.delete(envId);
      return state;
    }),
}));

export default useCurrentPausingEnvironments;
