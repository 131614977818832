import { useEffect, useState } from 'react';

/**
 * Hook to handle clientside pagination. It stores data and can be asked to load more data on button click.
 * Note : This cannot be used for showing content by page, but show all data until that page.
 */
const useClientSidePagination = <T,>(initialData: T[] = [], pageSize = 10) => {
  const [data, setData] = useState<T[]>(initialData);
  const [page, setPage] = useState<number>(1);
  const canShowNextPage = data.length - page * pageSize > 0;
  /**
   * Show items from next page also in the ui
   */
  const showNextPageData = () => {
    if (canShowNextPage) {
      setPage(page + 1);
    }
  };
  useEffect(() => {
    setPage(1);
  }, [data.length]);

  const paginatedData = data.slice(0, page * pageSize);
  return { updateData: setData, page, showNextPageData, canShowNextPage, paginatedData };
};

export default useClientSidePagination;
