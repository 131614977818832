import { EditorToggle } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { units } from '@src/styles/variables';

const EditorToggleWrapper = styled.div`
  margin-bottom: ${units.margin.lg};
`;

interface OverridesProps {
  deltaPath: string;
  /**
   * Remove this once the split-container-overrides flag is removed.
   */
  legacyProps?: {
    title: string;
    emptyStateText: string;
  };
}

const Overrides = ({ deltaPath, legacyProps }: OverridesProps) => {
  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();
  const { emptyStateText, title } = legacyProps || {};

  // Zustand
  const { currentDeltaAction } = useDeploymentDeltasStore();

  // i18n
  const { t } = useTranslation('workloadProfile');
  const overridesTranslations = t('FEATURES').OVERRIDES;

  const { updateWorkload, data } = useDeltaUtils<string[]>(deltaPath);

  const onArgChange = (args: string[]) => {
    if (currentDeltaAction === 'update-overrides') return;

    if (args.length === 0 && data?.length) {
      updateWorkload([
        {
          op: 'replace',
          value: [],
        },
      ]);
    } else if (args.length > 0) {
      updateWorkload([
        {
          op: 'add',
          value: args,
        },
      ]);
    }
  };

  return (
    <>
      <EditorToggleWrapper>
        <EditorToggle
          title={title}
          key={`edit_overrides_${deltaPath}`}
          name={'edit_overrides'}
          value={data || []}
          onSave={onArgChange}
          readonly={!draftModeActive}
          emptyStateText={emptyStateText || overridesTranslations.NO_OVERRIDES_TEXT}
        />
      </EditorToggleWrapper>
    </>
  );
};

export default Overrides;
