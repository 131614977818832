import { KeyValueEditor, KeyValueForm, WalModal } from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { KeyValue } from '@src/models/general';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

interface AddHeadersModalProps {
  openState: any;
  onClickSave: (headers: Record<string, string>) => void;
  title?: string;
  subtitle?: string;
  // the initial headers when the modal is opened
  initialHeaders?: Record<string, string>;
  headersState: [Record<string, string>, Dispatch<SetStateAction<Record<string, string>>>];
  readonly?: boolean;
}

const FormWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;

const HeadersListWrapper = styled.div`
  overflow: auto;
  max-height: ${rem(200)};
`;

const AddHeadersModal = ({
  openState,
  initialHeaders,
  title,
  subtitle,
  onClickSave,
  headersState,
  readonly,
}: AddHeadersModalProps) => {
  const keyValueForm = useWalhallForm();
  const [open, setOpen] = openState;
  const [headers, setHeaders] = headersState;
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const webhooksTranslations = t('APP_SETTINGS').WEBHOOKS;

  useEffect(() => {
    if (initialHeaders) {
      const tempHeaders = {
        ...initialHeaders,
      };
      setHeaders(tempHeaders);
    }
  }, [initialHeaders, setHeaders]);

  const onCreate = (keyValue: KeyValue) => {
    const { key, value } = keyValue;

    setHeaders((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const deleteKeyValue = (keyValue: KeyValue) => {
    setHeaders((prevState) => {
      delete prevState[keyValue.key];
      return { ...prevState };
    });
  };

  const saveKeyValue = (keyValue: KeyValue) => {
    setHeaders((prevState) => ({
      ...prevState,
      [keyValue.key]: keyValue.value,
    }));
  };

  const save = () => {
    onClickSave(headers);
    setOpen(false);
  };

  return (
    <WalModal
      size={'large'}
      disableClickOutside
      openState={[open, setOpen]}
      disableOverflow
      title={
        title
          ? title
          : readonly
            ? webhooksTranslations.VIEW_HEADERS_MODAL_TITLE
            : webhooksTranslations.MANAGE_HEADERS_MODAL_TITLE
      }
      subTitle={subtitle && webhooksTranslations.ADD_HEADERS_MODAL_SUBTITLE}
      content={
        <>
          {!readonly && (
            <FormProvider {...keyValueForm}>
              <FormWrapper>
                <KeyValueForm submitButton handleSaveField={onCreate} keyExtension={''} />
              </FormWrapper>
            </FormProvider>
          )}
          {headers && (
            <HeadersListWrapper>
              {Object.entries(headers).map(([key, value]) => (
                <KeyValueEditor
                  id={'add-headers'}
                  hideLabel
                  key={key}
                  keyExtension={''}
                  readonly={readonly}
                  keyValue={{ key, value }}
                  onDeleteField={deleteKeyValue}
                  onSaveField={saveKeyValue}
                />
              ))}
            </HeadersListWrapper>
          )}
        </>
      }
      actions={{
        main: {
          text: uiTranslations.SAVE,
          hideButton: readonly,
          props: {
            onClick: save,
          },
        },
        cancel: {},
      }}
    />
  );
};

export default AddHeadersModal;
