import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import ErrorSVG from '@src/assets/svg/illustrations/404.svg?react';
import { units } from '@src/styles/variables';

import DefaultErrorPageButtons from './components/DefaultErrorPageButtons';

const ErrorPageWrapper = styled.div<{ disableCentering?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ disableCentering }) =>
    !disableCentering &&
    css`
      justify-content: center;
    `}
  align-items: center;
  width: 100%;
`;

const ErrorTitle = styled.h2`
  margin-bottom: ${units.margin.lg};
`;

const ErrorDescription = styled.span`
  font-size: ${units.fontSize.sm};
  margin: ${units.margin.lg} 0;
`;

const NotFoundSVG = styled(ErrorSVG)<any>`
  ${({ descriptionTexts }) =>
    !descriptionTexts &&
    css`
      margin-bottom: ${units.margin.lg};
    `}
`;

const ErrorPageButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  button:not(:last-child) {
    margin-right: ${units.margin.lg};
  }
`;

interface ErrorPageProps {
  title?: string;
  descriptionTexts?: string[];
  buttons?: any;
  disableCentering?: boolean;
}

const ErrorPage = ({ title, descriptionTexts, buttons, disableCentering }: ErrorPageProps) => {
  // i18n
  const { t } = useTranslation();
  const errorTranslations = t('ERROR');

  return (
    <ErrorPageWrapper disableCentering={disableCentering}>
      <ErrorTitle>{title || errorTranslations.PAGE_NOT_FOUND}</ErrorTitle>
      <NotFoundSVG />
      {descriptionTexts &&
        descriptionTexts.map((text: string, index: number) => {
          // eslint-disable-next-line react/no-array-index-key
          return <ErrorDescription key={index}> {text}</ErrorDescription>;
        })}
      <ErrorPageButtonsWrapper>
        {buttons ? buttons : <DefaultErrorPageButtons />}
      </ErrorPageButtonsWrapper>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
