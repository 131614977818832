import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { Registry } from '@src/models/registry';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { registriesQueryKeys } from '../registriesQueryKeys';

const useRegistriesListQuery = (): QueryResponse<Registry[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: registriesQueryKeys.list(orgId),
    queryFn: () => makeRequest<Registry[]>('GET', `/orgs/${orgId}/registries`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useRegistriesListQuery;
