import { rem } from 'polished';

export const APP_MAX_WIDTH = '1080px';
export const NEW_APP_HEADER_HEIGHT = '60px';

export const units = {
  appMaxWidth: rem(APP_MAX_WIDTH),
  containerHorizontalPadding: rem(30),
  containerHorizontalPaddingMobile: rem(10),
  navHeight: rem(60),

  fontSize: {
    xl: rem(20),
    lg: rem(16),
    base: rem(14),
    sm: rem(12),
  },

  margin: {
    xl: rem(30),
    lg: rem(20),
    md: rem(10),
    sm: rem(5),
    xs: rem(3),
    xxs: rem(1),
    0: rem(0),
  },

  padding: {
    xxxl: rem(30),
    xxl: rem(20),
    xl: rem(15),
    lg: rem(10),
    md: rem(8),
    sm: rem(6),
    xs: rem(4),
    xxs: rem(2),
    0: rem(0),
  },

  modal: {
    xl: rem(920),
    lg: rem(700),
    md: rem(500),
  },
};
