import { datadogRum } from '@datadog/browser-rum';
import type { Monaco, OnMount } from '@monaco-editor/react';
import Ajv, { Schema } from 'ajv';
import jsYaml from 'js-yaml';
import { isEqual, isObject, keys } from 'lodash';
import type { IDisposable, IKeyboardEvent } from 'monaco-editor';
import { KeyboardEvent } from 'react';

import { darkPalette, lightPalette } from '@src/theme/color-palette';
import { VALID_PLACEHOLDER_REGEX } from '@src/utilities/string-utility';

/**
 * Search for autocomplete items from a list of nested objects of placeholders. Search term always ends with dot.
 * Other cases are handled elsewhere or by the editor itself
 *
 * @example
 * [{user: javascript, description: 'this is a description'}, {user:[{first: javascript, description: 'this is a description'}]}] , user. => [{name:'javascript',module:false, description: 'this is a description'}, {name:'first', module:true,  description: 'this is a description'}}]
 * @param placeholders placeholder objects (never a string)
 * @param {string} searchTerm term to search for (always ends with period (.) )
 * @returns  list of search results with module type included
 */
export const searchResults = (
  placeholders: any[],
  searchTerm: string
): { name: string; module: boolean; description: string }[] => {
  // return empty results when there are no placeholder objects
  if (placeholders.length === 0 || !searchTerm.endsWith('.')) {
    return [];
  }

  const terms = searchTerm.split('.');
  const mainTerm = terms[0];
  return terms.length === 2
    ? // in case the search term is 'foo.',  return keys
      placeholders
        .map((p) => p[mainTerm])
        .filter(isObject)
        .flatMap((obj) =>
          keys(obj)
            .filter((key) => key !== 'description')
            .map((key) => {
              const value: any = obj[key as keyof typeof obj];
              return {
                name: key,
                module: isObject(value) && !isEqual(keys(value), ['description']),
                description: value.description,
              };
            })
        )
    : // in case the search term is nested object like 'foo.bar.' exclude first term and recursively search
      searchResults(
        placeholders.map((p) => p[mainTerm]).filter(isObject),
        terms.slice(1).join('.')
      );
};

export enum WALHALL_MONACO_THEMES {
  'DARK' = 'WALHALL-MONACO-THEME-DARK',
  'LIGHT' = 'WALHALL-MONACO-THEME-LIGHT',
}

/**
 * Use this function to set global themes for monaco. We can change the themes when theme value changes later on
 *
 * @param monaco the monaco editor object
 */
export const addThemes = (monaco: Monaco) => {
  if (!monaco) {
    return;
  }
  // theme for input when monaco is used , dark mode
  monaco.editor.defineTheme(WALHALL_MONACO_THEMES.DARK, {
    base: 'vs-dark', // can also be vs-dark or hc-black
    inherit: true, // can also be false to completely replace the builtin rules
    rules: [
      {
        token: 'source',
        foreground: darkPalette.placeholderText,
      },
      {
        token: 'delimiter.bracket',
        foreground: darkPalette.mainBrighter,
      },
      {
        token: 'carriage',
        foreground: '#e34e34',
        fontStyle: 'bold',
      },
      {
        token: 'text',
        foreground: darkPalette.text,
      },
    ],
    colors: {
      'editor.background': darkPalette.baseLayer,
    },
  });

  // theme for input when monaco is used , light mode
  monaco.editor.defineTheme(WALHALL_MONACO_THEMES.LIGHT, {
    base: 'vs', // can also be vs-dark or hc-black
    inherit: true, // can also be false to completely replace the builtin rules
    rules: [
      {
        token: 'source',
        foreground: lightPalette.placeholderText,
      },
      {
        token: 'delimiter.bracket',
        foreground: lightPalette.mainDarker,
      },
      {
        token: 'carriage',
        foreground: '#e34e34',
        fontStyle: 'bold',
      },
      {
        token: 'text',
        foreground: lightPalette.text,
      },
    ],
    colors: {
      'editor.background': lightPalette.baseLayer,
    },
  });
};

export const setTheme = (monaco: Monaco, theme: string) => {
  if (!monaco?.editor) return;
  // set theme when monaco is loaded or when theme changes
  if (theme === 'dark') {
    monaco.editor.setTheme(WALHALL_MONACO_THEMES.DARK);
  } else {
    monaco.editor.setTheme(WALHALL_MONACO_THEMES.LIGHT);
  }
};

/*
 * add tokens so that we can add custom styling through the custom themes
 */
export const setPlaceHolderSyntaxHighlighting = (languages: any[]) => {
  languages
    .find((lang) => ['yaml'].includes(lang.id))
    ?.loader()
    .then((loader: any) => {
      const yamlLang = loader.language;
      // introducing bracketCounting to YAML. Which is needed to handle ${} inside doubleQuotedString below
      yamlLang.tokenizer.bracketCounting = [
        [/\{/, 'delimiter.bracket', '@bracketCounting'],
        [/\}/, 'delimiter.bracket', '@pop'],
      ];

      yamlLang.tokenizer.flowScalars = [
        [/"([^"\\]|\\.)*$/, 'text.invalid'], // invalid text
        [/'([^'\\]|\\.)*$/, 'text.invalid'], // invalid text
        [/'/, 'text'], // if a single quote is encountered, consider it as text
        // if ${ is encountered , from now its of token 'source' ( defined somewhere else)
        [/\$\{/, { token: 'delimiter.bracket', next: '@bracketCounting' }],
        [/@escapes/, 'text.escape'], // handle escapes if you encounter them
        [/\\./, 'text.escape.invalid'],
        [/'/, 'text', '@pop'], // if you encounter another single quote, end the token 'text' here
        // if you encounter a doubleQuote , its now 'text' token and go to @doubleQuotedString definition (below)
        [/"/, 'text', '@doubleQuotedString'],
      ];
      // override the double quoted string token of default yaml
      // This is similar to (copied from ) backticks in javascript.
      // We are overriding YAML double quoted string implementation
      yamlLang.tokenizer.doubleQuotedString = [
        [/\$\{/, { token: 'delimiter.bracket', next: '@bracketCounting' }],
        [/[^\\"$]+/, 'text'],
        [/@escapes/, 'text.escape'],
        [/\\./, 'text.escape.invalid'],
        [/"/, 'text', '@pop'],
      ];

      /*
       The last token in yaml tokenizer root object is string. check the link
       https://github.com/microsoft/monaco-editor/blob/main/src/basic-languages/yaml/yaml.ts#L94
       It is copied and an additional ^$ is added to it , so that the placeholder is ignored.
       Without this the text in yaml of the format ` abc ${foo}` is considered as string as whole
       which is not what we want
      */
      yamlLang.tokenizer.root[yamlLang.tokenizer.root.length - 1] = [
        /[^#^$]+/,
        {
          cases: {
            '@keywords': 'keyword',
            '@default': 'text',
          },
        },
      ];

      // CR
      yamlLang.tokenizer.root.unshift({ include: 'carriage' });
      yamlLang.tokenizer.carriage = [['␍', 'carriage']];
    });
};

/*
 * Configure the editor to display autocomplete options when `${}` tags are added.
 *
 * @param monaco the monaco editor object
 * @param placeholder the list of placeholders objects/strings . It can be nested multiple levels
 * @param languages list of languages autocomplete is being enabled. We are not adding them together as we want to different autocomplete options in different places
 */
export const setPlaceholderAutocomplete = (
  monaco: Monaco,
  placeholders: any[],
  languages: string[]
): IDisposable => {
  return monaco.languages.registerCompletionItemProvider(languages, {
    triggerCharacters: ['{', '.'],
    provideCompletionItems: (model, position) => {
      // check if it we need to trigger autocomplete , i.e. if the text is starting with ${ , ending with .
      const textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });
      if (!textUntilPosition.match(/\$\{([A-Z0-9-_]+\.)*/i)) {
        return { suggestions: [] };
      }

      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };

      // search for child items on '.'
      if (textUntilPosition.endsWith('.')) {
        const match = textUntilPosition.match(/\$\{([A-Z0-9-_.]+)/gi); // check if matchs ${foo.}
        if (match) {
          const objPath = match[match.length - 1].replace(/^\$\{/, ''); // objPath = 'foo.'
          const suggestions = searchResults(placeholders.filter(isObject), objPath).map(
            (placeholder) => ({
              label: placeholder.name,
              detail: placeholder.description,
              kind: placeholder.module
                ? monaco.languages.CompletionItemKind.Module
                : monaco.languages.CompletionItemKind.Property,
              insertText: placeholder.name + (placeholder.module ? '.' : '}'),
              range,
            })
          );
          return { suggestions };
        }
      }

      // if search term do not end with dot , proceed with other autocomplete
      return {
        suggestions: placeholders
          .flatMap((placeholder) =>
            isObject(placeholder)
              ? keys(placeholder)
                  .filter((key) => key !== 'description')
                  .map((key) => {
                    const value: any = placeholder[key as keyof typeof placeholder];
                    return {
                      name: key,
                      module: isObject(value) && !isEqual(keys(value), ['description']),
                      description: value.description,
                    };
                  })
              : {
                  name: placeholder,
                  module: false,
                  description: placeholder['description' as keyof typeof placeholder],
                }
          )
          .map((result) => ({
            label: result.name,
            detail: result.description,
            kind: result.module
              ? monaco.languages.CompletionItemKind.Module
              : monaco.languages.CompletionItemKind.Property,
            insertText: result.name + (result.module ? '.' : '}'),
            range,
          })),
      };
    },
  });
};

/*
 *  Force the editor to show autocomplete options when TAB key is present.
 *  TODO: Ctrl+space is not working for some reason
 */
export const registerSuggestionCommand: OnMount = (editor, monaco) => {
  editor.addCommand(
    /* eslint-disable no-bitwise */
    monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyJ,
    () => {
      editor.trigger('', 'editor.action.triggerSuggest', '');
    },
    'editorTextFocus && !editorHasSelection && ' +
      '!editorHasMultipleSelections && !editorTabMovesFocus && ' +
      '!hasQuickSuggest'
  );
};

/*
 * Create a custom language one for each set of placeholders we need.
 *
 * Example for custom language: https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-custom-languages
 *
 */
export const addCustomLanguage = (monaco: Monaco, languageName: string) => {
  // register language only once, ignore if it already exists
  if (monaco.languages.getLanguages().find(({ id }) => id === languageName)) return;

  monaco.languages.register({ id: languageName });
  monaco.languages.setMonarchTokensProvider(languageName, {
    tokenizer: {
      root: [
        [/[^$]+/, 'text'],
        // add token for placeholder highlighting
        [VALID_PLACEHOLDER_REGEX, 'source'],
        [/␍/, 'carriage'],
      ],
    },
  });
};

/**
 * Monaco has its own Event object and react has its own Event object and both are different. Since we are using react
 * events in our app, this method converts monaco events to react events
 */
export const getReactKeyboardEvent = (ev: IKeyboardEvent): KeyboardEvent => {
  return {
    ...ev,
    altKey: ev.browserEvent.altKey,
    charCode: ev.browserEvent.charCode,
    ctrlKey: ev.browserEvent.ctrlKey,
    key: ev.browserEvent.key,
    keyCode: ev.browserEvent.keyCode,
    location: ev.browserEvent.location,
    metaKey: ev.browserEvent.metaKey,
    repeat: ev.browserEvent.repeat,
    shiftKey: ev.browserEvent.shiftKey,
    which: ev.browserEvent.which,
  } as unknown as KeyboardEvent;
};

/**
 * Checks if user is inside autocomplete.
 * Valid cases:
 * ${modules.
 * some text ${modules.
 * some text ${modules.<cursor> some other text
 * Invalid cases:
 * ${modules.<cursor>}
 */
export const insideAutocomplete = (text: string) => text.match(/.*\${[^}]*$/) !== null;

/**
/**
 * Finds the line and column numbers of a specific path within a YAML string.
 *
 * @param {string} yamlString - The YAML string to search within.
 * @param {string} path - The path to search for within the YAML string.
 * @param {string} schemaPath - The schema path indicating the type of the path.
 * @returns {object} An object containing the start and end line and column numbers.
 */
const findLineAndColumnNumbers = (yamlString: string, path: string, schemaPath: string) => {
  // Split the YAML string into an array of lines
  const lines = yamlString.split('\n');

  // Split the path into an array of keys and remove any empty keys
  const pathKeys = path.split('/').filter((key) => key.trim() !== '');

  // Initialize variables to keep track of the current key and column number
  let currentKeyIndex = 0;
  let currentColumnNumber = 1;

  // Initialize the starting line number to 1
  let startLineNumber = 1;

  // Check if the schema path contains 'items' which indicates error path includes array
  const isSchemaPathArray = schemaPath.includes('items');

  // Determine the index of the last key in the path
  const lastKeyIndex = isSchemaPathArray ? pathKeys.length - 1 : pathKeys.length;

  // Iterate through each line of the YAML string
  for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
    const line = lines[lineNumber];

    // Find the index of the current key in the line
    const matchIndex = line.indexOf(pathKeys[currentKeyIndex]);

    // If the key is found in the line
    if (matchIndex !== -1) {
      // Move to the next key and update the column number
      currentKeyIndex++;
      currentColumnNumber = matchIndex + 1;

      // If the last key in the path is reached, update the starting line number and exit the loop
      if (currentKeyIndex === lastKeyIndex) {
        startLineNumber = lineNumber + 1;
        break;
      }
    }
  }

  // Return an object with the line and column numbers
  return {
    startLineNumber,
    startColumn: currentColumnNumber,
    endLineNumber: startLineNumber,
    endColumn: currentColumnNumber,
  };
};

/**
 * Converts an array of errors to marker data for displaying in an editor.
 *
 * @param {Array} errors - The array of errors.
 * @param {string} yamlString - The YAML string.
 * @returns {Array} - The marker data array.
 */
const convertErrorsToMarkerData = (errors: any[], yamlString: any) => {
  return errors.map((error) => {
    const paramKeys = Object.values(error.params);
    const propertyName = paramKeys.length > 0 ? paramKeys.join(', ') : '';
    const message = error.message || '';

    const markerData = {
      message: `${message}: '${propertyName}'`,
      severity: 3,
      ...findLineAndColumnNumbers(yamlString, error.instancePath, error.schemaPath),
    };
    return markerData;
  });
};

/**
 * Checks if the error is a YAML parsing error.
 *
 * @param {any} error - The error object to check.
 * @returns {boolean} - True if the error is a YAML parsing error, false otherwise.
 */
const isYamlParsingError = (error: any): error is jsYaml.YAMLException => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'mark' in error &&
    typeof error.mark?.line === 'number' &&
    typeof error.mark?.column === 'number'
  );
};

/**
 * Validates a YAML string against a given schema and returns any validation errors as marker data.
 *
 * @param {string} yamlString - The YAML string to validate.
 * @param {Schema} schema - The schema to validate against.
 * @returns {Array|null} - An array of marker data if there are validation errors, or null if the YAML is valid.
 */
export const validateYaml = (yamlString: string, schema: Schema) => {
  try {
    const yamlObject = jsYaml.load(yamlString);
    const ajv = new Ajv();
    const validate = ajv.compile(schema);
    const isValid = validate(yamlObject);

    if (!isValid && validate.errors) {
      return convertErrorsToMarkerData(validate.errors, yamlString);
    }

    return;
  } catch (e) {
    if (isYamlParsingError(e)) {
      return [
        {
          severity: 3,
          message: e.message,
          startLineNumber: e.mark?.line + 1,
          startColumn: e.mark?.column + 1,
          endLineNumber: e.mark?.line + 1,
          endColumn: e.mark?.column + 1,
        },
      ];
    } else {
      datadogRum.addError(e);
    }
  }
};
