import { DropdownItem, MultiSelectGroup, Toggle, WalCard } from '@humanitec/ui-components';
import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import useUpdateWebhookMutation from '@src/hooks/react-query/webhooks/mutations/useUpdateWebhookMutation';
import { useRBAC } from '@src/hooks/useRBAC';
import { Webhook } from '@src/models/webhook';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import { WebhooksWrapperGrid } from './components/styles';
import WebhookForm from './components/WebhookForm';

const ActiveToggle = styled(Toggle)<{ addMargin?: boolean }>`
  ${({ addMargin }) =>
    addMargin &&
    css`
      margin-top: ${units.margin.lg};
    `}
`;

interface WebhookProps {
  triggerOptions: (MultiSelectGroup | DropdownItem<string>)[];
  webhook: Webhook;
  className?: string;
  error?: number | null;
}

const WebhookComponent = ({ webhook, triggerOptions, className, error }: WebhookProps) => {
  // Form
  const formMethods = useWalhallForm();
  const { setError, clearErrors, setValue } = formMethods;

  // i18n
  const { t } = useTranslation();
  const webhooksTranslations = t('APP_SETTINGS').WEBHOOKS;

  // Context
  const canUpdateWebhook = useRBAC('updateWebhook');

  // React Query
  const { mutate: updateWebhook } = useUpdateWebhookMutation();

  const handleToggleChange = useCallback(
    (checked: boolean) => {
      updateWebhook({
        webhookId: webhook.id,
        webhook: {
          disabled: !checked,
        },
      });
    },
    [updateWebhook, webhook.id]
  );

  useEffect(() => {
    setValue(`${webhook.id}_is_active`, !webhook?.disabled);
  }, [webhook?.disabled, setValue, webhook.id]);

  useEffect(() => {
    if (error === 409) {
      setError('id', { type: 'manual', message: webhooksTranslations.WEBHOOK_ID_EXISTS });
    } else {
      clearErrors('id');
    }
  }, [error, setError, clearErrors, webhooksTranslations.WEBHOOK_ID_EXISTS]);

  return (
    <FormProvider {...formMethods}>
      <WebhooksWrapperGrid className={className}>
        <ActiveToggle
          addMargin={!webhook}
          name={`${webhook.id}_is_active`}
          onChange={handleToggleChange}
          size={'small'}
          disabled={!canUpdateWebhook}
        />
        <WalCard padding={'small'} cardStyle={'default'}>
          <WebhookForm webhook={webhook} hideLabel triggerOptions={triggerOptions} />
        </WalCard>
      </WebhooksWrapperGrid>
    </FormProvider>
  );
};

export default WebhookComponent;
