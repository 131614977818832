import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';

interface ConfirmDeleteApiTokenModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  onDeleteConfirmed: () => void;
  // serviceUserToBeDeleted: Role | undefined;
  onCancel?: () => void;
}

const ConfirmDeleteApiTokenModal = ({
  openState,
  onDeleteConfirmed,
  onCancel,
}: ConfirmDeleteApiTokenModalProps) => {
  // i18n
  const { t } = useTranslation();
  const conflictModalTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.CONFIRM_REVOKE_API_TOKENS;
  // State
  const [open, setOpen] = openState;

  return (
    <ConfirmDeleteModal
      title={conflictModalTranslations.TITLE}
      confirmMessage={conflictModalTranslations.DESCRIPTION}
      state={[open, setOpen]}
      deleteConfirmedCallback={onDeleteConfirmed}
      onCancel={onCancel}
      deleteButtonText={conflictModalTranslations.REVOKE_API_TOKEN}
      showWarningText
    />
  );
};

export default ConfirmDeleteApiTokenModal;
