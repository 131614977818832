import { useTranslation } from 'react-i18next';

const Freetrial = () => {
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  return (
    <a className={'txt-sm'} href={authTranslations.FREE_TRIAL_LINK}>
      {authTranslations.SIGN_UP}
    </a>
  );
};

export default Freetrial;
