import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { Rule } from '@src/models/rule';
import makeRequest from '@src/utilities/make-request';

import { rulesQueryKeys } from '../rulesQueryKeys';

const useAppRolesQuery = () => {
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  return useQuery({
    queryKey: rulesQueryKeys.list(orgId, appId, envId),
    queryFn: () => makeRequest<Rule[]>('GET', `/orgs/${orgId}/apps/${appId}/envs/${envId}/rules`),
    enabled: Boolean(orgId && appId && envId),
    select: (response) => response.data,
  });
};

export default useAppRolesQuery;
