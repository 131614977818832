import { create } from 'zustand';

type MonacoStore = {
  variablesPlaceholders: boolean;
  filesPlaceholders: boolean;
  resourcesPlaceholders: boolean;
  setVariablesPlaceholders: (isEnabled: boolean) => void;
  setFilesPlaceholders: (isEnabled: boolean) => void;
  setResourcesPlaceholders: (isEnabled: boolean) => void;
};
export const useMonacoStore = create<MonacoStore>((set) => ({
  variablesPlaceholders: false,
  filesPlaceholders: false,
  resourcesPlaceholders: false,
  setVariablesPlaceholders: (isEnabled: boolean) =>
    set(() => ({ variablesPlaceholders: isEnabled })),
  setFilesPlaceholders: (isEnabled: boolean) => set(() => ({ filesPlaceholders: isEnabled })),
  setResourcesPlaceholders: (isEnabled: boolean) =>
    set(() => ({ resourcesPlaceholders: isEnabled })),
}));
