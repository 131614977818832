import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useCurrentUserStore } from '@src/hooks/zustand/useCurrentUserStore';
import makeRequest from '@src/utilities/make-request';

const useLogoutMutation = () => {
  const { setAuthErrorMessage, setAuthType } = useCurrentUserStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError>({
    mutationFn: () => makeRequest('POST', `/auth/logout`),
    onSuccess: () => {
      setAuthErrorMessage(null);
      setAuthType(undefined);
      // Remove all queries from the cache
      queryClient.removeQueries();
      navigate('/auth/login');
      datadogRum.stopSessionReplayRecording();
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });
};

export default useLogoutMutation;
