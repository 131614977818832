import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentDeltaMetadataMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaMetadataMutation';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentQuery from '@src/hooks/react-query/environments/queries/useDeploymentQuery';
import { MatchParams } from '@src/models/routing';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const ViewDeploymentPageHeader = () => {
  // i18n
  const { t } = useTranslation('viewDeployment');
  const { t: tCommon } = useTranslation();

  const uiTranslations = tCommon('UI');
  // React Query
  const { mutate: updateDeploymentDeltaMetadata } = useDeploymentDeltaMetadataMutation();
  const { data: activeDelta } = useDeploymentDeltaQuery();
  const { data: displayedDeployment } = useDeploymentQuery();

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // Router hooks
  const { deltaId } = useParams<keyof MatchParams>() as MatchParams;

  const updateDeltaName = (value: string) => {
    updateDeploymentDeltaMetadata({ deltaId, metadata: 'name', value });
  };

  const subtitles = useMemo(() => {
    const arr = [];
    if (displayedDeployment?.created_at) {
      arr.push(
        formatDate(
          displayedDeployment?.created_at,
          DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE,
          true
        ) as string
      );
    }
    if (displayedDeployment?.id) {
      arr.push(displayedDeployment.id);
    }
    return arr;
  }, [displayedDeployment?.created_at, displayedDeployment?.id]);

  return (
    <PageHeader
      renameTitle={
        draftModeActive
          ? {
              submitCallback: updateDeltaName,
              defaultValueEmpty: Boolean(draftModeActive && !activeDelta?.metadata?.name),
              modalTitle: t('RENAME_DRAFT'),
            }
          : undefined
      }
      subtitles={subtitles}
      customHeading={
        !draftModeActive ? displayedDeployment?.comment || uiTranslations.NO_COMMENT : undefined
      }
      showPageContext
    />
  );
};

export default ViewDeploymentPageHeader;
