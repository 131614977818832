import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css, FlattenSimpleInterpolation } from 'styled-components/macro';

import { KeyValue } from '@src/models/general';
import { KeyBindings } from '@src/types/key-bindings';

import { AutoCompleteOptions } from '../AutoCompleteInput/AutoCompleteInput';
import {
  AutoCompleteDefinition,
  FormGenerator,
  InputDefinition,
} from '../FormGenerator/FormGenerator';

export interface KeyValueFormProps {
  id?: string;
  /** key value object */
  keyValue?: KeyValue;
  handleSaveField?: (formData: any) => void;
  /** options for the autocomplete component */
  autoCompleteOptions?: AutoCompleteOptions;
  /** if the key should be readonly */
  keyReadOnly?: boolean;
  hideLabel?: boolean;
  showDescription?: boolean;
  showToggle?: boolean;
  submitOnClickOutside?: boolean;
  existingKeys?: string[];
  submitButton?: boolean;
  gridTemplate?: FlattenSimpleInterpolation;
  size?: 'small' | 'medium' | 'large';
  valueRequired?: boolean;
  editMode?: boolean;
  keyExtension?: string;
}

export const KeyValueForm = ({
  keyValue,
  autoCompleteOptions,
  keyReadOnly,
  hideLabel,
  id,
  handleSaveField,
  showDescription,
  showToggle,
  submitOnClickOutside,
  existingKeys,
  submitButton,
  gridTemplate,
  size,
  valueRequired = false,
  keyExtension,
  editMode,
}: KeyValueFormProps) => {
  const [focusOnValue, setFocusOnValue] = useState(false);
  const [inputClicked, setInputClicked] = useState<'key' | 'value' | null>(null);

  const valueInputRef = useRef<HTMLInputElement>(null);
  const keyInputRef = useRef<HTMLInputElement>(null);
  const { watch, trigger, reset, setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const keyName = id ? `${id}-key` : 'key';
  const valueName = id ? `${id}-value` : 'value';
  const descriptionName = id ? `${id}-description` : 'description';
  const isSecretName = id ? `${id}-is_secret` : 'is_secret';

  const formValues = watch();

  useEffect(() => {
    if (editMode) {
      keyInputRef.current?.focus();
    }
  }, [editMode]);

  const save = () => {
    if (handleSaveField) {
      setValue(valueName, getValues()[valueName].replace(/\\n/g, '\n')); // unescape newlines
      handleSaveField(getValues());
      setValue(keyName, '');
      setValue(valueName, '');
      setInputClicked(null);
      keyInputRef.current?.focus();
    }

    reset({
      [keyName]: '',
      [valueName]: '',
      [descriptionName]: '',
    });
  };

  const handleValueKeyDown = (event: KeyboardEvent) => {
    setInputClicked(null);
    if (event.keyCode === KeyBindings.ENTER && !event.shiftKey) {
      trigger().then((valid) => {
        if (valid) {
          save();
        }
      });
      keyInputRef.current?.focus();
    }
  };

  const handleKeyKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER) {
      event.preventDefault();
      if (autoCompleteOptions) {
        setFocusOnValue(true);
      } else {
        valueInputRef.current?.focus();
      }
    }
  };

  const handleKeyBlur = () => {
    if (submitOnClickOutside && formValues[valueName] !== '' && inputClicked !== 'value') {
      trigger().then((valid) => {
        if (valid) {
          save();
        }
      });
    }
  };

  const handleValueBlur = () => {
    if (submitOnClickOutside && inputClicked !== 'key') {
      trigger().then((valid) => {
        if (valid) {
          save();
        }
      });
    }
  };

  const autoCompleteField: AutoCompleteDefinition | null = autoCompleteOptions
    ? {
        type: 'autocomplete',
        props: {
          name: valueName,
          hideLabel,
          label: uiTranslations.AUTOCOMPLETE_VALUE_LABEL,
          defaultValue: keyValue?.value,
          autoCompleteOptions,
          required: valueRequired,
          fontFamily: 'source-code',
          onKeyDown: handleValueKeyDown,
          onBlur: handleValueBlur,
          onMouseDown: () => {
            setInputClicked('value');
          },
          focusOnInputState: [focusOnValue, setFocusOnValue],
        },
      }
    : null;

  const valueInputField: InputDefinition = {
    type: 'input',
    props: {
      hideLabel,
      name: valueName,
      label: uiTranslations.VALUE_LABEL,
      defaultValue: keyValue?.value,
      fontFamily: 'source-code',
      onKeyDown: handleValueKeyDown,
      onBlur: handleValueBlur,
      rows: 1,
      required: valueRequired,
      inputRef: valueInputRef,
      onMouseDown: (e) => {
        setInputClicked('value');
        e.stopPropagation();
      },
    },
  };

  return (
    <FormGenerator
      gridTemplate={
        gridTemplate ||
        css`
          grid-template-columns: 1fr 3fr ${showDescription && '3fr'} ${showToggle && '1fr'} ${submitButton &&
            'auto'};
        `
      }
      hideLabels={hideLabel}
      size={size}
      fields={[
        {
          type: 'input',
          props: {
            name: keyName,
            label: uiTranslations.KEY_LABEL,
            labelExtensionText:
              keyExtension !== undefined
                ? keyExtension
                : uiTranslations.AUTOCOMPLETE_KEY_PLACEHOLDER,
            readonly: Boolean(keyReadOnly),
            required: true,
            onMouseDown: (e) => {
              e.stopPropagation();
              setInputClicked('key');
              setFocusOnValue(false);
            },
            onBlur: handleKeyBlur,
            onKeyDown: handleKeyKeyDown,
            standardValidation: [{ type: 'key' }, { type: 'existingId', ids: existingKeys || [] }],
            defaultValue: keyValue?.key,
            inputRef: keyInputRef,
          },
        },
        autoCompleteField || valueInputField,
        {
          type: 'input',
          props: {
            name: descriptionName,
            label: uiTranslations.DESCRIPTION,
            defaultValue: keyValue?.description,
            noBorderRadius: 'left',
          },
          hide: !showDescription,
        },
        {
          type: 'toggle',
          props: {
            name: isSecretName,
            label: uiTranslations.MAKE_IT_SECRET,
          },
          hide: !showToggle,
        },
      ]}
      onSubmit={submitButton ? save : undefined}
      submitButton={
        submitButton
          ? {
              children: 'Create',
            }
          : undefined
      }
    />
  );
};

export default KeyValueForm;
