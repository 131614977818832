import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { ArtefactVersion } from '@src/models/artefact';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { artefactQueryKeys } from '../artefactsQueryKeys';

export const artefactVersionsQueryOptions = (
  orgId: string,
  artefactId: string,
  archived: boolean,
  enabled: boolean
) => ({
  queryKey: artefactQueryKeys.listArtefactVersions(orgId, artefactId, { archived }),
  queryFn: () =>
    makeRequest<ArtefactVersion[]>(
      'GET',
      `/orgs/${orgId}/artefacts/${artefactId}/versions?archived=${Boolean(archived)}`
    ),
  enabled: Boolean(orgId && artefactId) && enabled,
});

const useArtefactVersionsQuery = (
  artefactId: string,
  archived: boolean = false,
  enabled = true
): QueryResponse<ArtefactVersion[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery(
    artefactVersionsQueryOptions(orgId, artefactId, archived, enabled)
  );
  return { ...queryResult, data: data?.data };
};

export default useArtefactVersionsQuery;
