import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

const VerifyEmail = () => {
  const { confirmationEmailHash } = useParams<keyof MatchParams>() as MatchParams;
  const navigate = useNavigate();

  useEffect(() => {
    makeRequest('GET', `/users/verify/${confirmationEmailHash}`);
    navigate('/');
  }, [confirmationEmailHash, navigate]);

  return <></>;
};

export default VerifyEmail;
