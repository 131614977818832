import { Spinner } from '@humanitec/ui-components';

import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import { WorkloadFeatureDefinition } from '@src/models/workload-profile-v2';

import ContainerLog from './features/ContainerLog/ContainerLog';
import Replicas from './features/Replicas/Replicas';

interface HumanitecRuntimeFeatureProps {
  feature: WorkloadFeatureDefinition;
  featureKey: string;
}

const HumanitecRuntimeFeature = ({ feature, featureKey }: HumanitecRuntimeFeatureProps) => {
  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery();
  const { workloadController } = environmentRuntimeData || {};

  if (feature.feature_name === 'humanitec/logs') {
    return <ContainerLog containerId={featureKey} />;
  } else if (feature.feature_name === 'humanitec/replicas') {
    return !workloadController ? <Spinner /> : <Replicas />;
  }

  return <></>;
};

export default HumanitecRuntimeFeature;
