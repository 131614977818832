export const screenSizes = {
  mobile: {
    min: '320px',
    max: '768px',
  },
  desktop: {
    min: '769px',
    max: '100vw',
  },
};

/**
 * Generic definition of breakpoints for media queries in styled components.
 *
 * ```css
 *   {
 *     '@media ${breakpoints.mobile} {
 *     font-size: 14px;
 *   }
 * ```
 */
export const breakpoints = {
  mobile: `(min-width: ${screenSizes.mobile.min}) and (max-width: ${screenSizes.mobile.max})`,
  desktop: `(min-width: ${screenSizes.desktop.min}) and (max-width: ${screenSizes.desktop.max})`,
};

export const viewAppScreenSizes = {
  sm: {
    min: '320px',
    max: '768px',
  },
  md: {
    min: '769px',
    max: '1000px',
  },
  lg: {
    min: '1001px',
    max: '100vw',
  },
};

// Page specific definitions.
export const viewAppBreakpoints = {
  only: {
    sm: `(min-width: ${viewAppScreenSizes.sm.min}) and (max-width: ${viewAppScreenSizes.sm.max})`,
    md: `(min-width: ${viewAppScreenSizes.md.min}) and (max-width: ${viewAppScreenSizes.md.max})`,
    lg: `(min-width: ${viewAppScreenSizes.lg.min}) and (max-width: ${viewAppScreenSizes.lg.max})`,
  },
  greaterThanIncluding: {
    sm: `(min-width: ${viewAppScreenSizes.sm.min})`,
    md: `(min-width: ${viewAppScreenSizes.md.min})`,
    lg: `(min-width: ${viewAppScreenSizes.lg.min})`,
  },
  smallerThanIncluding: {
    sm: `(max-width: ${viewAppScreenSizes.sm.max})`,
    md: `(max-width: ${viewAppScreenSizes.md.max})`,
    lg: `(max-width: ${viewAppScreenSizes.lg.max})`,
  },
};
