import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { EnvironmentRuntime } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { sortReplicaSetsBySeverity } from '@src/utilities/runtime-utils';

import { environmentQueryKeys } from '../environmentQueryKeys';

const useEnvironmentRuntimeQuery = (
  enabled: boolean = true,
  customParams?: {
    appId: string;
    envId: string;
  }
) => {
  const {
    orgId,
    appId: routerAppId,
    envId: routerEnvId,
    moduleId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const appId = customParams?.appId || routerAppId;
  const envId = customParams?.envId || routerEnvId;

  // Context
  const { onRunningDeployment } = useDeploymentOrDeltaContext();

  return useQuery({
    queryKey: environmentQueryKeys.environmentRuntimeDetail(orgId, appId, envId),
    queryFn: () =>
      makeRequest<EnvironmentRuntime>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/runtime`,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'application/v2+json'
      ),
    select: ({ data }) => {
      const workloadRuntimes = data?.modules ? data?.modules[moduleId] : undefined;
      const workloadController = sortReplicaSetsBySeverity(
        Object.values(workloadRuntimes?.controllers || {})
      )[0];

      return {
        environmentRuntime: data,
        workloadController: onRunningDeployment ? workloadController : undefined,
      };
    },
    enabled: Boolean(orgId && appId && envId && enabled),
  });
};

export default useEnvironmentRuntimeQuery;
