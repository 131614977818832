import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentTypesQueryKeys } from '../environmentTypesQueryKeys';

const useEnvironmentTypesQuery = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentTypesQueryKeys.listEnvironmentTypes(orgId),
    queryFn: () => makeRequest<EnvironmentType[]>('GET', `/orgs/${orgId}/env-types`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useEnvironmentTypesQuery;
