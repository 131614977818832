import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../../environments/environmentQueryKeys';

interface UseReplicaUpdateMutationProps {
  onSuccess: () => void;
}

const useReplicaUpdateMutation = ({ onSuccess }: UseReplicaUpdateMutationProps) => {
  const queryClient = useQueryClient();
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError<ApiErrorResponse>,
    {
      [key: string]: number;
    }
  >({
    mutationFn: (payload) => {
      return makeRequest(
        'PATCH',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/runtime/replicas`,
        payload
      );
    },
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentRuntimeDetail(orgId, appId, envId),
      });
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useReplicaUpdateMutation;
