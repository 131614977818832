import styled from 'styled-components/macro';

const LoadingAnimationContainer = styled.svg`
  position: absolute;
  top: 0;
  z-index: 999;
`;

const LoadingAnimation = styled.rect`
  animation-name: myAnim;
  animation-duration: 4600ms;
  animation-timing-function: cubic-bezier(0.45, 0.19, 0.67, 0.89);
  animation-iteration-count: infinite;
  @keyframes myAnim {
    0% {
      width: 0;
    }
    25% {
      width: 100vw;
      transform: translate(0vw);
    }
    100% {
      width: 0vw;
      transform: translate(100vw);
    }
  }
`;

const LoadingBar = () => (
  <LoadingAnimationContainer width={'100%'} height={'2'} xmlns={'http://www.w3.org/2000/svg'}>
    <LoadingAnimation width={'100%'} height={'2'} fill={'#0271DE'} data-testid={'loading-bar'} />
  </LoadingAnimationContainer>
);

export default LoadingBar;
