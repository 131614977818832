import { ReactNode } from 'react';
import styled, { css, TextColorPalette } from 'styled-components';

import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

type ColorType = keyof TextColorPalette;

export interface TextProps {
  size?: 'xl' | 'lg' | 'base' | 'sm';
  weight?:
    | 'normal'
    | 'bold'
    | 'bolder'
    | 'lighter'
    | 100
    | 200
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800
    | 900;
  color?: ColorType & string;
  className?: string;
  /**
   * max width for the text, if the text surpasses this width ellipsis will be used
   */
  maxWidthEllipsis?: number;
  children?: ReactNode;
}

const Wrapper = styled.span<TextProps>`
  display: inline-block;
  ${({ size, weight, color, theme, maxWidthEllipsis }) => {
    return css`
      ${size ? `font-size: ${units.fontSize[size || 'base']}` : ''};
      ${weight ? `font-weight:${weight}` : ''};
      ${color ? `color:${theme.color[color]}` : ''};
      ${maxWidthEllipsis ? ellipsisStyle(maxWidthEllipsis) : ''};
    `;
  }}
`;

export const Text = ({ children, ...props }: TextProps) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};
