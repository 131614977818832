import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

export const getEnvValuesHistoryPurgeURL = (
  orgId: string | undefined,
  appId: string | undefined,
  envId: string | undefined,
  purgeChangeId: string,
  keyValue: string
) =>
  `/orgs/${orgId}/apps/${appId}/envs/${envId}/value-set-versions/${purgeChangeId}/purge/${keyValue}`;
export const getSharedAppValuesHistoryPurgeURL = (
  orgId: string | undefined,
  appId: string | undefined,
  purgeChangeId: string,
  keyValue: string
) => `/orgs/${orgId}/apps/${appId}/value-set-versions/${purgeChangeId}/purge/${keyValue}`;

const usePurgeVersionHistoryMutation = (
  keyValue: string,
  onSuccess?: () => void,
  onError?: (err: AxiosError<{ message: string }>) => void
) => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      comment,
      purgeChangeId,
      isEnvironmentOverride,
    }: {
      comment: string;
      purgeChangeId: string;
      isEnvironmentOverride: boolean;
    }) =>
      makeRequest(
        'POST',
        isEnvironmentOverride
          ? getEnvValuesHistoryPurgeURL(orgId, appId, envId, purgeChangeId, keyValue)
          : getSharedAppValuesHistoryPurgeURL(orgId, appId, purgeChangeId, keyValue),
        { comment }
      ),
    onSuccess: (_, { isEnvironmentOverride }) => {
      queryClient.invalidateQueries({
        queryKey: isEnvironmentOverride
          ? sharedValuesQueryKeys.envValuesList(orgId, appId, envId)
          : sharedValuesQueryKeys.appValuesList(orgId, appId),
      });
      onSuccess?.();
    },
    onError,
  });

  return mutation;
};

export default usePurgeVersionHistoryMutation;
