import { Spinner } from '@humanitec/ui-components';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useActiveResourcesQuery from '@src/hooks/react-query/resources/queries/useActiveResourcesQuery';
import { ResourceCriteria, ResourceDefinition } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { findMostSpecficMatchingCriteria } from '@src/utilities/find-most-specific-matching-criteria/find-most-specific-matching-criteria';

interface MapProps<T> {
  [key: string]: T;
}

interface GetInactivityStatusProps {
  criteria: ResourceCriteria;
  resource: ResourceDefinition | undefined;
}

const Status = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-top: ${units.margin.md};
`;

const LoadingText = styled.span`
  margin-left: ${units.margin.sm};
`;

const GetInactivityStatus = ({ criteria, resource }: GetInactivityStatusProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const resourceTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  // React Query
  const { data: activeResources, isLoading } = useActiveResourcesQuery();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();
  const { data: applications = [] } = useApplicationsQuery();

  // Generate map of all criteria that are active
  const allActiveCriteriaMap = useMemo(() => {
    const activeCriteriaMap: MapProps<boolean> = {};
    if (resource?.criteria) {
      activeResources?.forEach((activeResource) => {
        if (resource?.criteria) {
          const activeCriteria = findMostSpecficMatchingCriteria(activeResource, resource.criteria);
          if (activeCriteria) activeCriteriaMap[activeCriteria.id] = true;
        }
      });
    }
    return activeCriteriaMap;
  }, [activeResources, resource?.criteria]);

  // Generate map of existing environment-types
  const environmentTypesMap = useMemo(() => {
    const itemsMap = envTypes.reduce((map: MapProps<string>, environmentType) => {
      map[environmentType.id] = environmentType?.description;
      return map;
    }, {});

    return itemsMap;
  }, [envTypes]);

  // Generate map of existing applications
  const applicationsMap = useMemo(() => {
    const itemsMap = applications.reduce((map: MapProps<string>, application) => {
      map[application.id] = application.name;
      return map;
    }, {});

    return itemsMap;
  }, [applications]);

  // Generate map of existing environments per application.
  const environmentsMap = useMemo(() => {
    const itemsMap = uniqBy(
      applications.flatMap((a) => a.envs),
      'id'
    ).reduce((map: MapProps<string>, environment) => {
      map[environment.id] = environment.name;
      return map;
    }, {});

    return itemsMap;
  }, [applications]);

  const inactivityStatus = useMemo(() => {
    /*
     * The inactivity status can be one of the following:
     * 1. "" : This is returned in scenarios where the criteria is most specific match or in scenarios where the criteria only has a res_id and no other properties.
     * 2. "Inactive - These matching criteria are currently not matched to a resource": This is returned in scenarios where the criteria exists but isn't the most specific match.
     * 3. "Inactive - ${matching-criteria-type} does not exist": This is returned when the ${matching-criteria-type} does match any existing application, environment-type or enviroment. Multiple matching criteria types can be non-existent, in that case the status text would be  "Inactive - ${matching-criteria-type}, ${matching-criteria-type} and ${matching-criteria-type} do not exist"
     *
     * Hint: ${matching-criteria-type} represents one of these three words: "Environment Type", "Application" or "Environment"
     */

    if (activeResources !== undefined) {
      // Hash map of properties with their related maps
      const criteriaTypeMaps = {
        env_type: environmentTypesMap,
        app_id: applicationsMap,
        env_id: environmentsMap,
      };

      // Mapping for API property names against display names
      const criteriaNamesMapping = {
        env_type: 'Environment Type',
        app_id: 'Application',
        env_id: 'Environment',
      };

      // Returns case 1 if the criteria exists in the map of all active criteria
      if (allActiveCriteriaMap[criteria.id as keyof typeof allActiveCriteriaMap]) {
        return '';
      } else {
        // Get all properties except those with "id" and "resource" keys
        const criteriaProperties = Object.entries(criteria).filter(
          ([key, _]) => key === 'env_type' || key === 'app_id' || key === 'env_id'
        );

        // Returns case 1 if the res_id is the only property on the criteria
        if (criteriaProperties.length === 0 && !!criteria.res_id) {
          return '';
        }

        const title = `${uiTranslations.INACTIVE} - `;
        const nonExistentCriterias: string[] = [];
        // checks if each criteria property exists in it's asssociated hash map.
        criteriaProperties.forEach(([key, value]) => {
          const mapAssociatedWithCriteriaType =
            criteriaTypeMaps[key as keyof typeof criteriaTypeMaps];
          if (!mapAssociatedWithCriteriaType[value]) {
            nonExistentCriterias.push(key);
          }
        });

        // Returns case 2, since all properties exist but it isn't the most specific */
        if (!nonExistentCriterias.length) {
          return `${title} ${resourceTranslations.MATCHING_CRITERIA_NOT_CURRENTLY_MATCHED}`;
        }

        // Returns case 3, when a single matching criteria doesn't exist
        if (nonExistentCriterias.length === 1) {
          return `${title} ${
            criteriaNamesMapping[nonExistentCriterias[0] as keyof typeof criteriaNamesMapping]
          } ${resourceTranslations.DOES_NOT_EXIST}`;
        }

        // Returns case 3, when a multiple matching criteria doesn't exist
        if (nonExistentCriterias.length > 1) {
          let statusText = '';
          const getPrefix = (index: number, endIndexOfArray: number) => {
            return index === endIndexOfArray ? ` ${resourceTranslations.AND}` : '';
          };

          const getSuffix = (index: number, endIndexOfArray: number) => {
            return index === endIndexOfArray - 1 || index === endIndexOfArray ? '' : ',';
          };

          nonExistentCriterias.map((prop, index) => {
            return (statusText += `${getPrefix(index, nonExistentCriterias.length - 1)} ${
              criteriaNamesMapping[prop as keyof typeof criteriaNamesMapping]
            }${getSuffix(index, nonExistentCriterias.length - 1)}`);
          });

          return `${title} ${statusText} ${resourceTranslations.DO_NOT_EXIST}`;
        }
      }
    }
  }, [
    activeResources,
    allActiveCriteriaMap,
    applicationsMap,
    environmentTypesMap,
    environmentsMap,
    criteria,
    resourceTranslations.AND,
    resourceTranslations.DOES_NOT_EXIST,
    resourceTranslations.DO_NOT_EXIST,
    resourceTranslations.MATCHING_CRITERIA_NOT_CURRENTLY_MATCHED,
    uiTranslations.INACTIVE,
  ]);

  if (isLoading) {
    return (
      <Status>
        <Spinner size={'small'} />
        <LoadingText>{resourceTranslations.CHECKING_INACTIVITY_STATUS}</LoadingText>
      </Status>
    );
  }

  return !!inactivityStatus ? <Status>{inactivityStatus}</Status> : <span />;
};

export default GetInactivityStatus;
