import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDeploymentDeltaMetadataMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaMetadataMutation';
import { DeploymentDelta } from '@src/models/deployment-delta';

import ConfirmDeleteModal from './ConfirmDeleteModal/ConfirmDeleteModal';

interface DiscardDraftModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  delta: DeploymentDelta;
}

const DiscardDraftModal = ({ openState, delta }: DiscardDraftModalProps) => {
  // i18n
  const { t } = useTranslation();
  const { t: tEnvironment } = useTranslation('viewEnvironment');
  const uiTranslations = t('UI');
  const draftsTranslations = tEnvironment('DRAFTS');

  // Component state
  const [open, setOpen] = openState;

  // React Query

  const {
    mutate: updateDeploymentDeltaMetadata,
    isSuccess: isDiscardedSuccessfully,
    reset: resetDiscardMutation,
  } = useDeploymentDeltaMetadataMutation();

  const discardDraftChanges = () => {
    if (delta) {
      updateDeploymentDeltaMetadata({
        deltaId: delta.id,
        metadata: 'archived',
        value: true,
      });
    }
  };

  useEffect(() => {
    if (isDiscardedSuccessfully) {
      setOpen(false);
      resetDiscardMutation();
    }
  }, [isDiscardedSuccessfully, resetDiscardMutation, setOpen]);

  return (
    <ConfirmDeleteModal
      deleteConfirmedCallback={discardDraftChanges}
      state={[open, setOpen]}
      confirmMessage={draftsTranslations.CONFIRM_DISCARD_DRAFT}
      deleteButtonText={uiTranslations.DISCARD}
      title={draftsTranslations.DISCARD_DRAFT}
    />
  );
};

export default DiscardDraftModal;
