import { Context, useCallback, useContext, useEffect } from 'react';

import { WebSocketMessageEvent } from '@src/components/socket/web-socket-message';

/**
 * custom hook to handle websocket events related to the current app from any component.
 *
 * @param appId - the current application id
 * @param context - the websocket context to listen to
 * @param handleMessageDataCallback - callback function to handle the event
 */
export const useHandleSocketMessage = (
  appId: string,
  context: Context<WebSocket | undefined>,
  handleMessageCallback: (
    // the parsed message response
    message: MessageEvent<Record<string, string>>,
    // the websocket object
    socket?: WebSocket
  ) => void
) => {
  const socket = useContext(context);

  const handleMessage = useCallback((message: WebSocketMessageEvent) => {
    const parsedMessage = JSON.parse(message.data);
    // handle messages related to the current application
    if (parsedMessage.data.application_id === appId) {
      handleMessageCallback(parsedMessage, socket);
    }
    // TODO replace useCallback with useEvent when it gets released https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket?.addEventListener('message', handleMessage);
    return () => {
      socket?.removeEventListener('message', handleMessage);
    };
  }, [handleMessage, socket]);
};
