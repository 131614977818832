import { SearchInput, WalCard } from '@humanitec/ui-components';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

export const SectionTopBarWrapper = styled(WalCard)`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${units.margin.lg};
  justify-items: start;
  margin: ${units.margin.sm} 0;
`;

export const SectionTopBarTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${units.margin.xl};
  font-size: ${units.fontSize.lg};
`;

export const SectionTopBarSearchField = styled(SearchInput)`
  align-self: flex-end;
`;
