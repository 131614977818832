export const rulesQueryKeys = {
  list: (orgId: string | undefined, appId: string | undefined, envId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'envs',
    envId,
    'rules',
    'list',
  ],
};
