import { get } from 'lodash';
import { useMemo } from 'react';

import { findSpecificFeatureByName } from '@src/components/shared/ViewWorkloadProfile/utils/utils';
import { DeploymentSetModuleResource, Workload } from '@src/models/deployment-set';
import { convertPathToArraySegments } from '@src/utilities/deployment-delta-utils';
import { mapResourceEntry } from '@src/utilities/resource-utils';

import useResourceTypesQuery from '../react-query/resources/queries/useResourceTypesQuery';
import useWorkloadProfileLatestQuery from '../react-query/workload-profiles/queries/useWorkloadProfileLatestQuery';
import { useGetDeploymentSetWithDeltaUpdates } from '../useGetDeploymentSetWithDeltaUpdates';

/**
 * This hook returns the resource dependencies of the workload.
 * This merges the data from `/externals` and `/spec/volumes`
 */
export const useGetWorkloadResourceData = (workloadPath: string) => {
  // Hooks
  const deploymentSetWithDeltaUpdates = useGetDeploymentSetWithDeltaUpdates();

  const workload = get(deploymentSetWithDeltaUpdates, convertPathToArraySegments(workloadPath)) as
    | Workload
    | undefined;

  // React Query
  const { data: workloadProfile } = useWorkloadProfileLatestQuery(workload?.profile);
  const { data: resourceTypes } = useResourceTypesQuery();

  const firstVolumesFeaturePath = findSpecificFeatureByName(
    workloadProfile?.spec_definition?.properties,
    'humanitec/volumes'
  )?.path;

  const volumesSpecPath = `spec/${firstVolumesFeaturePath}`;

  const volumesFullPath = `${workloadPath}/${volumesSpecPath}`;

  const resourceDependencyVolumes = get(
    deploymentSetWithDeltaUpdates,
    convertPathToArraySegments(volumesFullPath)
  ) as Record<string, DeploymentSetModuleResource> | undefined;

  return {
    volumesFeaturePath: volumesSpecPath,
    resourceDependencies: useMemo(
      () =>
        resourceTypes
          ? [
              ...Object.entries(workload?.externals || {}),
              ...Object.entries(resourceDependencyVolumes || {}),
            ].map((entry) => mapResourceEntry(entry, 'externals', resourceTypes, volumesSpecPath))
          : [],
      [resourceDependencyVolumes, resourceTypes, workload?.externals, volumesSpecPath]
    ),
  };
};
