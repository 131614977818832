export type SyntaxHighlightingMode =
  | 'yaml'
  | 'javascript'
  | 'python'
  | 'go'
  | 'java'
  | 'json'
  | 'php'
  | 'text';

/**
 * Extensions extracted from monaco.languages object. Monaco officially supports these extensions with appropriate language
 */
export const LANGUAGE_EXTENSIONS_MAP = [
  {
    language: 'json',
    extensions: ['json', 'bowerrc', 'jshintrc', 'jscsrc', 'eslintrc', 'babelrc', 'har'],
  },
  { language: 'javascript', extensions: ['js', 'es6', 'jsx', 'mjs', 'cjs'] },
  { language: 'yaml', extensions: ['yaml', 'yml'] },
  { language: 'go', extensions: ['go'] },
  { language: 'python', extensions: ['py', 'rpy', 'pyw', 'cpy', 'gyp', 'gypi'] },
  { language: 'java', extensions: ['java', 'jav'] },
  { language: 'php', extensions: ['php', 'php4', 'php5', 'phtml', 'ctp'] },
  { language: 'text', extensions: ['text', 'txt'] },
];

export const syntaxHighlightingItems: {
  label: string;
  value: SyntaxHighlightingMode;
  id: SyntaxHighlightingMode;
}[] = [
  { label: 'Text', value: 'text', id: 'text' },
  {
    label: 'JSON',
    value: 'json',
    id: 'json',
  },
  { label: 'YAML', value: 'yaml', id: 'yaml' },
  {
    label: 'Python',
    value: 'python',
    id: 'python',
  },
  { label: 'GoLang', value: 'go', id: 'go' },
  {
    label: 'Javascript',
    value: 'javascript',
    id: 'javascript',
  },
  { label: 'Java', value: 'java', id: 'java' },
  {
    label: 'Php',
    value: 'php',
    id: 'php',
  },
];

/*
 * These file extensions are valid extensions to get autocomplete in files editor.
 * 'files' is included in place of 'text'
 */
export const FILES_EXTENSIONS = LANGUAGE_EXTENSIONS_MAP.map(({ language }) =>
  language === 'text' ? 'files' : language
);
