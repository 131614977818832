import DownArrowIcon from '@humanitec/ui-components/src/assets/svg/arrow.svg?react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const RightArrowIcon = styled(DownArrowIcon)<any>`
  top: 50%;
  transform: rotate(270deg) translate(-20%);
  right: ${rem(8)};
  fill: ${({ theme, fill }) => (fill ? theme.color[fill] : theme.color.textTranslucent)};

  ${({ size }) => {
    let svgSize;
    switch (size) {
      case 'large':
        svgSize = rem(20);
        break;
      case 'small':
        svgSize = rem(12);
        break;
      case 'medium':
      default:
        svgSize = rem(16);
    }
    return css`
      height: ${svgSize};
      width: ${svgSize};
    `;
  }};
`;

const RightArrowSVG = ({
  size,
  className,
  fill,
}: {
  size?: 'small' | 'medium' | 'large';
  className?: string;
  fill?: string;
}) => {
  return (
    <RightArrowIcon
      className={className}
      xmlns={'http://www.w3.org/2000/svg'}
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      size={size}
      fill={fill}
    />
  );
};

export default RightArrowSVG;
