import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Freetrial from '@src/components/shared/Freetrial';
import { useCurrentUserStore } from '@src/hooks/zustand/useCurrentUserStore';
import { units } from '@src/styles/variables';

const Wrapper = styled.div`
  text-align: center;
`;

const Header = styled.div`
  font-size: ${units.fontSize.xl};
  margin-bottom: ${units.margin.lg};
  font-weight: bold;
`;

const Info = styled.span`
  font-size: ${units.fontSize.base};
`;

export const AuthError = () => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const { authErrorMessage: authenticateFailedReason } = useCurrentUserStore();

  return (
    <Wrapper>
      <Header>
        {authenticateFailedReason === 'github-not-exist'
          ? authTranslations.ACCOUNT_DOES_NOT_EXIST
          : authenticateFailedReason === 'bad-request' && authTranslations.INVALID_GITHUB_CODE}
      </Header>
      <Info>
        <Trans
          defaults={
            authenticateFailedReason === 'github-not-exist'
              ? authTranslations.HUMANITEC_ACCOUNT_DOES_NOT_EXIST
              : (authenticateFailedReason === 'bad-request' &&
                  authTranslations.GITHUB_CODE_INVALID) ||
                ''
          }>
          <Link className={'txt-sm'} to={'/auth/login'} />
          <Freetrial />
        </Trans>
      </Info>
    </Wrapper>
  );
};
