import { WalCard } from '@humanitec/ui-components';

import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';

interface CollectionFeatureProps {
  deltaPath: string;
  featureKey: string;
}

const CollectionFeature = ({ deltaPath, featureKey }: CollectionFeatureProps) => {
  const { data } = useDeltaUtils(deltaPath);

  const collectionIds = Object.keys(data || {});

  return (
    <>
      {collectionIds.map((collectionId) => {
        const to = `${featureKey}/${collectionId}`;

        return (
          <WalCard link={{ to }} key={collectionId}>
            {collectionId}
          </WalCard>
        );
      })}
    </>
  );
};

export default CollectionFeature;
