import { merge } from 'lodash';

import { units } from './variables';

type FontSizes = keyof typeof units.fontSize;
export const fontStylesMap = Object.keys(units.fontSize)
  .map((size) => ({
    ['txt-' + size]: 'font-size: ' + units.fontSize[size as keyof typeof units.fontSize],
  }))
  .reduce(merge);
export type FontClassnames = `txt-${FontSizes}`;
