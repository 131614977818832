import { create } from 'zustand';

type RolesStore = {
  lastCreatedServiceUsersIds: string[];
  lastCreatedApiTokensIds: string[];
  pushIdToLastCreatedServiceUsersIds: (id: string) => void;
  pushIdToLastCreatedApiTokensIds: (id: string) => void;
};

export const useRolesStore = create<RolesStore>((set) => ({
  lastCreatedServiceUsersIds: [],
  lastCreatedApiTokensIds: [],
  pushIdToLastCreatedServiceUsersIds: (id: string) =>
    set((state) => ({
      lastCreatedServiceUsersIds: [...state.lastCreatedServiceUsersIds, id],
    })),
  pushIdToLastCreatedApiTokensIds: (id: string) =>
    set((state) => ({
      lastCreatedApiTokensIds: [...state.lastCreatedApiTokensIds, id],
    })),
}));
