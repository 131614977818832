import { useEffect, useState } from 'react';

/**
 * Disposers are cleanup functions. After the editor is closed, we want to destroy all the settings
 * enabled for the editor based on current requirements. If not disposed, those settings are retained when a new editor
 * is opened and it might result in buggy behavior. Ofcourse if two editors are opened at the same time, then both the
 * settings are added one over other. This is something we should watch closely
 */
const useEditorDisposers = () => {
  /** Disposer to clean focus listener*/
  const [focusDisposer, setFocusDisposer] = useState<{ dispose: () => void } | undefined>(
    undefined
  );
  /** Disposer to clean blur listener */
  const [blurDisposer, setBlurDisposer] = useState<{ dispose: () => void } | undefined>(undefined);
  /** Disposer to  clean keydown event listener*/
  const [onKeyDownHandlerDisposer, setOnKeyDownHandlerDisposer] = useState<
    { dispose: () => void } | undefined
  >(undefined);
  /** Disposer to  clean keyUp event listener*/
  const [onKeyUpDisposer, setOnKeyUpDisposer] = useState<{ dispose: () => void } | undefined>(
    undefined
  );

  /**
   * Clean up disposer values stored in monaco
   */
  useEffect(() => () => focusDisposer?.dispose(), [focusDisposer]);
  useEffect(() => () => onKeyUpDisposer?.dispose(), [onKeyUpDisposer]);
  useEffect(() => () => blurDisposer?.dispose(), [blurDisposer]);
  useEffect(() => () => onKeyDownHandlerDisposer?.dispose(), [onKeyDownHandlerDisposer]);

  return {
    setFocusDisposer,
    setBlurDisposer,
    setOnKeyUpDisposer,
    setOnKeyDownHandlerDisposer,
  };
};

export default useEditorDisposers;
