import { ActionButtons, AddButtonForm, WalTable, WalTableColumn } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { ContainerFile } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { decodePathKey } from '@src/utilities/deployment-delta-utils';

import FilesViewAddEdit, { FilesViewAddEditProps } from './FilesViewAddEdit/FilesViewAddEdit';

const ActionButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const AddFilesFormWrapper = styled.div`
  margin-top: ${units.margin.md};
  margin-bottom: ${units.margin.md};
`;

const EmptyStateWrapper = styled.div`
  margin-top: ${units.margin.md};
`;

export const extractFileExtension = (path: string | undefined): string | undefined => {
  if (!path) {
    return undefined;
  }
  const extMatch = path.match(/\.(\w+)/);
  if (extMatch) {
    return extMatch[1];
  }
};

export interface FileItem {
  path: string;
  mode: string;
  value?: string;
}

interface FilesProps {
  deltaPath: string;
}

const Files = ({ deltaPath }: FilesProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const filesTranslations = t('VIEW_MODULE').FILES;
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [rowToOverrideColumns, setRowToOverrideColumns] = useState<number | undefined>();
  const [showAddModeFormContent, setShowAddModeFormContent] = useState<boolean | undefined>();

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { updateWorkload, data } = useDeltaUtils<Record<string, ContainerFile>>(deltaPath);

  // Zustand
  const { currentDeltaAction } = useDeploymentDeltasStore();

  const files: FileItem[] = Object.entries(data || {}).map(([filePath, file]) => ({
    path: decodePathKey(filePath),
    mode: file.mode,
    value: file.value,
  }));

  const removeFile = (key: string) => {
    updateWorkload([
      {
        key,
        op: 'remove',
      },
    ]);
  };

  const updateFileInDeploymentDelta = (file: FileItem, fileBeforeEdit?: FileItem | undefined) => {
    const pathsMatch = fileBeforeEdit?.path === file.path;
    if (pathsMatch || !fileBeforeEdit) {
      updateWorkload(
        [
          {
            key: file.path,
            op: pathsMatch ? 'replace' : 'add',
            value: { value: file.value, mode: file.mode },
          },
        ],
        { deltaAction: 'add-files' }
      );
    } else {
      // if the file path has changed, remove the old file and create a new one.
      updateWorkload([
        {
          key: fileBeforeEdit.path,
          op: 'remove',
        },
        {
          key: file.path,
          value: { value: file.value, mode: file.mode },
          op: 'add',
        },
      ]);
    }
  };

  const handleEdit = (index: number) => {
    setExpandedRow(index);
    setRowToOverrideColumns(index);
  };

  const handleCancelEditMode = () => {
    setExpandedRow(undefined);
    setRowToOverrideColumns(undefined);
  };

  const handleCancelAddMode = () => {
    setShowAddModeFormContent(false);
  };

  const columns: WalTableColumn<FileItem>[] = [
    {
      label: filesTranslations.PATH,
      prop: 'path',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 25,
        text: (row) => row.data.path,
      },
    },
    {
      label: filesTranslations.MODE,
      prop: 'mode',
      template: (row) => <p>{row.data.mode || <StyledDash>-</StyledDash>}</p>,
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        if (draftModeActive) {
          return (
            <ActionButtonWrapper>
              <ActionButtons
                buttons={['edit', 'delete']}
                onEdit={() => handleEdit(files.indexOf(row.data))}
                onDelete={() => removeFile(row.data.path)}
              />
            </ActionButtonWrapper>
          );
        }
      },
    },
  ];

  const renderFilesViewAddEdit = ({
    onSave,
    onCancel,
    mode,
    isSaving,
    fileToEdit: file,
    existingFiles,
  }: FilesViewAddEditProps) => (
    <FilesViewAddEdit
      existingFiles={existingFiles}
      key={file?.path}
      onSave={onSave}
      onCancel={onCancel}
      mode={mode}
      isSaving={isSaving}
      fileToEdit={file}
    />
  );

  const renderAddFilesSection = (
    <AddButtonForm
      showFormContentState={[showAddModeFormContent, setShowAddModeFormContent]}
      buttonText={uiTranslations.ADD_FILES}
      readonly={!draftModeActive}
      formContent={
        <>
          {renderFilesViewAddEdit({
            mode: 'add',
            existingFiles: files,
            onSave: (fileItem) => updateFileInDeploymentDelta(fileItem),
            onCancel: handleCancelAddMode,
            isSaving: currentDeltaAction === 'add-files',
          })}
        </>
      }
    />
  );

  return files.length === 0 && !draftModeActive ? (
    <EmptyStateWrapper>
      <span className={'txt-md'}>{sectionsTranslations.NO_FILES_DEFINED}</span>
    </EmptyStateWrapper>
  ) : (
    <>
      <AddFilesFormWrapper>{renderAddFilesSection}</AddFilesFormWrapper>
      <WalTable
        caption={sectionsTranslations.FILES}
        columns={columns}
        expandedRowState={[expandedRow, setExpandedRow]}
        disableScrolling
        tableStyle={'expandable'}
        tableRowStyle={'base'}
        rows={
          files
            ? files?.map((existingFile, rowIndex) => ({
                data: existingFile,
                expandableContent: renderFilesViewAddEdit({
                  fileToEdit: existingFile,
                  mode: 'view',
                  existingFiles: files,
                }),
                overrideColumns: rowToOverrideColumns === rowIndex,
                columnOverrideTemplate: renderFilesViewAddEdit({
                  fileToEdit: existingFile,
                  mode: 'edit',
                  existingFiles: files,
                  onSave: (newFile) => updateFileInDeploymentDelta(newFile, existingFile),
                  onCancel: handleCancelEditMode,
                }),
              }))
            : []
        }
      />
    </>
  );
};

export default Files;
