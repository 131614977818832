export const deploymentDeltaQueryKeys = {
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'deltas']
  all: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'deltas',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'deltas', 'list', { env: 'my-env' }]
  list: (
    orgId: string | undefined,
    appId: string | undefined,
    filters: { env: string | undefined }
  ) => [...deploymentDeltaQueryKeys.all(orgId, appId), 'list', { filters }],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'deltas', 'detail', 'my-delta']
  detail: (orgId: string | undefined, appId: string | undefined, deltaId: string | undefined) => [
    ...deploymentDeltaQueryKeys.all(orgId, appId),
    'detail',
    deltaId,
  ],
};
