import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DeleteApplicationButton from './components/DeleteApplicationButton/DeleteApplicationButton';

const SubHeading = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DeleteApplication = () => {
  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('DELETE_APPLICATION');

  return (
    <Wrapper>
      <span className={'txt-lg mb-sm'}>{translations.CANT_BE_UNDONE}</span>
      <SubHeading className={'txt-sm mb-md'}>{translations.CANT_RECOVER}</SubHeading>
      <DeleteApplicationButton />
    </Wrapper>
  );
};

export default DeleteApplication;
