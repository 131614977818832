import { create } from 'zustand';

type WebhooksStore = {
  lastCreatedWebhookIds: string[];
  pushIdToLastCreatedWebhookIds: (id: string) => void;
};

export const useWebhooksStore = create<WebhooksStore>((set) => ({
  lastCreatedWebhookIds: [],
  pushIdToLastCreatedWebhookIds: (id: string) =>
    set((state) => ({
      lastCreatedWebhookIds: [...state.lastCreatedWebhookIds, id],
    })),
}));
