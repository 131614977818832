import { datadogRum } from '@datadog/browser-rum';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

const useCancelPipelineRunsMutation = (onCancelled?: () => void) => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      pipelineId: string;
      runId: string;
      appId: string;
    }
  >({
    mutationFn: ({ pipelineId, runId, appId }) => {
      return makeRequest<PipelineRun[]>(
        'POST',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${runId}/cancel`,
        {}
      );
    },
    onSuccess: () => {
      onCancelled?.();
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });
};

export default useCancelPipelineRunsMutation;
