import DownArrowIcon from '@humanitec/ui-components/src/assets/svg/arrow.svg?react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const DropdownIcon = styled(DownArrowIcon)<DropdownSVGProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${rem(8)};
  fill: ${({ theme }) => theme.color.text};
  ${({ size }) => {
    let svgSize;
    switch (size) {
      case 'large':
        svgSize = rem(20);
        break;
      case 'small':
        svgSize = rem(12);
        break;
      case 'medium':
      default:
        svgSize = rem(16);
    }
    return css`
      height: ${svgSize};
      width: ${svgSize};
    `;
  }};
`;

interface DropdownSVGProps {
  size?: 'small' | 'medium' | 'large';
}

const DropdownSVG = ({ size }: DropdownSVGProps) => {
  return (
    <DropdownIcon
      xmlns={'http://www.w3.org/2000/svg'}
      width={'24'}
      height={'24'}
      className={'dropdown-arrow'}
      viewBox={'0 0 24 24'}
      size={size}
    />
  );
};

export default DropdownSVG;
