import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { AppValue } from '@src/models/app-value';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../../types';
import appValuesQueryKeys from './appValuesQueryKeys';

export const useAppValuesQuery = (): QueryResponse<AppValue[], AppValue[]> => {
  const { orgId, appId } = useParams();

  const URL = `/orgs/${orgId}/apps/${appId}/values`;

  const { data, ...queryResult } = useQuery({
    queryKey: appValuesQueryKeys.list(orgId, appId),
    queryFn: () => makeRequest<AppValue[]>('GET', URL),
    enabled: !!(orgId && appId),
  });

  return {
    ...queryResult,
    data: data?.data,
  };
};
export default useAppValuesQuery;
