import { AutoCompleteOptions, placeholderHasError } from '@humanitec/ui-components';
import styled from 'styled-components';

import { placeholderStyle } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';

const PlaceholderText = styled.span<{ hasError?: boolean }>`
  color: ${({ theme }) => theme.color.main};
  font-size: ${units.fontSize.sm};
  display: inline !important;
  word-break: break-all;
  ${({ hasError }) => placeholderStyle(hasError)};
  margin: 0;
  padding: 0;
`;

// Highlights text with placeholders
const GetHighlightedFieldValue = (text: string, autoCompleteOptions: AutoCompleteOptions) => {
  const value = text?.replace(/&lt;/g, '<');
  if (autoCompleteOptions && autoCompleteOptions.delimiterString) {
    const placeholderRegex = new RegExp(
      `\\${autoCompleteOptions.delimiterString.start}{1}.*?${autoCompleteOptions.delimiterString.end}{1}`,
      'g'
    );
    const fieldValueSegmentsBetweenPlaceholders = value?.split(placeholderRegex);

    if (fieldValueSegmentsBetweenPlaceholders?.length <= 1) {
      return value;
    }

    const placeholderMatches = value?.match(placeholderRegex);

    return fieldValueSegmentsBetweenPlaceholders?.reduce(
      (result: any[], segment: string, index: number) => {
        return placeholderMatches && placeholderMatches[index]
          ? [
              ...result,
              segment,
              <PlaceholderText
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                hasError={
                  autoCompleteOptions.delimiterString &&
                  placeholderHasError(
                    placeholderMatches[index],
                    autoCompleteOptions.lookupObject,
                    autoCompleteOptions.delimiterString
                  )
                }>
                {placeholderMatches[index]}
              </PlaceholderText>,
            ]
          : [...result, segment];
      },
      []
    );
  }
};

export default GetHighlightedFieldValue;
