import { rem } from 'polished';
import styled from 'styled-components';

export const WebhooksWrapperGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${rem(50)} 1fr;
  width: 100%;
`;

export const WebhookGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 1fr ${rem(200)} ${rem(70)};
  grid-column-gap: ${rem(10)};
  width: 100%;
  align-items: center;
`;
