export const workloadProfileQueryKeys = {
  // e.g. ['orgs', 'my-org', 'workload-profiles']
  all: (orgId: string | undefined) => ['orgs', orgId, 'workload-profiles'],
  // e.g. ['orgs', 'my-org', 'workload-profiles', 'list']
  list: (orgId: string | undefined) => [...workloadProfileQueryKeys.all(orgId), 'list'],
  // e.g. ['orgs', 'my-org', 'workload-profiles', 'latest, 'humanitec.default-module']
  latestVersion: (orgId: string | undefined, profileId: string | undefined) => [
    ...workloadProfileQueryKeys.all(orgId),
    'latest',
    profileId,
  ],
};
