import { create } from 'zustand';

import { DeploymentObject } from '@src/models/deployment-object';
import { generateEnvPath } from '@src/utilities/navigation';

type EnvironmentCloneStore = {
  environmentClone: Record<string, DeploymentObject>;
  updateEnvironmentClone: (
    appId: string,
    envId: string,
    value: Omit<DeploymentObject, 'id' | 'created_at'>
  ) => void;
  deleteEnvironmentClone: (appId: string, envId: string) => void;
  getEnvironmentClone: (appId: string, envId: string) => DeploymentObject | undefined;
};

export const useEnvironmentCloneStore = create<EnvironmentCloneStore>((set, get) => {
  return {
    environmentClone: {},
    getEnvironmentClone: (appId, envId) => get().environmentClone[generateEnvPath(appId, envId)],
    updateEnvironmentClone: (appId, envId, value) =>
      set((state) => ({
        ...state.environmentClone,
        [generateEnvPath(appId, envId)]: {
          id: 'clone',
          set_id: value.set_id,
          env_id: value.env_id,
          created_at: new Date().toString(),
        },
      })),
    deleteEnvironmentClone: (appId, envId) =>
      set((state) => {
        const newClone = { ...state.environmentClone };
        delete newClone[generateEnvPath(appId, envId)];
        return newClone;
      }),
  };
});
