export enum KeyBindings {
  ENTER = 13,
  ESCAPE = 27,
  SPACE = 32,
  TAB = 9,
  ARROWUP = 38,
  ARROWDOWN = 40,
  ARROWRIGHT = 39,
  ARROWLEFT = 37,
  BACKSPACE = 8,
}
