import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const NonStyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.text};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: ${({ theme }) => theme.color.text};
  }
  text-decoration: none;
`;

export const NonStyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.text};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: ${({ theme }) => theme.color.text};
  }
  text-decoration: none;
`;
