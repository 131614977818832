const envValuesQueryKeys = {
  all: (orgId: string | undefined, appId: string | undefined, envId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'env',
    envId,
    'values',
  ],
  list: (orgId: string | undefined, appId: string | undefined, envId: string | undefined) => [
    ...envValuesQueryKeys.all(orgId, appId, envId),
    'list',
  ],
};

export default envValuesQueryKeys;
