import React from 'react';

import { SkeletonItem, SkeletonItemProps } from '@src/components/shared/Skeleton/SkeletonItem';

interface SkeletonGroupProps {
  count: number;
  itemProps?: SkeletonItemProps;
}
const SkeletonGroup = ({ count, itemProps }: SkeletonGroupProps) => {
  return (
    <>
      {Array.from(Array(count).keys()).map((key) => (
        <SkeletonItem height={41.5} key={key} {...itemProps} className={'mb-sm'} />
      ))}
    </>
  );
};

export default SkeletonGroup;
