import { Icon, IconNames, Tooltip } from '@humanitec/ui-components';
import { rem } from 'polished';
import { HTMLAttributeAnchorTarget, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { NonStyledNavLink } from '@src/components/shared/NonStyledLink';
import { useWalhallContext } from '@src/context/walhallContext';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

const ListItem = styled.li<{ endOfGroup?: boolean; disablePointer?: boolean; active: boolean }>`
  width: 100%;
  ${({ endOfGroup }) =>
    endOfGroup &&
    css`
      margin-bottom: ${units.margin.lg};
    `};

  ${({ disablePointer }) =>
    !disablePointer &&
    css`
      cursor: pointer;
    `};

  
  &:hover {
    background-color: ${({ theme }) => theme.color.mainBrighterTransparent};
  }
     
  }
`;

const LinkItem = styled(NonStyledNavLink)`
  padding: ${units.padding.sm} ${units.padding.xl};
  display: flex;
  align-items: center;

  &.activeNav {
    color: ${({ theme }) => theme.color.mainBrighter};
  }
`;

const Item = styled.div`
  padding: ${units.padding.sm} ${units.padding.xl};
  display: flex;
  align-items: center;
`;

const ItemLabel = styled.span<{ labelWidth?: number }>`
  margin-left: ${units.margin.md};
  ${({ labelWidth }) =>
    labelWidth &&
    css`
      width: ${rem(labelWidth)};
      ${ellipsisStyle()}
    `}
`;

interface NavigationListItemProps {
  /* Label to display */
  label: string;
  /* Icon displyed on left of item */
  icon: IconNames;
  /* If the navigationbar is minimized. Hides label & enables tooltip */
  minimized: boolean;
  /* Label to be displayed if the navbar is minimized. 'label' is used if this is not passed */
  labelMinimized?: string;
  /* Url to navigate to on click */
  to?: string;
  /* Adds an extra margin-bottom */
  endOfGroup?: boolean;
  /* onClick handle for listiten */
  onClick?: () => void;
  /* max-width for the label element */
  labelWidth?: number;
  /* Adds a custom element ot the right of the label */
  customElementRight?: ReactElement;
  /* d */
  disablePointer?: boolean;
  ariaLabel?: string;
  target?: HTMLAttributeAnchorTarget;
}

const NavigationListItem = ({
  to,
  label,
  labelMinimized,
  minimized,
  icon,
  endOfGroup,
  onClick,
  labelWidth,
  customElementRight,
  disablePointer,
  ariaLabel,
  target,
}: NavigationListItemProps) => {
  const location = useLocation();
  const { navigateDialogState, nextRouteState, shouldConfirmOnNavigateState } = useWalhallContext();
  const [, setShowNavigateDialog] = navigateDialogState;
  const [shouldConfirmOnNavigate] = shouldConfirmOnNavigateState;
  const [, setNextRoute] = nextRouteState;

  const labelToDisplay = minimized ? labelMinimized || label : label;
  const Inner = ({ active }: { active?: boolean }) => {
    return (
      <>
        <Icon name={icon} size={20} overrideColor={active ? 'main-brighter' : undefined} />

        {!minimized && (
          <ItemLabel labelWidth={labelWidth} data-testid={'item-label'}>
            {labelToDisplay}
          </ItemLabel>
        )}
        {customElementRight ? customElementRight : ''}
      </>
    );
  };

  const isActive = Boolean(to && location.pathname.startsWith(to));

  return (
    <>
      <ListItem
        endOfGroup={endOfGroup}
        aria-label={ariaLabel}
        onClick={onClick}
        disablePointer={disablePointer}
        active={isActive}>
        {to ? (
          <Tooltip
            hoverBuffer={0}
            tabIndex={-1}
            whiteSpace={'nowrap'}
            triggerComponent={
              <LinkItem
                className={isActive ? 'activeNav' : ''}
                to={to}
                target={target}
                data-testid={'link-item'}
                onClick={(event) => {
                  if (shouldConfirmOnNavigate) {
                    event.preventDefault();
                    setShowNavigateDialog(true);
                    setNextRoute(to);
                  }
                }}>
                <Inner active={isActive} />
              </LinkItem>
            }
            text={labelToDisplay}
            position={'right'}
            disableTooltip={!minimized}
          />
        ) : (
          <Tooltip
            hoverBuffer={0}
            whiteSpace={'nowrap'}
            triggerComponent={
              <Item data-testid={'non-link-item'}>
                <Inner />
              </Item>
            }
            text={labelToDisplay}
            position={'right'}
            disableTooltip={!minimized}
          />
        )}
      </ListItem>
    </>
  );
};

export default NavigationListItem;
