import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceDefinitionByIdQuery = (): QueryResponse<ResourceDefinition> => {
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.definitionDetail(orgId, defId),
    queryFn: () => makeRequest<ResourceDefinition>('GET', `/orgs/${orgId}/resources/defs/${defId}`),
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionByIdQuery;
