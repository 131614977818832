import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Application } from '@src/models/application';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { applicationsQueryKeys } from '../applicationsQueryKeys';

const useApplicationQuery = (customParams?: { appId?: string }) => {
  const { orgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;
  const appId = customParams?.appId || routerAppId;

  const { data, ...queryResult } = useQuery({
    queryKey: applicationsQueryKeys.detail(orgId, appId),
    queryFn: () => makeRequest<Application>('GET', `/orgs/${orgId}/apps/${appId}`),
    enabled: Boolean(orgId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default useApplicationQuery;
