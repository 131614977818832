import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { generatePipelinesURL } from '@src/utilities/navigation';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const useDeletePipelineMutation = () => {
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<AxiosResponse, AxiosError>({
    mutationFn: () => {
      return makeRequest('DELETE', `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: pipelinesQueryKeys.list(orgId, appId) });
      navigate(`${generatePipelinesURL(orgId, appId)}/pipelines`);
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });
};

export default useDeletePipelineMutation;
