import { ActionButtons, AddButtonForm, WalTable, WalTableColumn } from '@humanitec/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { VolumeMount } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';

import VolumeMountViewAddEdit, {
  VolumeMountViewAddEditProps,
} from './components/VolumeMountViewAddEdit';

const VolumeMountTableWrapper = styled.div`
  margin-top: ${units.margin.md};
  margin-bottom: ${units.margin.xs};
`;

const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const AddVariablesFormWrapper = styled.div`
  margin-top: ${units.margin.md};
`;

interface VolumeMountsProps {
  deltaPath: string;
}

const VolumeMounts = ({ deltaPath }: VolumeMountsProps) => {
  // Component state
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [showAddModeFormContent, setShowAddModeFormContent] = useState<boolean | undefined>();

  // i18n
  const { t } = useTranslation();
  const moduleTranslations = t('VIEW_MODULE').VOLUME_MOUNTS;
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { data, updateWorkload, workloadResourceDependencies, sharedResourcesList } =
    useDeltaUtils(deltaPath);

  const [rowToOverrideColumns, setRowToOverrideColumns] = useState<number | undefined>();

  const volumeResources = useMemo(
    () => [
      ...workloadResourceDependencies.filter((r) => r.type === 'emptyDir' || r.type === 'volume'),
      ...sharedResourcesList.filter((r) => r.type === 'emptyDir' || r.type === 'volume'),
    ],
    [workloadResourceDependencies, sharedResourcesList]
  );

  const volumeMounts = Object.entries(data ?? {}).map(([mountPath, mount]) => ({
    mount_path: mountPath,
    ...mount,
  }));

  // Methods
  const handleEdit = (index: number) => {
    setExpandedRow(index);
    setRowToOverrideColumns(index);
  };

  const handleCancelEditMode = () => {
    setExpandedRow(undefined);
    setRowToOverrideColumns(undefined);
  };

  const handleCancelAddMode = () => {
    setShowAddModeFormContent(false);
  };

  const deleteEntry = useCallback(
    (mount: VolumeMount & { mount_path: string }) => {
      updateWorkload([
        {
          key: mount.mount_path,
          op: 'remove',
        },
      ]);
    },
    [updateWorkload]
  );

  const columns: WalTableColumn<VolumeMount & { mount_path: string }>[] = [
    {
      label: moduleTranslations.VOLUME,
      prop: 'id',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 180,
        maxCharacters: 25,
        text: (row) => row.data.id,
      },
    },
    {
      label: moduleTranslations.MOUNT_POINT,
      prop: 'mount_path',
      ellipsisTooltip: {
        maxWidth: 190,
        maxCharacters: 30,
        text: (row) => row.data.mount_path,
        showGenericText: true,
      },
      template: (row) => <p>{row.data.mount_path} </p>,
    },
    {
      label: moduleTranslations.SUBDIR,
      prop: 'sub_directory',
      ellipsisTooltip: {
        maxWidth: 190,
        maxCharacters: 30,
        text: (row) => row.data.sub_path,
        showGenericText: true,
      },
      template: (row) => <p>{row.data.sub_path || <StyledDash>-</StyledDash>}</p>,
    },
    {
      label: moduleTranslations.READWRITE,
      prop: 'read/write',
      template: (row) => (
        <p>{row.data.read_only ? moduleTranslations.READONLY : moduleTranslations.READWRITE} </p>
      ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        if (draftModeActive) {
          return (
            <ActionsWrapper>
              <ActionButtons
                buttons={['edit', 'delete']}
                onEdit={() => handleEdit(volumeMounts.indexOf(row.data))}
                onDelete={() => deleteEntry(row.data)}
              />
            </ActionsWrapper>
          );
        }
      },
    },
  ];

  const renderVolumeMountViewAddEdit = ({
    onCancel,
    mode,
    volumeMount,
    existingMountPaths,
  }: VolumeMountViewAddEditProps) => (
    <VolumeMountViewAddEdit
      existingMountPaths={existingMountPaths}
      volumeResources={volumeResources}
      volumeMount={volumeMount}
      key={volumeMount?.mount_path}
      onCancel={onCancel}
      mode={mode}
      deltaPath={deltaPath}
    />
  );

  const renderAddVariablesSection = (
    <AddButtonForm
      showFormContentState={[showAddModeFormContent, setShowAddModeFormContent]}
      buttonText={moduleTranslations.ADD_VOLUME_MOUNT}
      readonly={!draftModeActive}
      formContent={
        <>
          {renderVolumeMountViewAddEdit({
            mode: 'add',
            onCancel: handleCancelAddMode,
            existingMountPaths: volumeMounts.map((m) => m.mount_path),
            volumeResources,
            deltaPath,
          })}
        </>
      }
    />
  );

  return (
    <>
      {volumeMounts.length === 0 && !draftModeActive && (
        <span className={'txt-md'}>{sectionsTranslations.NO_VOLUME_MOUNTS_DEFINED}</span>
      )}

      {volumeResources.length > 0 && draftModeActive ? (
        <AddVariablesFormWrapper>{renderAddVariablesSection}</AddVariablesFormWrapper>
      ) : draftModeActive ? (
        <span>
          <Trans defaults={moduleTranslations.NO_VOLUMES} components={{ italic: <i /> }} />
        </span>
      ) : undefined}

      {volumeMounts.length > 0 && (
        <VolumeMountTableWrapper>
          <WalTable
            caption={sectionsTranslations.VOLUME_MOUNTS}
            columns={columns}
            expandedRowState={[expandedRow, setExpandedRow]}
            disableScrolling
            tableStyle={'expandable'}
            tableRowStyle={'base'}
            rows={
              volumeMounts
                ? volumeMounts?.map((mount, rowIndex) => ({
                    data: mount,
                    expandableContent: renderVolumeMountViewAddEdit({
                      mode: 'view',
                      volumeMount: mount,
                      existingMountPaths: volumeMounts.map((m) => m.mount_path),
                      volumeResources,
                      deltaPath,
                    }),
                    overrideColumns: rowToOverrideColumns === rowIndex,
                    columnOverrideTemplate: renderVolumeMountViewAddEdit({
                      mode: 'edit',
                      onCancel: handleCancelEditMode,
                      volumeMount: mount,
                      existingMountPaths: volumeMounts.map((m) => m.mount_path),
                      volumeResources,
                      deltaPath,
                    }),
                  }))
                : []
            }
          />
        </VolumeMountTableWrapper>
      )}
    </>
  );
};

export default VolumeMounts;
