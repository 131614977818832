import { MutableRefObject } from 'react';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import Icon, { IconColorOverrides, IconSizes } from '../../Icon/Icon';

interface InfoIconProps {
  noMargin?: boolean;
  marginRight?: boolean;
  className?: string;
  onMouseEnter?: () => any;
  iconRef?: MutableRefObject<any>;
  disableMainColor?: boolean;
  size?: IconSizes;
  overrideColor?: IconColorOverrides;
  dataTestId?: string;
}

const InfoIconWrapper = styled.span<InfoIconProps>`
  display: flex;
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-left: ${units.margin.sm};
    `}

  ${({ noMargin, marginRight }) =>
    !noMargin &&
    marginRight &&
    css`
      margin-right: ${units.margin.sm};
      margin-left: 0;
    `}
  svg {
    position: relative;
    z-index: 2;
  }
`;

const InfoIcon = ({
  noMargin,
  marginRight,
  className,
  onMouseEnter,
  iconRef,
  disableMainColor,
  size = 14,
  overrideColor,
  dataTestId,
}: InfoIconProps) => (
  <InfoIconWrapper
    className={className}
    noMargin={noMargin}
    marginRight={marginRight}
    ref={iconRef}>
    <Icon
      dataTestId={dataTestId}
      overrideColor={overrideColor || (disableMainColor ? undefined : 'main')}
      pointer
      name={'info'}
      size={size}
      onMouseEnter={onMouseEnter}
    />
  </InfoIconWrapper>
);

export default InfoIcon;
