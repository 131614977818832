import { useTranslation } from 'react-i18next';

import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { ContextPLaceholders, getContextPlaceholders } from '@src/hooks/usePlaceholders';
import { DynamicFormSchema } from '@src/types/dynamic-form';

interface Outputs {
  [value: string]: string | Outputs | undefined;
}
interface ResourcesPlaceholders {
  context: ContextPLaceholders & {
    description: string;
  };
  resources?: {
    description: string;
    [resourceType: string]:
      | {
          outputs: Outputs | undefined;
        }
      | string;
  };
}

export const getOutputValues = (
  properties?: Record<string, Omit<DynamicFormSchema, 'uiGroups'>>
) => {
  return (
    properties &&
    Object.entries(properties).reduce((outputsAcc: Outputs, [propertyKey, propertyValue]) => {
      outputsAcc[propertyKey] = propertyValue.properties
        ? getOutputValues(propertyValue.properties)
        : '';
      return outputsAcc;
    }, {})
  );
};

export const useResourcesPlaceholders = () => {
  const { data: resourceTypes } = useResourceTypesQuery();
  // i18n
  const { t } = useTranslation();
  const placeholderDescriptionsTranslations = t('PLACEHOLDER_DESCRIPTIONS');

  const placeholders: ResourcesPlaceholders = {
    context: {
      description: placeholderDescriptionsTranslations.CONTEXT_DESCRIPTION,
      ...getContextPlaceholders(placeholderDescriptionsTranslations, true),
    },
  };

  placeholders.resources = {
    description: placeholderDescriptionsTranslations.RESOURCES_DESCRIPTION,
  };
  resourceTypes?.forEach((resourceType) => {
    if (placeholders.resources) {
      placeholders.resources[resourceType.type] = {
        outputs: getOutputValues(resourceType.outputs_schema.properties),
      };
    }
  });

  return placeholders;
};
