export enum WebSocketEventTypes {
  DEPLOYMENT = 'organization.environment.deployment',
  DEPLOYMENT_EXPORTED = 'organization.environment.deployment.export',
  ENV_CREATED = 'organization.application.environment.created',
  ENV_UPDATED = 'organization.application.environment.updated',
  ENV_DELETED = 'organization.application.environment.deleted',
  ENV_PAUSE_STATUS_UPDATED = 'organization.environment.paused.updated',
  APP_CREATED = 'organization.application.created',
  APP_DELETED = 'organization.application.deleted',
  USER_CREATED = 'organization.user.created',
  USER_DELETED = 'organization.user.deleted',
  USER_UPDATED = 'organization.user.updated',
  WORKLOAD_REMOVED = 'organization.application.module.removed',
  ARTEFACT_VERSION_ADDED = 'organization.artefact.version.added',
  ARTEFACT_VERSION_UPDATED = 'organization.artefact.version.updated',
  BUILD_ADDED = 'organization.module.build.added',
  PIPELINE_UPDATED = 'organization.application.pipeline.update',
  PIPELINE_RUN_UPDATED = 'organization.application.pipeline.run.update',
  PIPELINE_JOB_UPDATED = 'organization.application.pipeline.run.job.update',
}
