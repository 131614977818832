import { rem } from 'polished';
import { ReactNode, useCallback, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { CopyText } from '../molecules/CopyText/CopyText';

const CodeSnippetWrapper = styled.div`
  position: relative;
  font-family: 'Source Code Pro', sans-serif;
  background-color: ${({ theme }: any) => theme.color.base};
  border-radius: ${rem(4)};
  padding: ${units.padding.xl};
  margin: ${units.margin.lg} 0;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.mainBrighter};
  }
`;

const CodeSnippetElement = styled.div`
  overflow: auto;
  max-height: ${rem(250)};
`;

const CodeSnippetCopyWrapper = styled.div`
  position: absolute;
  right: ${rem(30)};
  z-index: 2;
`;

const CodeSnippet = ({ children }: { children: ReactNode }) => {
  // Component state
  const [textToCopy, setTextCopy] = useState<string | undefined>();

  const codeSnippetRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setTextCopy(node.innerText);
    }
  }, []);

  const copyButtonRef = useRef<HTMLButtonElement>();

  const handleCodeSnippetClick = () => {
    if (copyButtonRef.current) {
      copyButtonRef.current?.click();
    }
  };

  return (
    <CodeSnippetWrapper>
      {textToCopy && (
        <CodeSnippetCopyWrapper>
          <CopyText text={textToCopy} copyButtonRef={copyButtonRef} />
        </CodeSnippetCopyWrapper>
      )}
      <CodeSnippetElement ref={codeSnippetRef} onClick={handleCodeSnippetClick}>
        {children}
      </CodeSnippetElement>
    </CodeSnippetWrapper>
  );
};

export default CodeSnippet;
