import { Icon, SecretValue, WalInput, WarningSection } from '@humanitec/ui-components';
import { ReactElement } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppValueVersionItem } from '@src/hooks/react-query/shared-values/sharedValueVersionsTypes';
import { useWalhallForm } from '@src/utilities/form';

type SharedAppValueHistoryFormProps = {
  historyItem: AppValueVersionItem;
  /**
   * Whether to show the blockquote styling to the form
   */
  showBlockquote?: boolean;
  /**
   * if Blockquote needs to be shown, if it needs to be alert. Default is warning
   */
  showAlert?: boolean;
  /* Need to show a component at the bottom for the form in some instances */
  footer?: ReactElement;
};

interface HistoryFormProps {
  editedDate: string;
  value: string | null;
  description: string | null;
  editedBy: string;
}

/**
 * Preovides a form for history value which can be used to display in multiple screens
 */
export const SharedAppValueHistoryForm = ({
  historyItem,
  showBlockquote = false,
  showAlert = false,
  footer,
}: SharedAppValueHistoryFormProps) => {
  const formMethods = useWalhallForm<HistoryFormProps>({
    defaultValues: {
      ...historyItem,
    },
  });

  // translation
  const { t } = useTranslation();
  const valueHistoryTranslations = t('APP_SETTINGS').VALUE_HISTORY;

  const formElements = (
    <FormProvider {...formMethods}>
      <WalInput
        name={'editedDate'}
        readonly
        labelAbove
        label={valueHistoryTranslations.EDIT_DATE}
      />
      <WalInput
        className={'mt-md'}
        name={'editedBy'}
        readonly
        labelAbove
        label={valueHistoryTranslations.EDITED_BY}
      />
      {!historyItem.isDeleted && historyItem.isPurged ? (
        <>
          <WalInput
            className={'mt-md'}
            fakeInputMinHeight={100}
            label={valueHistoryTranslations.VALUE}
            labelAbove
            name={'value'}
            readonly
            rows={4}
            fakeInput>
            <span className={'pt-sm'}>
              <Icon name={'warning'} overrideColor={'yellow'} />{' '}
            </span>
            <span className={'p-xs'}>
              {valueHistoryTranslations.PURGED_ON} {historyItem.purgeDetails.purgedDate}
            </span>
          </WalInput>
          <WalInput
            fakeInput
            className={'mt-md'}
            fakeInputMinHeight={100}
            label={'Comment'}
            labelAbove
            name={'comment'}
            readonly>
            {historyItem.purgeDetails.comment}
          </WalInput>
        </>
      ) : (
        <>
          <WalInput
            fakeInput
            className={'mt-md hyphens'}
            fakeInputMinHeight={100}
            label={valueHistoryTranslations.VALUE}
            labelAbove
            name={'value'}
            readonly>
            {historyItem.isSecret && historyItem.secretId ? (
              <SecretValue secretVersionId={historyItem.secretId} />
            ) : (
              historyItem.value
            )}
          </WalInput>
          <WalInput
            fakeInput
            className={'mt-md'}
            fakeInputMinHeight={100}
            label={valueHistoryTranslations.DESCRIPTION}
            labelAbove
            name={'description'}
            readonly>
            {historyItem.description}
          </WalInput>
        </>
      )}
    </FormProvider>
  );
  return showBlockquote ? (
    <WarningSection mode={showAlert ? 'alert' : 'warning'}>
      {formElements}
      {footer}
    </WarningSection>
  ) : (
    <>
      {formElements}
      {footer}
    </>
  );
};

export default SharedAppValueHistoryForm;
