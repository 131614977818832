export const webhooksQueryKeys = {
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'webhooks']
  all: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'webhooks',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'webhooks', 'list']
  list: (orgId: string | undefined, appId: string | undefined) => [
    ...webhooksQueryKeys.all(orgId, appId),
    'list',
  ],
};
