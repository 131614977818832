import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import DeploymentEntryMetadata from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/CompareDeployments/components/DeploymentEntryRows/components/DeploymentEntryMetadata/DeploymentEntryMetadata';
import useAppRolesQuery from '@src/hooks/react-query/roles/queries/useAppRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { DeploymentStatus } from '@src/models/deployment-object';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DeploymentEntryRowsProps {
  date: string;
  hash?: string;
  status?: DeploymentStatus;
  hasRunningPods?: boolean;
  activeDeployment?: boolean;
  comment?: string;
  createdBy?: string;
  isClone?: boolean;
  deploymentEnvId?: string;
}

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NameRow = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

export const CommentRow = styled(Row)<{ noComment?: boolean }>`
  margin-top: ${units.margin.md};
  margin-bottom: ${units.margin.sm};
  color: ${({ theme }) => theme.color.text};
  ${ellipsisStyle(350)}
  ${({ noComment }) =>
    noComment &&
    css`
      color: ${({ theme }) => theme.color.textTranslucent};
    `}
`;

const DeploymentEntryRows = (props: DeploymentEntryRowsProps) => {
  // React Query
  const { data: appRoles = [] } = useAppRolesQuery();
  // Context
  const { data: user } = useGetCurrentUserQuery();
  const { activeDeployment, createdBy, comment } = props;

  // i18n
  const { t } = useTranslation();
  const viewAppTranslations = t('VIEW_APPLICATION');
  const uiTranslations = t('UI');
  return (
    <Wrapper>
      <DeploymentEntryMetadata {...props} />
      <CommentRow noComment={!comment} title={comment}>
        {comment
          ? comment
          : activeDeployment
            ? viewAppTranslations.LAST_DEPLOYMENT
            : uiTranslations.NO_COMMENT}
      </CommentRow>
      {createdBy && (
        <NameRow>
          {createdBy === user?.id
            ? user.name
            : appRoles.length && appRoles?.find((role) => role.id === createdBy)?.name}
        </NameRow>
      )}
    </Wrapper>
  );
};

export default DeploymentEntryRows;
