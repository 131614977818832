import { WorkloadFeatureDefinition } from './workload-profile-v2';

export interface WorkloadProfile {
  id: string;
  latest: string;
  org_id: string;
  created_at: string;
  created_by: string;
}

export interface WorkloadProfileVersion {
  created_at: string;
  created_by: string;
  /**
   * V1 Workload Profile Features. This is a static list of features that do not change. Will be removed when V2 is released
   *
   * @deprecated
   */
  features: {
    'humanitec/annotations'?: Record<string, unknown>;
    'humanitec/containers'?: Record<string, unknown>;
    'humanitec/ingress'?: Record<string, unknown>;
    'humanitec/labels'?: Record<string, unknown>;
    'humanitec/schedules'?: Record<string, unknown>;
    'humanitec/replicas'?: Record<string, unknown>;
    'humanitec/service'?: Record<string, unknown>;
    'humanitec/service-account'?: Record<string, unknown>;
    'humanitec/volumes'?: Record<string, unknown>;
  };
  // V2 Workload Profile Features. See workload-profiles-v2.ts for more information.
  spec_definition?: {
    properties: WorkloadFeatureDefinition['properties'];
    runtime_properties?: WorkloadFeatureDefinition['runtime_properties'];
  };
  notes: string;
  org_id: string;
  profile_id: string;
  version: string;
}

export interface ManagedProfile {
  id: ManagedProfileTypes;
  managed: true;
}

export type ManagedProfileTypes = 'rabbitmq' | 'elasticsearch' | 'mariadb' | 'redis' | 'mongodb';

export const isWorkloadProfile = (v: WorkloadProfile | ManagedProfile): v is WorkloadProfile => {
  return v ? Boolean((v as WorkloadProfile).org_id) : false;
};

export const isManagedProfile = (v: WorkloadProfile | ManagedProfile): v is WorkloadProfile => {
  return v ? Boolean((v as ManagedProfile).managed) : false;
};
