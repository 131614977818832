import { create } from 'zustand';

import { OptimizelyUser } from '@src/models/user';

export type AuthenticationProviders = 'google' | 'github';
export type AuthenticationProvidersReadable = 'Google' | 'GitHub';
export type AuthenticationTypes = 'login' | 'register';
export type AuthenticateFailedReasons =
  | 'google-not-exist'
  | 'github-not-exist'
  | 'jwt-not-exist'
  | 'bad-request'
  | null;

type CurrentUserStore = {
  authType?: AuthenticationTypes;
  setAuthType: (type?: AuthenticationTypes) => void;
  authErrorMessage: AuthenticateFailedReasons;
  setAuthErrorMessage: (msg: AuthenticateFailedReasons) => void;
  optimizelyUser: OptimizelyUser;
  setOptimizelyUser: (user: OptimizelyUser) => void;
};

export const useCurrentUserStore = create<CurrentUserStore>((set) => {
  return {
    authType: undefined,
    authErrorMessage: null,
    setAuthType: (type) =>
      set(() => ({
        authType: type,
      })),
    setAuthErrorMessage: (msg) =>
      set(() => ({
        authErrorMessage: msg,
      })),
    optimizelyUser: { id: 'unknown-user' },
    setOptimizelyUser: (user) => {
      set(() => ({
        optimizelyUser: user,
      }));
    },
  };
});
