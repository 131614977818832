import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useEnvironmentDeleteMutation from '@src/hooks/react-query/environments/mutations/useEnvironmentDeleteMutation';

import ConfirmDeleteModal from './ConfirmDeleteModal/ConfirmDeleteModal';

interface DeleteEnvironmentModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const DeleteEnvironmentModal = ({ openState }: DeleteEnvironmentModalProps) => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const deleteModalTranslations = t('DELETE').CONFIRM_DELETE_MODAL;

  // React Query
  const {
    mutate: deleteEnvironment,
    isPending: deletingEnvironment,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useEnvironmentDeleteMutation();

  const deleteEnv = () => {
    // dispatch an action to delete the environment
    deleteEnvironment();
  };

  useEffect(() => {
    if (isDeletedSuccessfully) {
      openState[1](false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, openState, resetDeleteMutation]);

  return (
    <ConfirmDeleteModal
      state={openState}
      isDeleting={deletingEnvironment}
      deleteConfirmedCallback={deleteEnv}
      confirmMessage={deleteModalTranslations.DESCRIPTION}
      title={deleteModalTranslations.TITLE}
    />
  );
};
export default DeleteEnvironmentModal;
