import { ActiveResource, ResourceCriteria } from '@src/models/resources';

/**
 * Returns a score that determines how specific a matching criteria is to a resource
 * learn more about matching criteria specifity here https://developer.humanitec.com/platform-orchestrator/resources/definitions/#criteria-specificity
 */

const getMatchingCriteriaScore = (resource: ActiveResource, criteria: ResourceCriteria) => {
  let score = 0;

  if (criteria.env_type === resource.env_type) {
    score++;
  }
  if (criteria.app_id === resource.app_id) {
    score = score + 2;
  }
  if (criteria.env_id === resource.env_id) {
    score = score + 4;
  }
  if (criteria.res_id === resource.res_id) {
    score = score + 8;
  }
  if (criteria.class === resource.class) {
    score = score + 16;
  }

  const criteriaProperties = Object.keys(criteria).filter(
    (key) =>
      key === 'app_id' ||
      key === 'env_type' ||
      key === 'env_id' ||
      key === 'res_id' ||
      key === 'class'
  );

  // Always return a score of 0 when criteria has a property that doesn't exist on the resource
  criteriaProperties.forEach((key) => {
    if (criteria[key as keyof typeof criteria] !== resource[key as keyof typeof resource])
      score = 0;
  });

  return score;
};

/**
 * Returns a the most specific criteria that matches the resource. Returns null if no criteria matches.
 */

export const findMostSpecficMatchingCriteria = (
  resource: ActiveResource,
  matchingCriteria: ResourceCriteria[]
): ResourceCriteria | null => {
  const mostSpecificCriteria = matchingCriteria.sort(
    (a, b) => getMatchingCriteriaScore(resource, b) - getMatchingCriteriaScore(resource, a)
  )[0];

  // if all criterias score 0, check if there is a criteria that matches all values and return it
  if (getMatchingCriteriaScore(resource, mostSpecificCriteria) === 0) {
    const matchesAllValues = matchingCriteria.find(
      (criteria) =>
        criteria.id && !(criteria.res_id || criteria.app_id || criteria.env_type || criteria.env_id)
    );
    return matchesAllValues || null;
  }
  return mostSpecificCriteria;
};
