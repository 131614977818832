export const apiTokensQueryKeys = {
  // e.g. ['tokens']
  all: () => ['tokens'],
  // e.g. ['tokens', 'my-org', 'list']
  listApiTokensByOrg: (orgId: string | undefined) => [...apiTokensQueryKeys.all(), orgId, 'list'],
  // e.g. ['users', 'default-service-user', 'tokens', 'list']
  listApiTokensByUserId: (userId: string | undefined) => [
    'users',
    userId,
    ...apiTokensQueryKeys.all(),
    'list',
  ],
};
