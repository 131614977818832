import { RoleType } from '@src/models/role';

export const rolesQueryKeys = {
  // e.g. ['orgs', 'my-org', 'roles', 'list']
  orgRoles: (orgId: string | undefined) => ['orgs', orgId, 'roles', 'list'],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'roles', 'list']
  appRoles: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'roles',
    'list',
  ],
  // e.g. ['orgs', 'my-org', 'env-types', 'my-env-type', 'roles', 'list']
  envTypeRoles: (orgId: string | undefined, envTypeId: string | undefined) => [
    'orgs',
    orgId,
    'env-types',
    envTypeId,
    'roles',
    'list',
  ],
  // e.g  ['orgs', 'my-org',  'invitations']
  listInvitations: (orgId: string) => ['orgs', orgId, 'invitations'],
};

export const generateRolesURL = (orgId: string, roleType?: RoleType) =>
  `/orgs/${orgId}${roleType ? `/${roleType.type}/${roleType.id}` : ''}/users`;

export const generateRoleQueryKeyBasedOnRoleType = (orgId: string, roleType?: RoleType) => {
  if (roleType?.type === 'apps') {
    return rolesQueryKeys.appRoles(orgId, roleType.id);
  } else if (roleType?.type === 'env-types') {
    return rolesQueryKeys.envTypeRoles(orgId, roleType.id);
  }
  return rolesQueryKeys.orgRoles(orgId);
};
