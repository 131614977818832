import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { DeploymentSet, Workload } from '@src/models/deployment-set';
import { checkPathExistsInDeploymentSet } from '@src/utilities/deployment-delta-utils';
import { MANAGED_WORKLOAD_PROFILES } from '@src/utilities/string-utility';

import useDeploymentDeltaQuery from './react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentSetQuery from './react-query/deployment-set/useDeploymentSetQuery';
import { useDeltaUtils } from './useDeltaUtils/useDeltaUtils';

type TileVariants = 'existing' | 'added';

interface TileProps extends Workload {
  workloadId: string;
  variant: TileVariants;
}

export interface RenderWorkloadProps {
  workload: Workload;
  workloadId: string;
  deleted: boolean;
  edited: boolean;
}

/**
 * Hook to map the workload data required for workload cards/tiles.
 * Extracting into a hook because it's used in both new & old navigation. We may be able to remove this once the old nav code is deleted.
 *
 * @param customDeploymentSet Pass a custom deployment set. If this is undefined, the deployment set from the store will be used.
 */
export const useGetWorkloadData = (
  customDeploymentSet?: DeploymentSet
): { workloadDefinitions: RenderWorkloadProps[]; isFetching: boolean } => {
  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // React Query
  const { data: deploymentSet, isFetching } = useDeploymentSetQuery();
  const { data: activeDelta, isFetching: isDeltaFetching } = useDeploymentDeltaQuery();

  const { workloads } = useDeltaUtils();

  return {
    isFetching: isFetching || isDeltaFetching,
    workloadDefinitions: useMemo(() => {
      const workloadsToUse = customDeploymentSet?.modules || workloads;
      const inSetAndDelta: TileProps[] = Object.entries(workloadsToUse ?? {}).map(
        ([workloadId, workload]) => ({
          ...workload,
          workloadId,
          variant:
            deploymentSet &&
            checkPathExistsInDeploymentSet(`/modules/${workloadId}`, cloneDeep(deploymentSet))
              ? 'existing'
              : 'added',
        })
      );

      const mappedTiles = inSetAndDelta;

      return mappedTiles.map(({ variant, workloadId, ...rest }, index) => {
        const changes = activeDelta?.modules?.update?.[workloadId];
        const moduleAddedInDraft = activeDelta?.modules?.add?.[workloadId];

        return {
          managed: MANAGED_WORKLOAD_PROFILES.test(rest.profile),
          tabIndex: !MANAGED_WORKLOAD_PROFILES.test(rest.profile) ? 0 : undefined,
          key: index,
          workload: rest,
          workloadId,
          readonly: !draftModeActive,
          draftModeActive,
          deleted: draftModeActive && Boolean(activeDelta?.modules?.remove?.includes(workloadId)),
          newModule: variant === 'added',
          edited: Boolean((draftModeActive && changes && changes.length > 0) || moduleAddedInDraft),
        };
      });
    }, [
      customDeploymentSet?.modules,
      workloads,
      draftModeActive,
      deploymentSet,
      activeDelta?.modules?.update,
      activeDelta?.modules?.add,
      activeDelta?.modules?.remove,
    ]),
  };
};
