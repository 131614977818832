import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { useWalhallForm } from '@src/utilities/form';

import { Checkbox } from '..//Checkbox/Checkbox';

const WhitespacesCheckbox = styled(Checkbox)`
  margin-left: auto;
  min-height: 2rem;
`;

export interface WhitespaceHighlightCheckboxProps {
  onChange: () => void;
}

const WhitespaceHighlightCheckbox = ({ onChange }: WhitespaceHighlightCheckboxProps) => {
  const formMethods = useWalhallForm();

  return (
    <FormProvider {...formMethods}>
      <WhitespacesCheckbox
        defaultChecked
        name={'show-whitespaces'}
        label={'Show whitespace characters'}
        handleChange={onChange}
      />
    </FormProvider>
  );
};

export default WhitespaceHighlightCheckbox;
