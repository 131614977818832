import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { KeyValue } from '@src/models/general';
import { useWalhallForm } from '@src/utilities/form';

import WalModal from '../../../base/Modal/Modal';
import { AutoCompleteOptions } from '../../AutoCompleteInput/AutoCompleteInput';
import { KeyValidation, ValueValidation } from '../../KeyValueEntries/components/KeyValueEntry';
import { KeyValueEntries } from '../../KeyValueEntries/KeyValueEntries';

interface KeyValueEntriesModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  entries: KeyValue[];
  handleFormSubmit: (formValues: { entries: KeyValue[] }) => void;
  title: string;
  addButtonText: string;
  entryNameSingular: string;
  keyValidation?: KeyValidation;
  valueValidation?: ValueValidation;
  editingEnabled?: boolean;
  errorMoreInformationLink?: string;
  autocompleteOptions?: AutoCompleteOptions;
}

const KeyValueEntriesModal = ({
  state,
  entries,
  handleFormSubmit,
  title,
  keyValidation,
  valueValidation,
  addButtonText,
  editingEnabled,
  errorMoreInformationLink,
  entryNameSingular,
  autocompleteOptions,
}: KeyValueEntriesModalProps) => {
  const [open, setOpen] = state;

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const [numberOfFields, setNumberOfFields] = useState<number>(entries.length);

  useEffect(() => {
    if (open) {
      setNumberOfFields(entries.length);
    }
  }, [open, entries.length]);

  // Form
  const methods = useWalhallForm<{ entries: KeyValue[] }>();
  const {
    formState: { isDirty },
  } = methods;

  const onSubmit = (formValues: { entries: KeyValue[] }) => {
    handleFormSubmit(formValues);
    setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <WalModal
        openState={state}
        handleFormSubmit={onSubmit}
        title={title}
        size={'large'}
        disableOverflow={numberOfFields <= 2}
        content={
          <KeyValueEntries
            group={{ name: 'entries', label: entryNameSingular }}
            entries={entries}
            errorMoreInformationLink={errorMoreInformationLink}
            keyValidation={keyValidation}
            valueValidation={valueValidation}
            editingEnabled={Boolean(editingEnabled)}
            addButtonText={addButtonText}
            autocompleteOptions={autocompleteOptions}
            onFieldNumberChanged={(fieldsNumber) => setNumberOfFields(fieldsNumber)}
          />
        }
        actions={{
          main: {
            text: uiTranslations.SAVE_CHANGES,
            props: {
              type: 'submit',
              disabled: !isDirty,
            },
            hideButton: !editingEnabled,
          },
          cancel: {
            text: editingEnabled ? uiTranslations.CANCEL : uiTranslations.CLOSE,
          },
        }}
      />
    </FormProvider>
  );
};

export default KeyValueEntriesModal;
