import { merge } from 'lodash';

import { flexCentered, flexColumn } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

import { MarginSizes } from './utils';

const gapStyles = Object.keys(units.margin)
  .map((size) => ({
    ['gap-' + size]: 'gap: ' + units.margin[size as MarginSizes],
  }))
  .reduce(merge);
const flexStyles = {
  flex: 'display: flex',
  'justify-end': 'justify-content: flex-end',
  'justify-center': 'justify-content: center',
  'align-center': 'align-items: center',
  'flex-column': flexColumn(),
  'flex-row': 'flex-direction: row !important',
  'flex-centered': flexCentered(),
  'flex-1': 'flex: 1',
};
export type FlexClassnames = keyof typeof flexStyles | `gap-${MarginSizes}`;
export const flexStylesMap = merge(flexStyles, gapStyles);
