import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { PipelineCriteria } from '@src/models/pipeline';
import { ResourceCriteria } from '@src/models/resources';
import { units } from '@src/styles/variables';

const Wrapper = styled.div`
  display: flex;
  max-width: 100%;
`;

const CriteriaTextWrapper = styled.div<{
  maxWidth: string | undefined;
  allowOverflow: boolean | undefined;
}>`
  ${({ maxWidth }) =>
    maxWidth
      ? css`
          max-width: ${maxWidth};
        `
      : css`
          max-width: 100%;
        `}
  ${({ allowOverflow }) =>
    !allowOverflow &&
    css`
      white-space: nowrap;
    `}
overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
`;

const SubText = styled.span`
  padding: 0 ${units.margin.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

const CriteriaItem = styled.div<{ allowOverflow: boolean | undefined }>`
  ${({ allowOverflow }) =>
    allowOverflow
      ? css`
          display: block;
        `
      : css`
          display: inline;
          white-space: nowrap;
        `};
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ShowMatchingCriteriaInfoProps<T> {
  criteria: T;
  /* maximum shown criteria properties before showing + N more text */
  numberOfShownProps?: number;
  /* max-width of container. An ellipsis would appear if content exceeds this width */
  maxWidth?: string;
  /* if set to true, content is allowed to span multiple lines instead of a single line. */
  allowOverflow?: boolean;
}
const ShowMatchingCriteriaInfo = <T extends ResourceCriteria | PipelineCriteria>({
  criteria,
  numberOfShownProps,
  maxWidth,
  allowOverflow,
}: ShowMatchingCriteriaInfoProps<T>) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('MATCHING_CRITERIA');

  const getText = (key: string) => {
    switch (key) {
      case 'deployment_type':
        return translations.DEPLOYMENT_TYPE_IS;
      case 'app_id':
        return translations.APPLICATION_ID_IS;
      case 'env_type':
        return translations.ENVIRONMENT_TYPE_IS;
      case 'env_id':
        return translations.ENVIRONMENT_ID_IS;
      case 'class':
        return translations.RESOURCE_CLASS_IS;
      default:
        return translations.RESOURCE_ID_IS;
    }
  };

  const criteriaProps: (keyof T)[] = Object.keys(criteria || {}).filter(
    (prop) =>
      prop === 'app_id' ||
      prop === 'env_type' ||
      prop === 'env_id' ||
      prop === 'res_id' ||
      prop === 'deployment_type' ||
      prop === 'class'
  ) as (keyof T)[];

  const maxProps = numberOfShownProps || criteriaProps.length;

  return (
    <Wrapper>
      {criteriaProps.length > 0 ? (
        <>
          <CriteriaTextWrapper maxWidth={maxWidth} allowOverflow={allowOverflow}>
            {criteriaProps.slice(0, maxProps).map((prop, index) => (
              <CriteriaItem key={prop as string} allowOverflow={allowOverflow}>
                {getText(prop as string)}`{criteria[prop] as string}`
                {index !== criteriaProps.length - 1 && index < maxProps - 1 && (
                  <SubText>{translations.AND}</SubText>
                )}{' '}
                {/* Only appends "and" to the end of the criteria-type if it's not the last element in the list and if it's index isn't the max index specified */}
              </CriteriaItem>
            ))}
          </CriteriaTextWrapper>
          {numberOfShownProps && criteriaProps.length > numberOfShownProps && (
            <SubText>
              +{criteriaProps.length - numberOfShownProps} {translations.MORE_LOWERCASE}
            </SubText>
          )}
        </>
      ) : (
        <span>{translations.MATCHES_ALL_CONDITIONS}</span>
      )}
    </Wrapper>
  );
};

export default ShowMatchingCriteriaInfo;
