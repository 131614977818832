import { HumanitecWorkloadProfileFeatureKeys } from '@src/models/workload-profile-v2';

import Annotations from './features/Annotations/Annotations';
import { AssignResources } from './features/AssignResources/AssignResources';
import { ContainerImage } from './features/ContainerImage/ContainerImage';
import Containers from './features/Containers/Containers';
import CronJobs from './features/CronJobs/CronJobs';
import Files from './features/Files/Files';
import IngressRouting from './features/IngressRouting/IngressRouting';
import Labels from './features/Labels/Labels';
import Overrides from './features/Overrides/Overrides';
import Probe from './features/Probe/Probe';
import ServiceAccount from './features/ServiceAccount/ServiceAccount';
import ServicePorts from './features/ServicePorts/ServicePorts';
import Variables from './features/Variables/Variables';
import VolumeMounts from './features/VolumeMounts/VolumeMounts';

interface HumanitecDeltaFeatureProps {
  deltaPath: string;
  featureName: HumanitecWorkloadProfileFeatureKeys;
  featureKey: string;
}

const HumanitecDeltaFeature = ({
  featureName,
  featureKey,
  deltaPath,
}: HumanitecDeltaFeatureProps) => {
  if (featureName === 'humanitec/image') {
    return <ContainerImage deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/service-account') {
    return <ServiceAccount deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/variables') {
    return <Variables deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/files') {
    return <Files deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/service') {
    return <ServicePorts deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/resources') {
    return <AssignResources deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/labels') {
    return <Labels deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/annotations') {
    return <Annotations deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/probe') {
    return <Probe deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/volume-mounts') {
    return <VolumeMounts deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/schedules') {
    return <CronJobs deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/containers') {
    return <Containers deltaPath={deltaPath} featureKey={featureKey} />;
  }

  if (featureName === 'humanitec/overrides') {
    return <Overrides deltaPath={deltaPath} />;
  }

  if (featureName === 'humanitec/ingress') {
    return <IngressRouting deltaPath={deltaPath} />;
  }

  return <></>;
};

export default HumanitecDeltaFeature;
