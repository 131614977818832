import { get } from 'lodash';
import { rem } from 'polished';
import { ErrorOption, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { DisplayFieldErrors, InputElementProps } from '../Input/Input';
import { inputCss } from '../Input/styles/mixins';
import { WalLabel } from '../Label';

const Wrapper = styled.div<{ fullWidth?: boolean; width?: number; $hideLabel?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${rem(width)};
    `}
`;

const DateComponent = styled.input<InputElementProps>`
  color-scheme: ${({ theme }) => theme.color.datePickerColorScheme};
  width: 100%;
  ${(props) => inputCss(props)}
`;

export interface DatePickerProps {
  /* id of the input */
  id?: string;
  /* name of the date input */
  name: string;
  /* label attached to the input */
  label?: string;
  /* hide the label field */
  hideLabel?: boolean;
  /* custom class */
  className?: string;
  /* if this component should take the 100% width of the container */
  fullWidth?: boolean;
  /* width of component */
  width?: number;
  /* minimum date selectable. Allows a date string formatted in DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY i.e 2022-09-22 */
  minDate?: string;
  /* maximum date selectable. Allows a date string formatted in DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY i.e 2022-09-22 */
  maxDate?: string;
  /* data-testid attribute */
  dataTestId?: string;
  /* onChange html event */
  onChange?: (value: string) => void;
  /* if the date input is required */
  required?: boolean;
  defaultValue?: string;
}
/**
 * Date Input component with calendar dropdown for selecting dates.
 *
 * Note: This makes use of the native html date input so appearances may vary across browsers. Theming is controlled by the `color-scheme` css attribute.
 *
 */
export const DatePicker = ({
  id,
  name,
  label,
  hideLabel,
  className,
  fullWidth,
  width,
  dataTestId,
  onChange,
  minDate,
  maxDate,
  required,
  defaultValue,
}: DatePickerProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const registerProps = register(name, {
    required: required && `${label ? label : 'This field'} is required`,
  });
  const fieldError = name && (get(errors, name) as ErrorOption);
  return (
    <Wrapper
      className={className}
      fullWidth={fullWidth}
      width={width}
      $hideLabel={!label || hideLabel}>
      {label && !hideLabel && (
        <WalLabel htmlFor={name} className={'txt-sm'}>
          {label}
        </WalLabel>
      )}
      <DateComponent
        id={id || name}
        type={'date'}
        data-testid={dataTestId}
        {...registerProps}
        onChange={(event) => {
          registerProps.onChange(event);
          onChange?.(event.target.value);
        }}
        defaultValue={defaultValue}
        min={minDate}
        max={maxDate}
        invalid={Boolean(errors && name && fieldError)}
      />
      {fieldError && <DisplayFieldErrors fieldErrors={fieldError} oneField={{ label }} />}
    </Wrapper>
  );
};
