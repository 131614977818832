import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { Webhook } from '@src/models/webhook';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../../types';
import { webhooksQueryKeys } from '../webhooksQueryKeys';

const useWebhooksListQuery = (): QueryResponse<Webhook[]> => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: webhooksQueryKeys.list(orgId, appId),
    queryFn: () => makeRequest<Webhook[]>('GET', `/orgs/${orgId}/apps/${appId}/webhooks`),
    enabled: Boolean(orgId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default useWebhooksListQuery;
