import { CustomLanguages } from '../../base/Editor/EditorProps';
import { WalInput, WalInputProps } from '../../base/Input/Input';

export interface AutoCompleteOptions {
  /** object for auto complete lookup */
  lookupObject: any;
  customLanguage?: CustomLanguages;
  /** delimits the area where the auto complete happens */
  delimiterString?: {
    start: string;
    end: string;
  };
  /** max height value for the dropdown menu */
  dropdownMenuMaxHeight?: number;
}
/**
 * strip placeholder delimiters from a string
 */
export const stripDelimiters = (str: string, delimiterString: { start: string; end: string }) => {
  if (delimiterString) {
    const delimiterStart = new RegExp(`[\\${delimiterString.start}]`, 'ig');
    const delimiterEnd = new RegExp(`[\\${delimiterString.end}]`, 'ig');
    return str.replace(delimiterStart, '').replace(delimiterEnd, '');
  }
};

/**
 * check if a placeholder cannot be resolved based on a lookup object
 */
export const placeholderHasError = (
  value: string,
  lookupObject: Record<string, any>,
  delimiterString: { start: string; end: string }
): boolean => {
  const placeholderSegments = value
    .split('.')
    .map((segement) => stripDelimiters(segement, delimiterString));
  let newLookUpObject: any = { ...lookupObject };
  if (placeholderSegments.length > 1) {
    for (const segment of placeholderSegments) {
      if (segment && newLookUpObject.hasOwnProperty(segment)) {
        newLookUpObject = { ...newLookUpObject[segment] };
      } else {
        return true;
      }
    }
  } else if (placeholderSegments.length === 1 && placeholderSegments[0] !== '') {
    return true;
  }
  return false;
};

export const AutoCompleteInput = (props: WalInputProps) => {
  return <WalInput {...props} isMonacoEditor />;
};

export default AutoCompleteInput;
