import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { OrgRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from './userQueryKeys';

const useUserDetailQuery = (customParams?: { userId?: string }) => {
  const { orgId, userId: routerUserId } = useParams<keyof MatchParams>() as MatchParams;
  const userId = customParams?.userId || routerUserId;

  return useQuery({
    queryKey: userQueryKeys.user(orgId, userId),
    queryFn: () => makeRequest<Role<OrgRoles>>('GET', `/orgs/${orgId}/users/${userId}`),
    select: (data) => data.data,
    enabled: Boolean(orgId && userId),
  });
};

export default useUserDetailQuery;
