import { WalModal } from '@humanitec/ui-components';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface PauseEnvironmentModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  content: ReactNode;
  onPause: () => void;
  onCancel: () => void;
}

const PauseEnvironmentModal = ({
  openState,
  content,
  onPause,
  onCancel,
}: PauseEnvironmentModalProps) => {
  // Components state
  const [open, setOpen] = openState;
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <WalModal
      title={uiTranslations.PAUSE_ENVIRONMENT}
      openState={[open, setOpen]}
      content={content}
      actions={{
        main: {
          text: uiTranslations.PAUSE_ENVIRONMENT,
          props: {
            iconLeft: { name: 'pause', disableInvert: true, size: 12 },
            onClick: onPause,
          },
        },
        cancel: {
          props: {
            onClick: onCancel,
          },
        },
      }}
    />
  );
};

export default PauseEnvironmentModal;
