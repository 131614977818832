import styled from 'styled-components/macro';

import { cl } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';

import ExpandableCard, { ExpandableCardProps } from '../ExpandableCard/ExpandableCard';

const AccordionListItem = styled.li`
  list-style: none;
  margin-bottom: ${units.margin.xs};
`;

export interface AccordionItem extends ExpandableCardProps {
  /** the position of the accordion item in the accordion in descending order */
  sortIndex?: number;
}

interface AccordionProps {
  items: AccordionItem[];
  cardTestId?: string;
}

export const Accordion = ({ items, cardTestId }: AccordionProps) => {
  return (
    <ul {...cl('m-0', 'p-0')}>
      {items
        .sort((a, b) => (b.sortIndex || 0) - (a.sortIndex || 0))
        .map((item) => (
          <AccordionListItem key={item.id}>
            <ExpandableCard
              id={item.id}
              headerContent={item.headerContent}
              content={item.content}
              cardStyle={item.cardStyle}
              disableExpansion={item.disableExpansion}
              expandedByDefault={item.expandedByDefault}
              dataTestId={cardTestId}
            />
          </AccordionListItem>
        ))}
    </ul>
  );
};

export default Accordion;
