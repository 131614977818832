import { AxiosError } from 'axios';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import ConfirmDeleteModal, {
  InputConfirmationType,
} from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';

interface ConfirmDeleteMatchingCriteriaModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  onDeleteConfirmed: () => void;
  title: string;
  /** matching criteria is deleting from React query */
  isDeletingCriteria?: boolean;
  /** matching criteria delete error from React query */
  deleteCriteriaError?: AxiosError<ApiErrorResponse> | null;
  inputConfirmation?: InputConfirmationType;
  content: ReactNode;
}
const ConfirmDeleteMatchingCriteriaModal = ({
  openState,
  onDeleteConfirmed,
  deleteCriteriaError,
  isDeletingCriteria,
  title,
  inputConfirmation,
  content,
}: ConfirmDeleteMatchingCriteriaModalProps) => {
  const [open, setOpen] = openState;
  return (
    <ConfirmDeleteModal
      title={title}
      state={[open, setOpen]}
      deleteConfirmedCallback={onDeleteConfirmed}
      isDeleting={isDeletingCriteria}
      deleteError={
        deleteCriteriaError
          ? {
              message: deleteCriteriaError?.response?.data.message,
              code: deleteCriteriaError?.response?.data.error,
            }
          : undefined
      }
      inputConfirmation={inputConfirmation}
      showWarningText
      customContentComponent={content}
    />
  );
};

export default ConfirmDeleteMatchingCriteriaModal;
